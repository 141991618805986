import React from 'react';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core/';

import { useHistory, useLocation } from 'react-router-dom';

import FontAwesome from '@src/Components/common/FontAwesome';

import type { Page } from '@src/routes/types';

interface Props {
  route: Page;
}

const Expand = (
  <FontAwesome size="xs" name="chevron-right" type="fas" form="fa" />
);
const Close = (
  <FontAwesome size="xs" name="chevron-down" type="fas" form="fa" />
);

function isOnRoute(route: Page, location) {
  if (route.path) {
    if (route.path === '/' && location.pathname === route.path) {
      return true;
    }
  } else {
    for (let i = 0; i < route.subRoutes.length; i++) {
      if (location.pathname === route.subRoutes[i].path) {
        return true;
      }
    }
  }
  return false;
}

function SubCategoryRoute({ route }: Props) {
  const location = useLocation();
  const isOpen = React.useMemo(() => {
    return route.subRoutes.some((subRoute) => {
      return location.pathname === subRoute.path;
    });
  }, [route, location]);

  const [open, setOpen] = React.useState(isOpen);
  const history = useHistory();

  React.useEffect(() => {
    setOpen(isOpen);
  }, [location, isOpen]);

  return (
    <React.Fragment key={route.key}>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemIcon>{route.icon}</ListItemIcon>
        <ListItemText primary={route.title} />
        {open ? Close : Expand}
      </ListItem>
      <Collapse in={open} unmountOnExit timeout={500}>
        <List component="div" disablePadding>
          {route.subRoutes.map((subRoute) => {
            const isHidden =
              subRoute.hiddenFromNav || subRoute.hiddenFromDrawer;
            const hidenElement = <React.Fragment key={subRoute.key} />;
            const shownElement = (
              <ListItem
                button
                key={subRoute.key}
                onClick={() => history.push(subRoute.path)}
                selected={location.pathname === subRoute.path}
              >
                <ListItemIcon>{subRoute.icon}</ListItemIcon>
                <ListItemText primary={subRoute.title} />
              </ListItem>
            );
            let returnedRoute = hidenElement;
            if (subRoute.authorized) {
              returnedRoute = isHidden ? hidenElement : shownElement;
            }
            return returnedRoute;
          })}
        </List>
      </Collapse>
    </React.Fragment>
  );
}

export default React.memo(SubCategoryRoute);
