import { useState } from 'react';

import {
  Grid,
  Card,
  makeStyles,
  CardContent,
  Typography,
  Box,
  Divider,
  TextField,
  Button,
} from '@material-ui/core';
import { ParentOrderProps } from './types';
import ParentTextFields from './ParentOrderTextFields';
import { startCase } from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  secondColumns,
  orderItemsColumns,
  firstColumns,
  textColumns,
  buyerInfoColumns,
} from './constants';
import { primary } from '@src/Components/Theme/themes/constants';

const useStyles = makeStyles({
  header: {
    marginBottom: 15,
  },
  card: {
    width: '100%',
    padding: 10,
    marginBottom: 30,
  },
  cardAction: {
    justifyContent: 'center',
  },
  button: {
    textTransform: 'none',
  },
  textArea: {
    marginTop: 10,
  },
  fontColor: {
    color: '#fff',
    backgroundColor: '#6e6b7b',
    fontSize: 10,
  },
  divider: {
    background: primary.main,
    marginTop: 20,
    marginBottom: 20,
  },
  text: {
    marginRight: 2,
  },
});

function ParentOrder({
  isViewMode,
  // mutate
  row,
}: ParentOrderProps) {
  const [item, setItem] = useState(0);
  const classes = useStyles();
  const orderItems = row?.orderItems ? row?.orderItems : null;
  const history = useHistory();

  return (
    <>
      {orderItems === null ? (
        <></>
      ) : (
        <Grid
          container
          justify="flex-start"
          alignItems="flex-start"
          spacing={1}
          wrap="wrap"
        >
          <Card className={classes.card}>
            <CardContent style={{ padding: 30 }}>
              <Grid
                className={classes.header}
                alignItems="center"
                justify="space-between"
                container
                xs={12}
              >
                <Typography variant="h5">
                  <Box fontWeight="fontWeightBold">Edit Order</Box>
                </Typography>
              </Grid>
              <Grid xs={12} container>
                <Grid item container xs={6}>
                  {firstColumns.map((name) => {
                    return (
                      <Grid
                        key={name.key}
                        container
                        spacing={1}
                        justify="space-between"
                        className={classes.textArea}
                      >
                        <Grid
                          alignItems="center"
                          justify="flex-start"
                          container
                          item
                          xs={12}
                        >
                          <Grid item container xs={8}>
                            <Typography key={name.id}>
                              <strong>{name.title}</strong>
                            </Typography>
                          </Grid>
                          <Grid item container xs={4}>
                            <Typography>{row[name.key]}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item>
                  <Divider className={classes.divider} orientation="vertical" />
                </Grid>
                <Grid
                  item
                  container
                  xs={5}
                  justify="flex-start"
                  style={{ marginLeft: 20 }}
                >
                  {secondColumns.map((name) => {
                    if (name.customComponent) {
                      return (
                        <>
                          <name.customComponent order={row} itemIndex={item} />
                        </>
                      );
                    }
                    return (
                      <Grid
                        key={name.key}
                        container
                        spacing={1}
                        justify="space-between"
                        className={classes.textArea}
                      >
                        <Grid
                          alignItems="center"
                          justify="flex-start"
                          container
                          item
                          xs={12}
                        >
                          <Grid item container xs={8}>
                            <Typography key={name.id}>
                              <strong>{name.title}</strong>
                            </Typography>
                          </Grid>

                          <Grid item container xs={4}>
                            <Typography>{row[name.key]}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>

              <Grid
                alignItems="center"
                justify="space-between"
                container
                xs={6}
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                {buyerInfoColumns.map((name) => {
                  <Grid container spacing={1} justify="space-between" xs={6}>
                    <Grid
                      alignItems="center"
                      justify="flex-start"
                      container
                      item
                      xs={12}
                    >
                      <Grid item container xs={8}>
                        <Typography key={name.id}>
                          <strong>{name.title}</strong>
                        </Typography>
                      </Grid>
                      <Grid item container xs={4}>
                        <Typography>{row[name.key]}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>;
                })}
              </Grid>

              {buyerInfoColumns.map((name) => {
                return (
                  <Grid
                    key={name.key}
                    container
                    spacing={1}
                    justify="space-between"
                  >
                    <Grid
                      alignItems="center"
                      justify="flex-start"
                      container
                      item
                      xs={12}
                    >
                      <Grid item container xs={2}>
                        <Typography key={name.id}>
                          <strong>{name.title}</strong>
                        </Typography>
                      </Grid>
                      <Grid item container xs={4}>
                        <Typography>{row[name.key]}</Typography>
                        <Typography>{orderItems[name.key]}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}

              <Grid
                // key={name.key}
                container
                spacing={1}
                justify="space-between"
                xs={12}
              >
                {textColumns
                  .filter((x) => {
                    return x.list === 'left';
                  })
                  .map((name) => {
                    return (
                      <Grid
                        key={name.key}
                        alignItems="center"
                        justify="center"
                        container
                        item
                        xs={6}
                      >
                        <Grid item container xs={12} style={{ margin: 15 }}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            key={name.id}
                            label={name.title}
                            defaultValue={row[name.key]}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}

                {textColumns
                  .filter((x) => {
                    return x.list === 'right';
                  })
                  .map((name) => {
                    return (
                      <Grid
                        key={name.key}
                        alignItems="center"
                        justify="center"
                        container
                        item
                        xs={6}
                      >
                        <Grid item container xs={12} style={{ margin: 15 }}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            key={name.id}
                            label={name.title}
                            defaultValue={row[name.key]}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>

              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
              {orderItems?.map((item) => {
                return orderItemsColumns.map((name) => {
                  if (name.customComponent) {
                    return (
                      <>
                        <name.customComponent item={item} itemIndex={0} />
                      </>
                    );
                  }
                  return (
                    <Grid
                      key={name.key}
                      container
                      spacing={1}
                      justify="flex-start"
                    >
                      <Grid
                        alignItems="center"
                        justify="flex-start"
                        container
                        item
                        xs={6}
                      >
                        <Grid
                          item
                          container
                          xs={5}
                          style={{ marginTop: 10, marginBottom: 10 }}
                        >
                          <Typography key={name.id}>
                            <strong>{name.title}</strong>
                          </Typography>
                        </Grid>
                        <Grid item container xs={6}>
                          <Typography>{item[name.key]}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                });
              })}
              <Grid container xs={12} style={{ marginTop: 10 }}>
                <Grid item container justify="space-between">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => history.push('/orders/search')}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary">
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
}

export default ParentOrder;
