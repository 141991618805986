import React, { memo } from 'react';

import {
  Grid,
  Hidden,
  Paper,
  Tooltip,
  IconButton,
  Icon,
  Typography,
  Link,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import NotificationDrawer from '@src/Components/Notifications/NotificationDrawer';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FontAwesome from '@src/Components/common/FontAwesome';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#FFF',
    transition: '1s ease-in-out',
    width: '100%',
    margin: '0 auto',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2.5),
  },
  button: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '1rem',
  },
  buttonText: {
    display: 'flex',
    flexDirection: 'column',
  },
  progressRoot: {
    height: 15,
    width: '100%',
  },
  progressBar: {
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    cursor: 'pointer',
  },
}));

function BreadcrumbsComponent() {
  const classes = useStyles();

  const path = window.location.pathname;
  const pathSegments = path.split('/').filter((segment) => segment);
  const lastSegment = pathSegments[pathSegments.length - 1];

  return (
    <Paper className={classes.root} elevation={1}>
      <Grid item container justify="space-between" alignItems="center">
        {/* <Grid item xs={3}>
          {pathSegments.length < 1 ? (
            <Typography
              style={{ textTransform: 'capitalize', fontWeight: 'bold' }}
              color="secondary"
            >
              Analytics
            </Typography>
          ) : (
            <Typography
              style={{ textTransform: 'capitalize', fontWeight: 'bold' }}
              color="secondary"
            >
              {lastSegment}
            </Typography>
          )}
        </Grid> */}
        <Grid item container xs={12} style={{ justifyContent: 'flex-start' }}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            {pathSegments.length < 1 ? (
              <Grid item container alignItems="center">
                <Typography style={{ textTransform: 'capitalize' }}>
                  Dashboards
                </Typography>
                <NavigateNextIcon fontSize="small" />
                <Typography
                  style={{ textTransform: 'capitalize' }}
                  color="secondary"
                >
                  Analytics
                </Typography>
              </Grid>
            ) : (
              pathSegments.map((segment, i) => {
                const lastSegment = i === pathSegments.length - 1;
                const segmentLink = `/${pathSegments
                  .slice(0, i + 1)
                  .join('>')}`;
                return lastSegment ? (
                  <Typography
                    style={{ textTransform: 'capitalize' }}
                    color="secondary"
                    key={segment}
                  >
                    {segment}
                  </Typography>
                ) : (
                  <Typography
                    style={{ textTransform: 'capitalize' }}
                    key={segment}
                  >
                    {segment}
                  </Typography>
                );
              })
            )}
          </Breadcrumbs>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default memo(BreadcrumbsComponent);
