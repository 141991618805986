import { makeStyles } from '@material-ui/core';
import { Props } from './types';
import formatters from '@oneAppCore/utils/formatters';

const useStyles = makeStyles((theme) => ({
  'lds-ellipsis': {
    display: 'inline-block',
    position: 'relative',
    width: '50px',
    height: '50px',
    '& div': {
      position: 'absolute',
      top: '23px',
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',
      '&:nth-child(1)': {
        left: '8px',
        animation: '$ellipsis-start 0.6s infinite',
      },
      '&:nth-child(2)': {
        left: '8px',
        animation: '$ellipsis-mid 0.6s infinite',
      },
      '&:nth-child(3)': {
        left: '32px',
        animation: '$ellipsis-mid 0.6s infinite',
      },
      '&:nth-child(4)': {
        left: '56px',
        animation: '$ellipsis-end 0.6s infinite',
      },
    },
  },
  '@keyframes ellipsis-start': {
    from: {
      transform: 'scale(0)',
    },
    to: {
      transform: 'scale(1)',
    }
  },
  '@keyframes ellipsis-end': {
    from: {
      transform: 'scale(1)',
    },
    to: {
      transform: 'scale(0)',
    }
  },
  '@keyframes ellipsis-mid': {
    from: {
      transform: 'translate(0, 0)',
    },
    to: {
      transform: 'translate(24px, 0)',
    }
  },
  'dark': {
    '& div': {
      background: 'black',
    }
  },
  'light': {
    '& div': {
      background: 'white',
    }
  },
}));

function Loader({ style, dark }: Props) {
  const classes = useStyles(); // add prop for colors later
  return (
    <div style={{ ...style }}>
      <div className={formatters.classNames([classes['lds-ellipsis'], dark ? classes.dark : classes.light])}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
