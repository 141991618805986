import type { Column } from '@src/Components/common/containers/SearchView/types';
import Grid from '@material-ui/core/Grid';

export const columns: Column[] = [
  {
    title: 'Username',
    keyName: 'username',
    sortBy: true,
    align: 'center',
  },
  {
    title: 'Listings Created',
    keyName: 'count',
    sortBy: true,
    align: 'center',
    customComponent: function count(row) {
      return <p>{Number(row.count).toLocaleString()}</p>;
    },
  },
  {
    title: 'Profit',
    keyName: 'profit',
    sortBy: true,
    align: 'center',
    customComponent: function cost(row) {
      return (
        <p>
          $
          {Number(row.profit / 100).toLocaleString('en', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      );
    },
  },
  {
    title: 'Commission',
    keyName: 'commission',
    sortBy: true,
    align: 'center',
    customComponent: function cost(row) {
      return (
        <p>
          $
          {Number((row.profit * 0.08) / 100).toLocaleString('en', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      );
    },
  },
];
