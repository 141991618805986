import { Grid } from '@material-ui/core';
import AppSelect from '@src/Components/common/Selects/AppSelect';
import { options } from './constants';
import ListingHooks from '../../ListingHooks';
import { ANALYTICS_TYPE_LABELS, ANALYTIC_INS } from '@oneAppCore/constants/listings';
function ListingChartSelect() {
  const [
    suppReportOptions,
    setSuppChartOptions,
  ] = ListingHooks.useChartOptionsState();

  console.log('options', ANALYTICS_TYPE_LABELS, ANALYTICS_TYPE_LABELS[ANALYTIC_INS]);
  const updateSuppChart = (chart: string = 'in-stock') => {
    setSuppChartOptions((previous) => ({
      ...previous,
      chart,
    }));
  };

  return (
    <>
      <Grid container style={{ marginBottom: 10 }}>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <AppSelect
            label="Chart Select"
            options={options}
            value={options.find(
              (option) => option.value === suppReportOptions.chart,
            )}
            onChange={(option) => {
              updateSuppChart(option?.value);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ListingChartSelect;
