import type { Column } from '@src/Components/common/containers/SearchView/types';
import { Grid, Tooltip, IconButton, Typography } from '@material-ui/core';

import FontAwesome from '@src/Components/common/FontAwesome';

export const columns: Column[] = [
  {
    keyName: 'channelName',
    title: 'Channel',
    sortBy: true,
    align: 'center',
  },
  {
    keyName: 'storeName',
    title: 'Store',
    sortBy: true,
    align: 'center',
  },
  {
    keyName: 'feedType',
    title: 'Feed Type',
  },
  {
    keyName: 'successfulCount',
    title: 'Success',
    customComponent: function success(row) {
      const zeroedCount = row.successfulCount ? row.successfulCount : 0;
      const count = row.failedCount === null ? 'N/A' : zeroedCount;
      return <p>{count}</p>;
    },
  },
  {
    keyName: 'failedCount',
    title: 'Failed',
    customComponent: function failed(row) {
      const zeroedCount = row.failedCount ? row.failedCount : 0;
      const count = row.failedCount === null ? 'N/A' : zeroedCount;
      return <p>{count}</p>;
    },
  },
  {
    keyName: 'errors',
    title: 'Errors',
    customComponent: function errors(row) {
      const errors = row.errors || {};
      const errorKeys = Object.keys(errors);

      return (
        <Grid container item xs={12}>
          {errorKeys.length === 0 &&
            errorKeys.map((key, index) => {
              return (
                <Grid item xs={12} key={`${row.id}-error-${index}`}>
                  <Typography>
                    {key}:{row.errors[key]}
                  </Typography>
                </Grid>
              );
            })}
          {!errorKeys.length && <Typography>No errors captured</Typography>}
        </Grid>
      );
    },
  },
  {
    keyName: 'status',
    title: 'Status',
    align: 'center',
  },
  {
    keyName: 'lastScannedAt',
    title: 'Last Scanned At',
    align: 'center',
  },
  {
    keyName: 'actions',
    title: 'Actions',
    align: 'center',
    customComponent: function actions() {
      return (
        <>
          <Tooltip title={'Actions'}>
            <IconButton style={{ fontSize: '14px' }}>
              <FontAwesome name="ellipsis-v" type="fa" form="fa" />
            </IconButton>
          </Tooltip>
        </>
      );
    },
  },
];
