// import {
//   Card,
//   CardContent,
//   Grid,
//   Paper,
//   Box,
//   Divider,
//   makeStyles,
//   Theme,
// } from '@material-ui/core';
// const keyframes = `
//   @keyframes Gradient {
//     0% {
//       background-position: 0% 50%;
//     }
//     50% {
//       background-position: 100% 50%;
//     }
//     100% {
//       background-position: 0% 50%;
//     }
//   }
// `;
// const styleSheet = document.styleSheets[0];
// styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
// const useStyles = makeStyles((theme: Theme) => ({
//   box: {
//     borderRadius: 10,
//     background: `
//                       linear-gradient(-45deg, #A3A6A9, #FFFFFF,
//                         #A3A6A9, #FFFFFF,#A3A6A9, #FFFFFF)
//                       0% 50%
//                     `,
//     backgroundSize: '200% 200%',
//     animation: 'Gradient 10s ease infinite',
//   },
// }));

// function Loading() {
//   const classes = useStyles();

//   return (
//     <Grid container>
//       <Grid item xs={12}>
//         <Grid container xs={12}>
//           <Card style={{ width: '100%', padding: 20, marginBottom: 30 }}>
//             <Grid item container xs={12} justify="center">
//               <Grid item xs={12}>
//                 <Box className={classes.box}></Box>
//               </Grid>
//             </Grid>
//           </Card>
//         </Grid>
//       </Grid>
//     </Grid>
//   );
// }

// export default Loading;

import {
  Card,
  CardContent,
  Grid,
  Paper,
  Box,
  Divider,
  makeStyles,
  Theme,
} from '@material-ui/core';
const keyframes = `
  @keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
const useStyles = makeStyles((theme: Theme) => ({
  box: {
    borderRadius: 10,
    background: `
                      linear-gradient(-45deg, #A3A6A9, #FFFFFF,
                        #A3A6A9, #FFFFFF,#A3A6A9, #FFFFFF)
                      0% 50%
                    `,
    backgroundSize: '200% 200%',
    animation: 'Gradient 10s ease infinite',
  },
  varBox: {
    borderRadius: 5,
    background: `
                      linear-gradient(-45deg, #A3A6A9, #FFFFFF,
                        #A3A6A9, #FFFFFF,#A3A6A9, #FFFFFF)
                      0% 50%
                    `,
    backgroundSize: '200% 200%',
    animation: 'Gradient 10s ease infinite',
  },
}));
function Loading() {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} style={{ marginTop: 20, padding: 10 }}>
        <Card style={{ width: '100%' }}>
          <Grid
            item
            container
            xs={12}
            justify="flex-start"
            style={{ marginBottom: 20, marginTop: 20, marginLeft: 10 }}
          >
            <Grid item xs={1} style={{ marginRight: 10 }}>
              <Box className={classes.varBox} style={{ height: 20 }}></Box>
            </Grid>
            <Grid item xs={1} style={{ marginRight: 10 }}>
              <Box className={classes.varBox} style={{ height: 20 }}></Box>
            </Grid>
            <Grid item xs={1} style={{ marginRight: 10 }}>
              <Box className={classes.varBox} style={{ height: 20 }}></Box>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20, padding: 10 }}>
        <Card style={{ width: '100%' }}>
          <Grid
            item
            container
            xs={12}
            justify="space-around"
            style={{ marginBottom: 20, marginTop: 20, marginLeft: 10 }}
          >
            <Grid item xs={2}>
              <Box className={classes.varBox} style={{ height: 30 }}></Box>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Divider />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justify="flex-start"
            style={{
              marginBottom: 20,
              marginTop: 20,
              marginLeft: 10,
              padding: 10,
            }}
          >
            <Grid item xs={2}>
              <Box
                className={classes.varBox}
                style={{ height: 30, width: 400 }}
              ></Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                className={classes.varBox}
                style={{ height: 30, width: 400 }}
              ></Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                className={classes.varBox}
                style={{ height: 30, width: 400 }}
              ></Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                className={classes.varBox}
                style={{ height: 30, width: 400 }}
              ></Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                className={classes.varBox}
                style={{ height: 30, width: 400 }}
              ></Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                className={classes.varBox}
                style={{ height: 30, width: 400 }}
              ></Box>
            </Grid>
            <Grid item xs={2} style={{ marginTop: 20 }}>
              <Box
                className={classes.varBox}
                style={{ height: 30, width: 400 }}
              ></Box>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justify="flex-end"
            style={{ padding: 10 }}
          >
            <Box
              className={classes.varBox}
              style={{ height: 40, width: 100 }}
            ></Box>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
export default Loading;
