import { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DonutChart from '@src/Components/common/charts/DonutChart';

import ReportApi from '@oneAppCore/services/ReportApi';
import dateUtils from '@oneAppCore/utils/dates';

import { Props } from '../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  height: {
    [theme.breakpoints.down('md')]: {
      maxHeight: '400px',
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: '100%',
    },
  },
}));

function PieceRateChart({ unitsBack, unitType }: Props) {
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      const { newerThan } = dateUtils.getDaysBack({ unitsBack, unitType });

      const response = await ReportApi.get('ORDERS_COMPLETED_BY', {
        newerThan,
        page: 1,
        limit: 10000,
      });

      const rows = response.rows
        .filter((item) => item.Count > 0)
        .reduce(
          (acc, row) => ({
            ...acc,
            [row.Name]: Number(row.Count) * 0.25,
          }),
          {},
        );

      setData(Object.values(rows));
      setLabels(Object.keys(rows));
    })();
  }, [unitsBack]);

  return (
    <Grid container direction="column" spacing={1}>
      <Grid alignItems="center" justify="center" item container>
        <Typography variant="h5" style={{ color: '#616161' }}>
          Piece Rate
        </Typography>
      </Grid>
      <Grid item xs={12} >
        <DonutChart
          labels={labels}
          data={data}
          title="Piece Rate"
          plugins={{
            legend: {
              display: true,
              position: 'right',
              labels: {
                // usePointStyle: true,
                generateLabels: (chart) => {
                  const datasets = chart.data.datasets;
                  return datasets[0].data.map((data, i) => ({
                    text: `${chart.data.labels[i]} ${data}`,
                    fillStyle: datasets[0].backgroundColor[i],
                  }));
                },
              },
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default PieceRateChart;
