import { memo, useMemo } from 'react';
import { ArcElement/* , Legend */ } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Chart } from 'chart.js';

import type { Props } from '../types';
Chart.register(ArcElement/* , Legend */);
function ReusableDonutChart({
  data,
  labels,
  title,
  plugins: pluginProp,
  colors: colorsProp,
}: Props) {
  const theme = useTheme();

  const colors = useMemo(
    () => [
      theme.palette.primary.light,
      theme.palette.secondary.main,
      theme.palette.warning.dark,
      theme.palette.error.dark,
      theme.palette.info.dark,
      theme.palette.success.dark,
      theme.palette.primary.main,
      theme.palette.secondary.light,
      theme.palette.warning.main,
      theme.palette.error.main,
      theme.palette.info.main,
      theme.palette.success.main,
    ],
    [theme],
  );

  const backgroundColor = colorsProp
    ? useMemo(
      () => data.slice().map((_, index) => colorsProp[index]),
      [data, colorsProp],
    )
    : useMemo(
      () => data.slice().map((_, index) => colors[index % colors.length]),
      [data, colors],
    );

  // const isTablet = useMediaQuery('(max-width:1250px)');
  // const isMobile = useMediaQuery('(max-width:900px)');

  const plugins = Object.assign(
    {
      legend: {
        display: true,
      },
    },
    pluginProp,
  );

  return (
    <Doughnut
      options={{
        responsive: true,
        plugins,
      }}
      data={{
        labels,
        datasets: [
          {
            label: title,
            data,
            borderWidth: 0,
            hoverOffset: 3,
            backgroundColor,
          },
        ],
      }}
    />
  );
}

export default memo(ReusableDonutChart);
