import { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  SwipeableDrawer,
  Grid,
  Button,
  TextField,
  Typography,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import SupplierOrderButton from '@src/Components/Orders/SupplierOrderButton';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import { Props } from './types';
import { columns } from './constants';
import useInventoryScanHist from '@src/hooks/swr/Inventory/useInventoryScanHistory';
import dayjs from 'dayjs';
import { numberGrouper } from '@oneAppCore/utils/formatters';
import Pagination from '@material-ui/lab/Pagination';

const drawerWidth = 400;
const mobileWidth = 260;
interface StyleProps {
  mobile: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
      width: '400px',
    },
    [theme.breakpoints.down('md')]: {
      width: '350px',
    },
    padding: 20,
    justifyContent: 'flex-start',
  },
  title: { paddingTop: theme.spacing(1) },
  root: {
    display: 'flex',
    flexBasis: 0,
    margin: theme.spacing(3, 0),
  },

  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

export default function ViewInventoryDrawer({
  isDrawerOpen,
  setDrawerOpen,
  row,
  mobile,
}: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [scanList, setScanList] = useState({});
  const [fitmentList, setFitmentList] = useState({});
  const [scanListKeys, setScanListKeys] = useState([]);
  const [scanPage, setScanPage] = useState(1);
  const [scanPageCount, setScanPageCount] = useState(10);
  const [fitListKeys, setFitListKeys] = useState([]);
  const [fitPage, setFitPage] = useState(1);
  const [fitPageCount, setFitPageCount] = useState(10);

  const classes = useStyles({ mobile });

  const {
    data: { scanned, purchased, fitted, ordered },
  } = useInventoryScanHist(row.id);

  const noBeforeTypes = ['Inbound', 'Override'];
  const noAfterTypes = ['Order'];


  useEffect(() => {
    setIsLoading(false);
    const obj = {};
    const scanListFunc = (sc) => {
      const { type, date } = sc;
      const name = `${date}-${type}`;
      if (!obj[name]) obj[name] = sc;
    }
    scanned.forEach(scanListFunc);
    purchased.forEach(scanListFunc);
    ordered.forEach(scanListFunc);

    setScanList(obj);
    const scanKeys = Object.keys(obj).sort().reverse();
    const fitKeys = Object.keys(obj).sort().reverse();
    const filteredScanKeys = scanKeys.filter((key, index) => index < scanPageCount * scanPage && index >= scanPageCount * (scanPage - 1));
    const filteredFitKeys = fitKeys.filter((key, index) => index < fitPageCount * fitPage && index >= fitPageCount * (fitPage - 1));
    console.log(filteredFitKeys);
    setScanListKeys(filteredScanKeys);
    setScanListKeys(filteredFitKeys);
  }, [scanned, purchased, ordered]);

  useEffect(() => {
    const obj = {};
    fitted.forEach((fit) => {
      const { make, model, years } = fit;
      if (!obj[make]) obj[make] = {};
      if (!obj[make][model]) obj[make][model] = numberGrouper({ years, formatResult: true });
    })

    setFitmentList(obj);
  }, [fitted])

  useEffect(() => {
    const keys = Object.keys(scanList).sort().reverse();
    const filteredKeys = keys.filter((key, index) => index < scanPageCount * scanPage && index >= scanPageCount * (scanPage - 1));
    // const filteredKeys = keys.filter((key, index) => index < pageCount * page && index >= pageCount * (page - 1))
    setScanListKeys(filteredKeys);
  }, [scanPage]);
  useEffect(() => {
    const keys = Object.keys(fitmentList).sort().reverse();
    const filteredKeys = keys.filter((key, index) => index < fitPageCount * fitPage && index >= fitPageCount * (fitPage - 1));
    // const filteredKeys = keys.filter((key, index) => index < pageCount * page && index >= pageCount * (page - 1))
    setFitListKeys(filteredKeys);
  }, [fitPage]);

  return (
    <>
      <SwipeableDrawer
        open={isDrawerOpen}
        onClose={() => setDrawerOpen()}
        onOpen={() => null}
        classes={{ paper: classes.drawerPaper }}
        anchor="right"
      >
        <Grid container>
          <Grid container alignItems="center" justify="center">
            <Typography
              variant="h4"
              color="primary"
              style={{ marginTop: 15, marginBottom: 15 }}
            >
              Item Details
            </Typography>
          </Grid>
          {columns.map((item, i) => {
            if (item.customComponent) {
              const divider = (<Grid item xs={12}><Divider className={classes.divider} /></Grid>);
              return (
                <>
                  <item.customComponent row={row} />
                  {i < columns.length - 1 ? divider : null}
                </>
              );
            }
            return (
              <Grid key={item.key} container style={{ marginTop: 5 }}>
                <Grid item xs={6}>
                  <Typography style={{ fontWeight: 600, fontSize: '1.15rem' }}>
                    {item.title}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: '1.15rem' }}>
                    {row[item.key]}
                  </Typography>
                </Grid>
                {item.key === 'supplierName' ? (
                  <Grid item xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                ) : null}
              </Grid>
            );
          })}
          {!isLoading &&
            <>
              <Grid item xs={12} container >
                {scanListKeys.length && <Grid item container xs={12} style={{ justifyContent: 'flex-end' }}>
                  {/* <Paginator currentPage={scanPage} showPerPage={false} showFirst={false} showLast={false} siblingCount={1} boundaryCount={2} showCount={false} /> */}
                  <Pagination
                    shape={'rounded'}
                    variant={'text'}
                    size="small"
                    count={Math.ceil(Object.keys(scanList).length / scanPageCount)}
                    color="primary"
                    page={scanPage}
                    onChange={(_, page) => setScanPage(page)}
                    showFirstButton={true}
                    showLastButton={true}
                  />
                </Grid>}
                <Grid item xs={12} container style={{ padding: 0, maxHeight: '275px', overflowY: 'scroll', marginTop: 15 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date (by)</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell>From (to)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {scanListKeys.map((key, scidx) => {
                        const item = scanList[key];
                        console.log(item);
                        let before: any = null;
                        let after: any = item.total;
                    /* if (item.scan) {
                      before = item.total - item.quantity;
                      after = item.total;
                    } 
                    else  */if (noBeforeTypes.includes(item.scanType)) {
                          after = item.total;
                        } else if (noAfterTypes.includes(item.scanType)) {
                          before = item.quantity;
                          after = row.quantity - item.quantity;
                        } else if (item.total === 0) {
                          before = item.total;
                          after = item.quantity;
                        } else {
                          before = item.total - item.quantity;
                        }
                        return (
                          <TableRow key={`scanlist-${scidx}`} style={{ paddingBottom: '4px', paddingTop: '4px' }}>
                            <TableCell style={{ paddingBottom: '4px', paddingTop: '4px' }}>{dayjs(key.split('-M')[0]).format('MMM DD, YY')}<br />({item.username})</TableCell>
                            <TableCell style={{ paddingBottom: '4px', paddingTop: '4px' }}>{item.scanType}</TableCell>
                            <TableCell style={{ paddingBottom: '4px', paddingTop: '4px' }}>{item.quantity}</TableCell>
                            <TableCell style={{ paddingBottom: '4px', paddingTop: '4px' }}>{before} ({after})</TableCell>
                            {/* <TableCell>{item.purchasedAt || item.scannedAt}</TableCell> */}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
              <Grid item xs={12} container>
                {scanListKeys.length && <Grid item container xs={12} style={{ justifyContent: 'flex-end' }}>
                  {/* <Paginator currentPage={scanPage} showPerPage={false} showFirst={false} showLast={false} siblingCount={1} boundaryCount={2} showCount={false} /> */}
                  <Pagination
                    shape={'rounded'}
                    variant={'text'}
                    size="small"
                    count={Math.ceil(Object.keys(fitmentList).length / fitPageCount)}
                    color="primary"
                    page={fitPage}
                    onChange={(_, page) => setFitPage(page)}
                    showFirstButton={true}
                    showLastButton={true}
                  />
                </Grid>}
                <Grid item xs={12} container style={{ padding: 0, maxHeight: '275px', overflowY: 'scroll', marginTop: 15 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Year</TableCell>
                        <TableCell>Make</TableCell>
                        <TableCell>Model</TableCell>
                        <TableCell>Type</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(fitmentList).sort().map((make) => {
                        return (Object.keys(fitmentList[make]).sort().map((model) => {
                          return (
                            <TableRow key={`fitment-${row.id}-${make}-${model}`}>
                              <TableCell>{fitmentList[make][model]}</TableCell>
                              <TableCell>{make}</TableCell>
                              <TableCell>{model}</TableCell>
                              <TableCell title='This is currently hard coded pending data validation'>ATV</TableCell>
                            </TableRow>
                          )
                        }));
                      })}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </>
          }
        </Grid>
      </SwipeableDrawer>
    </>
  );
}
