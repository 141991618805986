import Grid from '@material-ui/core/Grid';

import ReportSelect from './ReportSelect';
import ReportOptions from './ReportOptions';
import ReportDownload from './ReportDownload';
import ReportTable from './ReportTable';
import ReportPaginator from './ReportPaginator';
import ReportRunHist from './RunHistoryTable';
import ReportCards from './ReportCards';

function Reports() {
  return (
    <Grid container spacing={3} direction="column">
      {/* <Grid item container>
        <ReportSelect />
      </Grid> */}
      <Grid item container>
        <ReportCards />
      </Grid>
      <Grid item container>
        <ReportRunHist />
      </Grid>
    </Grid>
  );
}

export default Reports;
