import useMeStores from '@src/hooks/swr/useMeStores';
import useSuppliers from '@src/hooks/swr/useSuppliers';

import { columns } from './constants';
import { Grid } from '@material-ui/core';
import SearchView from '@src/Components/common/containers/SearchView';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { getColor } from '@src/utils/colors';
import MiniReports from './MiniReports';
import OrderViewDrawer from '../OrderDrawer';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    paddingTop: theme.spacing(1),
    width: '100%',
  },
  quantity: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 20,
    width: 20,
    backgroundColor: '#ffffff',
  },
  title: {
    textOverflow: 'ellipsis',
    display: 'block',
    maxWidth: '300px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    lineHeight: 1,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  orderNumber: {
    padding: 10,
    borderRadius: 10,
    width: 100,
    height: 10,
    marginTop: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  bold: {
    fontWeight: 'bold',
    lineHeight: 0.5,
  },
  red: {
    color: getColor('dark_red', 'dark'),
    borderColor: getColor('dark_red', 'dark'),
    backgroundColor: getColor('light_red', 'light'),
    borderWidth: '1.5px',
  },
  black: {
    color: getColor('black', 'main'),
    borderColor: getColor('black', 'main'),
  },
}));

function OrdersSearch() {
  const classes = useStyles();
  const { data: stores = [] } = useMeStores();
  const { data: suppliers = [] } = useSuppliers();

  const storeOptions = stores.map((store) => ({
    label: store.name,
    value: store.id,
  }));

  const supplierOptions = suppliers.map((supplier) => ({
    label: supplier.name,
    value: supplier.id,
  }));

  return (
    <Grid item container justify="center" alignItems="center" spacing={1}>
      <SearchView
        url="api/v1/orders/search"
        ViewDrawerComponent={OrderViewDrawer}
        constantFilters={{
          miniReport: 'show',
        }}
        filters={[
          {
            type: 'date range',
            name: 'Purchased At',
            keyName: 'purchasedAt',
          },
          {
            type: 'string',
            name: 'Order Number',
            keyName: 'orderNumber',
          },
          {
            type: 'selector',
            name: 'Status',
            keyName: 'status',
            properties: [
              { label: 'Unassigned', value: 'unassigned' },
              { label: 'Tracked', value: 'tracked' },
              { label: 'Placed', value: 'placed' },
              { label: 'Issue', value: 'issue' },
            ],
          },
          {
            type: 'selector',
            name: 'Store',
            keyName: 'storeId',
            properties: storeOptions,
          },
          {
            type: 'string',
            name: 'Shipping Name',
            keyName: 'shippingName',
          },
          {
            type: 'equality',
            name: 'Profit',
            keyName: 'profit',
          },
          {
            type: 'selector',
            name: 'Supplier',
            keyName: 'supplierId',
            properties: supplierOptions,
          },
          {
            type: 'string',
            name: 'Supplier Order #',
            keyName: 'supplierOrderNumber',
          },
        ]}
        columns={columns(suppliers, classes)}
        MiniReportComponent={MiniReports}
        showChannelOptions
      />
    </Grid>
  );
}

export default OrdersSearch;
