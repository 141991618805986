import { dateOptions } from './constants';

import AppSelect from '@src/Components/common/Selects/AppSelect';
import Grid from '@material-ui/core/Grid';

import type { Props } from './types';

function TimePeriodSelect({ newerThan, updateNewerThan }: Props) {
  return (
    <Grid item container xs={6} sm={4} lg={2}>
      <AppSelect
        options={dateOptions}
        label="Time Period"
        value={dateOptions.find((option) => option.value === newerThan)}
        onChange={(e) => updateNewerThan(e.value)}
      />
    </Grid>
  );
}

export default TimePeriodSelect;
