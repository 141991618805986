import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { SupplierOrderButtonProps } from './types';
import Radio from '@material-ui/core/Radio';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import { WPS, AD } from '@oneAppCore/constants/suppliers';
const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',

    width: '100%',
  },
  image: {
    height: 50,
    width: 50,
    marginBottom: 10,
    marginTop: 10,
  },
  empty: {
    height: 50,
    width: 50,
    marginBottom: 10,
    marginTop: 10,
  },
});

const SupplierOrderButton: React.FC<SupplierOrderButtonProps> = ({
  row,
  supplier,
  isLoading,
  supplierRadioSelection,
  onRadioChange,
  available,
}) => {
  const classes = useStyles();

  return (
    <>
      {row.orderItems.length === 1 ? (
        row.orderItems[0].variationImages?.[0] ? (
          <Grid
            onClick={() => {
              (supplier !== AD && supplier !== WPS) || !available || isLoading
                ? null
                : onRadioChange(supplier);
            }}
          >
            <img
              className={classes.image}
              src={row.orderItems[0].variationImages?.[0].imageUrl}
            />
          </Grid>
        ) : (
          <Grid
            onClick={() => {
              (supplier !== AD && supplier !== WPS) || !available || isLoading
                ? null
                : onRadioChange(supplier);
            }}
          >
            <img
              className={classes.image}
              src={`https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`}
              alt="placeholder image for product image"
            />
          </Grid>
        )
      ) : null}
      <Grid className={classes.container}>
        {supplier === WPS || supplier === AD ? (
          <>
            <Radio
              title={`Select ${supplier}`}
              checked={supplierRadioSelection === supplier}
              size="small"
              key={row.id}
              disabled={!available || isLoading}
              onChange={() => {
                onRadioChange(supplier);
              }}
              value={supplier}
              name={`${supplier} select radio button`}
              inputProps={{ 'aria-label': `${supplier} select radio button` }}
            />
            {row.orderItems.length > 1 ? (
              <Grid className={classes.empty}></Grid>
            ) : null}
          </>
        ) : row.orderItems.length > 1 ? (
          <Grid className={classes.empty}></Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default SupplierOrderButton;
