import { Grid } from '@material-ui/core';
import AppSelect from '@src/Components/common/Selects/AppSelect';
// import { options } from './constants';
import SupplierHooks from '../../SupplierHooks';
import SupplierApi from '@oneAppCore/services/SupplierApi';
import { useEffect, useState } from 'react';
function SupplierChartSelect() {
  const [
    suppReportOptions,
    setSuppChartOptions,
  ] = SupplierHooks.useChartOptionsState();

  const [options, setOpts] = useState([]);
  useEffect(() => {
    const getSupp = async () => {
      const option = await SupplierApi.getSuppliers();
      setOpts([{ label: 'All Suppliers', value: -1 }, ...option.map((opt) => ({ label: opt.name, value: opt.id }))]);
    };
    getSupp();
  }, [])

  const updateSuppChart = (id: number = -1, name: string = 'All Suppliers') => {
    setSuppChartOptions((previous) => ({
      ...previous,
      chartId: id,
      chart: name,
    }));
  };

  return (
    <>
      <Grid container style={{ marginBottom: 10 }}>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <AppSelect
            label="Chart Select"
            options={options}
            value={options.find(
              (option) => option.value === suppReportOptions.chartId,
            )}
            onChange={(option) => {
              updateSuppChart(option?.value, option?.label);
              // updateSuppChart(option?.value || 'none', );
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default SupplierChartSelect;
