import { memo } from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Select from 'react-select';

import type { Props } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  selectContainer: {
    width: '100%',
    position: 'relative',
    marginBottom: -20,
  },
  label: {
    color: theme.palette.grey[700],
  },
  error: {
    color: 'red',
  },
}));

function AppSelect(props: Props) {
  const classes = useStyles();

  const {
    containerClass,
    storeError,
    brandError,
    label,
    labelClass,
    style,
    ...rest
  } = props;

  return (
    <div
      className={`${containerClass || classes.selectContainer} ${
        storeError ? classes.error : ''
      }`}
    >
      {label && (
        <Typography
          variant="caption"
          component="span"
          // className={labelClass || classes.label}
          className={`${labelClass || classes.label} ${
            storeError ? classes.error : ''
          }`}
        >
          {`${label}${props.required ? '*' : ''}`}
        </Typography>
      )}
      <Select
        style={style}
        {...rest}
        styles={{
          dropdownIndicator: (provided, state) => ({
            ...provided,
            transform: state.selectProps.menuIsOpen && 'rotate(-180deg)',
            transitionDuration: '0.3s',
            transitionProperty: 'transform',
          }),
          control: (provided, state) => ({
            ...provided,
            backgroundColor: style?.backgroundColor || provided.backgroundColor,

            borderColor:
              storeError || brandError ? 'red' : provided.borderColor,
          }),
        }}
      />
      {!props.disabled && (
        <input
          required={Boolean(props?.required)}
          tabIndex={-1}
          autoComplete="off"
          name={props.label || 'Select'}
          style={{
            opacity: 0,
            height: 0,
            width: '100%',
          }}
          value={props?.value?.value ? 'hasValue' : ''}
          onChange={() => null}
        />
      )}
    </div>
  );
}

export default memo(AppSelect);
