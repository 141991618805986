import { TableRow, TableCell, TextField, IconButton } from '@material-ui/core';
import { Column } from '@src/Components/common/containers/SearchView/types';
import { FontAwesome } from '@src/Components/common';
import { useEffect, useState } from 'react';
import { CreateVariation, Props } from './types';
import { Form, FitmentForm, VariationsForm } from '../../types';
import VariationsApi from '@oneAppCore/services/VariationsApi';
import { useSnackbar } from 'notistack';
import FileUpload from '@src/Components/common/buttons/FileUpload';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { primary } from '@src/Components/Theme/themes/constants';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';

const AddVariation = ({
  columns,
  product,
  mutate,
  variation,
  deleteRow,
  variationIndex,
  removeVariation,
  varOptions,
}: {
  columns?: Column[];
  product?: Form;
  variation?: any;
  deleteRow?: any;
  mutate?: any; // ? Ask tanner what the type would be for this
  variationIndex?: number;
  removeVariation?: (type: number) => void;
  varOptions?: any;
}) => {
  const [newVariation, setNewVariation] = useState<CreateVariation>({
    title: '',
    sku: '',
    manufacturerNo: '',
    productId: 0,
    upc: '',
    quantityPerBox: 0,
    itemPrice: 0,
    variantType: '',
  });

  const [url, setUrl] = useState('');
  const [fitment, setFitment] = useState<FitmentForm[][]>([[]]);
  const [form, setForm] = useState<VariationsForm[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleCreateVariation = async () => {
    try {
      if (
        newVariation.title === '' ||
        newVariation.sku === '' ||
        newVariation.manufacturerNo === ''
      )
        return;
      else {
        const {
          title: variation,
          sku,
          manufacturerNo,
          upc,
          quantityPerBox,
          itemPrice,
          variantType,
        } = newVariation;
        const { id: productId, brandId } = product;
        await VariationsApi.post({
          variation,
          sku,
          manufacturerNo,
          productId,
          brand: brandId,
          upc,
          quantityPerBox,
          url,
          itemPrice,
          variantType: varOptions,
        }).then(() => {
          enqueueSnackbar('Created A Variation!', { variant: 'success' });
          setNewVariation({
            productId: 0,
            title: '',
            sku: '',
            manufacturerNo: '',
            upc: '',
            quantityPerBox,
            itemPrice,
            variantType: '',
          });
          mutate();
        });
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  return (
    <TableRow>
      {columns?.map((column) => {
        if (column.keyName === 'multipack') {
          return (
            <TableCell key={column.keyName} style={{ textAlign: 'center' }}>
              <AddCircleTwoToneIcon
                onClick={handleCreateVariation}
                fontSize="large"
                style={{ color: '#3C9629' }}
              />
            </TableCell>
          );
        } else if (column.keyName === 'image') {
          return (
            <TableCell key={column.keyName}>
              <FileUpload
                id="any"
                onUpload={(uploadUrl) => setUrl(uploadUrl[0])}
              />
            </TableCell>
          );
        } else if (column.keyName === 'title') {
          return (
            <TableCell key={column.keyName}>
              <TextField
                style={{
                  width: 'fit-content',
                  backgroundColor: primary.transparent.main,
                }}
                label={varOptions?.charAt(0)?.toUpperCase() + varOptions?.slice(1)}
                variant="outlined"
                value={newVariation[column.keyName]}
                onChange={(e) =>
                  setNewVariation((nv) => ({
                    ...nv,
                    [column.keyName]: e.target.value,
                  }))
                }
              />
            </TableCell>
          );
        } else if (column.editable) {
          return (
            <TableCell key={column.keyName}>
              <TextField
                style={{
                  width: 'fit-content',
                  backgroundColor: primary.transparent.main,
                }}
                label={column.title}
                variant="outlined"
                value={newVariation[column.keyName]}
                onChange={(e) =>
                  setNewVariation((nv) => ({
                    ...nv,
                    [column.keyName]: e.target.value,
                  }))
                }
              />
            </TableCell>
          );
        } else if (column.keyName === 'supfit') {
          return (
            <TableCell key={column.keyName}>
              <CancelTwoToneIcon
                onClick={() => removeVariation(variationIndex)}
                fontSize="large"
                style={{ color: '#FC0301' }}
              />
            </TableCell>
          );
        } else {
          // ? This is to maintain the spacing consistancy for each column.
          return <TableCell key={column.keyName} />;
        }
      })}
    </TableRow>
  );
};

export default AddVariation;
