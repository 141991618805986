import { useState, useEffect, FormEvent } from 'react';

import VariationsApi from '@oneAppCore/services/VariationsApi';
import { useSnackbar } from 'notistack';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import AsyncSelect from '@src/Components/common/Selects/AsyncSelect';
import TextField from '@src/Components/common/TextField';
import { Backdrop } from '@src/Components/common/Loaders';
import useMe from '@src/hooks/swr/useMe';
import useMeSuppliers from '@src/hooks/swr/useMeSuppliers';
import InventoryDrawer from '../../../Search/InventoryDrawer';

import type { Props, CreateUPCForm } from './types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 360,
    padding: theme.spacing(2),
  },
}));

export default function CreateUPCDrawer({ data, open, close, upc }: Props) {
  const { data: supplierList } = useMeSuppliers();
  const classes = useStyles();
  const [form, setForm] = useState<CreateUPCForm>({ quantityPerBox: 1 });
  const [isNew, setIsNew] = useState(false);
  const [options, setOptions] = useState([]);
  const [saving, setSaving] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const loadVariations = async (filter: string) => {
    if (filter?.length < 3) return [];
    const supplier = supplierList?.map((supp) => supp.id) || [7];
    const { data: variations } = await VariationsApi.search({
      filter,
      supplier,
    });
    const newOptions = variations.map((variation) => ({
      value: variation.id,
      label: `${variation.sku} - ${variation.brand} ${variation.manufacturerNo}`,
    }));
    setOptions(newOptions);
    return newOptions;
  };

  const update = <K extends keyof CreateUPCForm>(
    key: K,
    value: CreateUPCForm[K],
  ) => {
    setForm((previous) => ({
      ...previous,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (upc) setForm((previous) => ({ ...previous, upc }));
  }, [upc]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setSaving(true);
    try {
      await VariationsApi.postUpc(form);
      enqueueSnackbar('Created UPC Mapping', { variant: 'success' });
      setForm({ quantityPerBox: 1 });
      close(true);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      setSaving(false);
    }
  };

  const closeDrawer = () => {
    setIsNew(false);
    close();
  };

  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={() => {
          close();
          setIsNew(false);
        }}
        className={classes.root}
        onOpen={null}
      >
        <Grid container direction="column" className={classes.root}>
          <form onSubmit={onSubmit}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography variant="h6">Add Product Mapping</Typography>
              </Grid>
              <Grid item>
                <AsyncSelect
                  id="variation"
                  label="Find Product by sku,mpn, or brand"
                  // label="Product"
                  loadOptions={loadVariations}
                  value={options.find(
                    (option) => option.value === form.variationId,
                  )}
                  onChange={(e) => update('variationId', e.value)}
                />
              </Grid>
              {form.variationId && (
                <>
                  <Grid item>
                    <TextField
                      label="UPC"
                      id="upc"
                      value={form?.upc ?? ''}
                      onChange={(e) => update('upc', e.currentTarget.value)}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Package Quantity"
                      id="quantityPerBox"
                      value={form?.quantityPerBox ?? ''}
                      onChange={(e) =>
                        update(
                          'quantityPerBox',
                          e.currentTarget.value !== ''
                            ? Number(e.currentTarget.value)
                            : null,
                        )
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Button type="submit" variant="contained" color="primary">
                      Create
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
          <Button
            color="primary"
            variant="contained"
            size="small"
            style={{ marginTop: 15 }}
            onClick={() => setIsNew(true)}
          >
            Add New
          </Button>
        </Grid>
        <Backdrop open={saving} />
      </SwipeableDrawer>
      {isNew && (
        <InventoryDrawer
          invData={data}
          isDrawerOpen={open}
          setDrawerOpen={closeDrawer}
        />
      )}
    </div>
  );
}
