import { useState } from 'react';

import {
  Grid,
  Card,
  makeStyles,
  CardContent,
  Typography,
  Divider,
  FormGroup,
  FormControlLabel,
  Switch,
  Chip,
  Box,
  TextField,
  Button,
  IconButton,
  withStyles,
} from '@material-ui/core';
// import InboxIcon from '@material-ui/icons/';
import FontAwesome from '@src/Components/common/FontAwesome';

const useStyles = makeStyles({
  button: {
    marginTop: 10,
    minWidth: '100%',
  },
});

function ActionCard() {
  const classes = useStyles();

  return (
    <Grid container alignItems="center">
      <Card style={{ marginLeft: 20, minWidth: '100%' }}>
        <CardContent>
          <Grid item container justify="center" xs={12}>
            <Button
              disabled
              className={classes.button}
              variant="contained"
              color="secondary"
            >
              Send Invoice
            </Button>
          </Grid>

          <Grid item container justify="center" xs={12}>
            <Button
              disabled
              className={classes.button}
              variant="contained"
              color="primary"
            >
              Add Payment
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default ActionCard;
