import { memo, useMemo } from 'react';
import { Chart, PointElement, LineElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import type { Props } from '../types';
Chart.register(PointElement, LineElement);
function ResusableLineChart({
  data: dataProp,
  labels,
  title,
  plugins: pluginProp,
  datasets,
  stacked,
}: Props) {
  const theme = useTheme();

  const colors = useMemo(
    () => [theme.palette.primary.main, theme.palette.secondary.main],
    [theme],
  );

  const backgroundColor = useMemo(
    () => dataProp?.slice().map((_, index) => colors[index % 6]),
    [dataProp, colors],
  );
  const plugins = Object.assign(
    {
      legend: {
        display: false,
      },
    },
    pluginProp,
  );

  const isTablet = useMediaQuery('(max-width:1250px)');
  const isMobile = useMediaQuery('(max-width:900px)');

  const minSize = useMemo(
    () => (isMobile ? '250px' : isTablet ? '300px' : '450px'),
    [isMobile, isTablet],
  );

  const dataObject = {
    labels: labels.map((l) => l),
    datasets: stacked
      ? dataProp.map((dataPoint) => ({
          backgroundColor,
          ...dataPoint,
          borderWidth: 3,
          hoverOffset: 5,
        }))
      : [
          {
            backgroundColor,
            label: title,
            data: dataProp[0],
            borderWidth: 3,
            hoverOffset: 5,
          },
        ],
  };
  return (
    <div
      style={{
        minHeight: minSize,
      }}
    >
      <Line
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins,
          elements: {
            line: {
              tension: 0.1,
            },
          },
          scales: stacked
            ? {
                y: { stacked: false },
                x: {
                  stacked: true,
                  grid: {
                    display: false,
                  },
                },
              }
            : {},
        }}
        data={dataObject}
      />
    </div>
  );
}

export default memo(ResusableLineChart);
