import { useMemo, useState, useEffect, FormEvent } from 'react';
import { useSnackbar } from 'notistack';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';

import type { Props } from './types';

export default function ProgressBar({ open, close }: Props) {
  const closeDialog = () => {
    close(false);
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="progress-modal"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="progress-modal">Progress</DialogTitle>
      <DialogContent>
        <Grid>
          <Typography variant="h6" style={{ marginBottom: 5 }}>
            Suppliers
          </Typography>
        </Grid>
        <Grid container spacing={1}>
          <Grid id="the-d-zone" item xs={12} style={{ marginBottom: 15 }}>
            <Typography variant="body2">The D Zone</Typography>
            <LinearProgress
              style={{ height: 10, borderRadius: 10 }}
              variant="determinate"
            />
          </Grid>
          <Grid id="rockyMountian" item xs={12} style={{ marginBottom: 15 }}>
            <Typography variant="body2">Rocky Mountain ATVMC</Typography>
            <LinearProgress
              style={{ height: 10, borderRadius: 10 }}
              variant="determinate"
            />
          </Grid>
          <Grid
            id="tucker-powersports"
            item
            xs={12}
            style={{ marginBottom: 15 }}
          >
            <Typography variant="body2">Tucker Powersports</Typography>
            <LinearProgress
              style={{ height: 10, borderRadius: 10 }}
              variant="determinate"
            />
          </Grid>
          <Grid
            id="automatic-distributors"
            item
            xs={12}
            style={{ marginBottom: 15 }}
          >
            <Typography variant="body2">Automatic Distributors</Typography>
            <LinearProgress
              style={{ height: 10, borderRadius: 10 }}
              variant="determinate"
            />
          </Grid>
          <Grid
            id="western-powersports"
            item
            xs={12}
            style={{ marginBottom: 15 }}
          >
            <Typography variant="body2">Western Powersports</Typography>
            <LinearProgress
              style={{ height: 10, borderRadius: 10 }}
              variant="determinate"
            />
          </Grid>
        </Grid>

        <Grid>
          <Typography variant="h6" style={{ marginBottom: 5, marginTop: 20 }}>
            Channels
          </Typography>
        </Grid>
        <Grid container spacing={1}>
          <Grid id="amazon" item xs={12} style={{ marginBottom: 15 }}>
            <Typography variant="body2">Amazon</Typography>
            <LinearProgress
              style={{ height: 10, borderRadius: 10 }}
              variant="determinate"
            />
          </Grid>
          <Grid id="walmart" item xs={12} style={{ marginBottom: 15 }}>
            <Typography variant="body2">Walmart</Typography>
            <LinearProgress
              style={{ height: 10, borderRadius: 10 }}
              variant="determinate"
            />
          </Grid>
          <Grid id="ebay" item xs={12} style={{ marginBottom: 15 }}>
            <Typography variant="body2">ebay</Typography>
            <LinearProgress
              style={{ height: 10, borderRadius: 10 }}
              variant="determinate"
            />
          </Grid>
          <Grid id="woocomm" item xs={12} style={{ marginBottom: 15 }}>
            <Typography variant="body2">Woocommerce</Typography>
            <LinearProgress
              style={{ height: 10, borderRadius: 10 }}
              variant="determinate"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeDialog}
          color="primary"
          style={{ marginBottom: 15, marginRight: 15 }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
