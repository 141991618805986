import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

import { colorTheme } from '@oneAppCore/constants/internalvdropship';

import Grid from '@material-ui/core/Grid';
import { createMuiTheme as createTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LineChart from '@src/Components/common/charts/LineChart';

import ReportApi from '@oneAppCore/services/ReportApi';

import { Props } from '../types';
import { unitDateLabel } from '../utilities';
import { makeStyles, Theme } from '@material-ui/core/styles';
import dateUtils from '@oneAppCore/utils/dates';

const useStyles = makeStyles((theme: Theme) => ({
  width: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
  },
  height: {},
}));

function InternalDropshipChart({ unitsBack, unitType }: Props) {
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const theme = createTheme(colorTheme);
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      const { newerThan } = dateUtils.getDaysBack({ unitsBack, unitType });
      setData([]);
      const response = await ReportApi.get('INTERNAL_DROPSHIP', {
        newerThan,
        page: 1,
        limit: 1000000,
      });

      const rows = response.rows;
      const isToday = rows.some((row) => 'hour' in row);

      if (isToday) {
        const hours = Array.from({ length: 24 }, (_, i) => {
          const hour = i % 12 || 12;
          const period = i < 12 ? 'AM' : 'PM';
          return `${hour.toString().padStart(2, '0')}:00 ${period}`;
        });

        const rowss = response.rows;

        const channels = Array.from(
          new Set(rowss.map((row) => row['Order Type'])),
        );

        const orderedRows = channels.map((channel) => ({
          data: hours.map((hour) => {
            const found = rowss.find(
              (row) =>
                dayjs(row.hour).format('hh:00 A') === hour &&
                row['Order Type'] === channel,
            );
            return found ? Number(found.order_count) : 0;
          }),
          label: channel,
          backgroundColor: theme.palette[channel].main,
          borderColor: theme.palette[channel].main,
          hoverOffset: 5,
        }));

        setLabels(hours);
        setData(orderedRows);
      } else {
        const obj = {};
        const rows = response.rows.reduce((acc, row) => {
          const keyName = dayjs(
            row['Purchase Date'],
            'MM-DD-YYYY HH:mm:ss',
          ).format('MM/DD/YYYY');
          return {
            ...acc,
            [keyName]: acc[keyName]
              ? acc[keyName] + row['Quantity']
              : row['Quantity'],
          };
        }, {});
        const labelRows = Object.keys(rows)
          // .sort()
          .reduce(
            (acc, key) => ({
              ...acc,
              [key]: rows[key],
            }),
            {},
          );
        response.rows.forEach((row) => {
          if (!obj[row['Order Type']]) obj[row['Order Type']] = [];
          obj[row['Order Type']].push(row);
        });

        const orderedRows = [];

        Object.keys(obj).forEach((objKey) => {
          const rows = obj[objKey].reduce((acc, row) => {
            const keyName = dayjs(
              row['Purchase Date'],
              'MM-DD-YYYY HH:mm:ss',
            ).format('MM/DD/YYYY');
            return {
              ...acc,
              [keyName]: acc[keyName]
                ? acc[keyName] + row['Quantity']
                : row['Quantity'],
            };
          }, {});
          let arrayOfOrderedRows = [];
          Object.keys(labelRows)
            .filter((name) => name !== 'length')
            // .sort()
            .forEach((labelName) => {
              if (!arrayOfOrderedRows[labelName])
                arrayOfOrderedRows[labelName] = 0;
            });
          const reducedRows = Object.keys(labelRows)
            // .sort()
            .reduce((acc, key) => {
              let returningReducedObject = {
                ...acc,
                [key]: rows[key],
              };
              if (!rows[key])
                returningReducedObject = {
                  ...acc,
                  [key]: 0,
                };
              return returningReducedObject;
            }, {});
          arrayOfOrderedRows = Object.keys(reducedRows).map(
            (reducedRow, index) => {
              return reducedRows[reducedRow];
            },
          );
          const ret = {
            data: arrayOfOrderedRows,
            label: objKey,
            backgroundColor: theme.palette[objKey].main,
            borderColor: theme.palette[objKey].main,
            hoverOffset: 5,
          };
          orderedRows.push(ret);
        });

        setData(orderedRows);
        setLabels(Object.keys(labelRows).filter((name) => name !== 'length'));
      }
    })();
  }, [unitsBack]);
  const unitsLabel = `Internal vs. Dropship ${unitDateLabel({
    unitsBack,
    unitType,
  })}`;

  return (
    <Grid
      container
      justify="center"
      direction="column"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h5">{unitsLabel}</Typography>
      </Grid>
      <Grid item className={classes.width}>
        <LineChart
          labels={labels}
          data={data}
          stacked
          title={unitsLabel}
          plugins={{
            legend: {
              display: true,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default InternalDropshipChart;
