import { useState } from 'react';
import { startCase, lowerCase } from 'lodash';
import FontAwesome from '@src/Components/common/FontAwesome';
import { IconButton, Tooltip, Grid } from '@material-ui/core';
import type { Props } from './types';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';
import RestockDrawer from '../RestockDrawer/RestockDrawer';

function ViewDrawer({ row }: Props) {
  const [open, setOpen] = useState(false);

  const close = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid item container style={{ justifyContent: 'flex-end' }}>
        <Tooltip title={`View`}>
          <span>
            <IconButton onClick={() => setOpen(true)}>
              <VisibilityTwoToneIcon color="primary" fontSize="default" />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      <RestockDrawer isDrawerOpen={open} setDrawerOpen={close} row={row} />
    </>
  );
}

export default ViewDrawer;
