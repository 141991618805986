import React from 'react';

import { TextField as MuiTextField, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import type { TextFieldProps } from '@material-ui/core/TextField';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    color: theme.palette.grey[700],
  },
}));

function TextField(props: TextFieldProps) {
  const classes = useStyles();
  return (
    <div className={classes.container} key={props.name}>
      {props.label && <Typography variant="caption" component="span" className={classes.label}>
        {`${props.label}${props.required ? '*' : ''}`}
      </Typography>}
      <MuiTextField
        placeholder={`${props.label}...`}
        {...props}
        label=""
        variant="outlined"
      />
    </div>
  );
}

export default TextField;
