import {
  TableCell,
  TableRow,
  Select,
  MenuItem,
  Typography,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import FontAwesome from '@src/Components/common/FontAwesome';
import { useState } from 'react';
import { PackValues } from './types';
import FitmentApi from '@oneAppCore/services/FitmentApi';
import FileUpload from '@src/Components/common/buttons/FileUpload';
import { useSnackbar } from 'notistack';
import { number } from 'joi';
import VariationsApi from '@oneAppCore/services/VariationsApi';

const AddPackRow = ({
  variationId,
  mutate,
}: {
  variationId: number;
  mutate: any;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [url, setUrl] = useState('');
  const [packType, setPackType] = useState('');
  const [newPack, setNewPack] = useState<PackValues>({
    packType: '0',
    // sku: '',
    quantity: 0,
  });

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPackType(event.target.value as string);
  };

  /* Set up this function to use when the back end is ready.
  might need to change a little bit when we have the data */

  const addNewPack = async () => {
    try {
      if (newPack.title === '0' || newPack.quantity === 0) {
        enqueueSnackbar('The pack type and quantity must be set!', {
          variant: 'error'
        });
      } else {
        console.log('cvb', {
          ...newPack,
          variationId,
        });
        const createPack = await VariationsApi.createPack({
          ...newPack,
          variationId,
        });
        enqueueSnackbar('Created new pack successfully!', {
          variant: 'success'
        });
        setNewPack({ title: '', quantity: 0 });
        mutate();
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }
  }

  /* This is just a function to show a snackbar so the
check does something until we can use the above function */
  const enquePack = () => {
    enqueueSnackbar(`Created a new pack!, ${Object.values(newPack)}`, {
      variant: 'success',
    });
  };

  const packTypeValues = [
    { label: 'Set of', value: 'Set of' },
    // { label: 'Group of', value: 'Group of' },
    { label: 'Pack', value: 'Pack' },
  ];

  return (
    <TableRow>
      <TableCell>
        <FontAwesome
          name="check"
          type="fas"
          form="fa"
          style={{ cursor: 'pointer', color: '#50C878' }}
          onClick={() => {
            addNewPack();
          }}
        />
      </TableCell>
      <TableCell>
        <FileUpload id="any" onUpload={(uploadUrl) => setUrl(uploadUrl[0])} />
      </TableCell>
      <TableCell>
        <Select
          value={newPack.packType}
          onChange={(e) => {
            setNewPack({ ...newPack, packType: e.target.value as string })
          }}
        >
          <MenuItem value={'0'} >Select a Pack Type</MenuItem>
          {packTypeValues.map((val, index) => {
            return (
              <MenuItem key={`new-pack-name-option-${index + 1}`} value={val.value}>{val.label}</MenuItem>
            )
          })}
        </Select>
      </TableCell>
      {/* <TableCell>
        <Typography>sku</Typography>
      </TableCell> */}
      {/* <TableCell>
        <Typography>Price</Typography>
      </TableCell> */}
      <TableCell>
        <TextField
          variant="outlined"
          label="Quantity"
          type='number'
          value={newPack.quantity || null}
          onChange={(e) => {
            setNewPack({ ...newPack, quantity: Number(e.currentTarget.value) })
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default AddPackRow;
