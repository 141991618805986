import React, { useState } from 'react';

import { Typography, Tooltip } from '@material-ui/core';
import TextField from '@src/Components/common/TextField';
import { primary } from '@src/Components/Theme/themes/constants';

function InlineEdit(props) {
  const [isEditing, setIsEditing] = useState(false);
  const [inlineValue, setInlineValue] = useState(props.value);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      props.saveHandler(inlineValue, props.data);
      setIsEditing(false);
    }
  };

  const onFocusLost = (e) => {
    setIsEditing(false);
  };

  const onChange = (e) => {
    setInlineValue(e.currentTarget.value);
  };

  return (
    <div key={props.name}>
      {isEditing && (
        <TextField
          label={props.label}
          value={inlineValue}
          autoFocus={true}
          onKeyDown={handleKeyDown}
          onChange={onChange}
          onBlur={onFocusLost}
          helperText='Press Enter to Save'
        />
      )}
      {!isEditing && (
        <Tooltip style={{ cursor: 'pointer', color: primary.main }} title={`Click to edit ${props.label}`}>
          <Typography onClick={handleEdit}>
            {inlineValue ? inlineValue : 'unassigned'}
          </Typography>
        </Tooltip>
      )}
    </div>
  );
}

export default InlineEdit;
