import numberUtils from '@oneAppCore/utils/numbers';

import SearchViewMiniReport from '@src/Components/common/containers/SearchViewMiniReport';
import { Grid, Typography, Tooltip } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { formatCentsToDollars } from '@src/utils/currency';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    number: {
      fontWeight: 'bold',
    },
  }),
);

function CostBreakdown(props: any) {
  const classes = useStyles();
  
  return (
    <SearchViewMiniReport>
      <Tooltip
        title="Orders that are not unassigned or have an issue"
        placement="top"
      >
        <Grid container xs={12}>
          <Grid item container xs={6} direction="column">
            <Grid item container spacing={1}>
              <Grid item>
                <Typography variant="body1">Total Cost: </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.number}>
                  {formatCentsToDollars(props?.cost)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item>
                <Typography variant="body1">Total Revenue: </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.number}>
                  {formatCentsToDollars(props?.price)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item>
                <Typography variant="body1">Total Profit Margin: </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.number}>
                  {numberUtils.formatNumber(props?.profitMargin, '0.00')}%
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item>
                <Typography variant="body1">Total Profit: </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.number}>
                  {formatCentsToDollars(props?.profit)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={6} direction="column">
            <Grid item container spacing={1}>
              <Grid item>
                <Typography variant="body1">{'Orders < 1%: '}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.number}>
                  {props?.zeroProfitCount}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item>
                <Typography variant="body1">Orders 1-5%: </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.number}>
                  {props?.oneToFiveProfitCount}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item>
                <Typography variant="body1">Orders 5-10%: </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.number}>
                  {props?.fiveToTenProfitCount}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item>
                <Typography variant="body1">Orders 10-15%: </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.number}>
                  {props?.tenToFifteenProfitCount}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item>
                <Typography variant="body1">Orders over 15%: </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.number}>
                  {props?.overFifteenProfitCount}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Tooltip>
    </SearchViewMiniReport>
  );
}

export default CostBreakdown;
