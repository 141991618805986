import { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useSearch from '@src/Components/common/containers/SearchView/hooks/useSearch';
import { SwipeableDrawer, Grid } from '@material-ui/core';
import type { DrawerProps } from './types';
import CategoryViewDrawer from '@src/Components/Products/Categories/CategoryViewDrawer';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    width: 425,
    padding: theme.spacing(2),
  },
}));

export default function NewCategoryDrawer({
  isDrawerOpen,
  setDrawerOpen,
  submit,
}: DrawerProps) {
  const classes = useStyles();
  const { mutate } = useSearch();

  const onSubmit = async () => {
    await mutate();
    setDrawerOpen();
  };

  return (
    <>
      <SwipeableDrawer
        open={isDrawerOpen}
        onClose={() => setDrawerOpen()}
        onOpen={() => null}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
      >
        <CategoryViewDrawer close={setDrawerOpen} onSubmit={onSubmit} />
      </SwipeableDrawer>
    </>
  );
}
