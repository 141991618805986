import { useMemo } from 'react';
import useBrands from '@src/hooks/swr/useBrands';

import AppSelect from '../AppSelect';

import type { Props } from './types';

function BrandSelect(props: Props) {
  const { data: brands } = useBrands();

  const options = useMemo(() => {
    if (!brands) return [];
    return brands.map((channel) => ({
      value: channel.id,
      label: channel.brand,
    }));
  }, [brands]);

  return (
    <AppSelect
      options={options}
      label={props?.label || 'Brand'}
      {...props}
      style={{
        backgroundColor: props.backgroundColor || 'transparent',
      }}
      value={
        typeof props?.value === 'object'
          ? props.value
          : options.find(
              (option) => option[props?.valueKey ?? 'value'] === props?.value,
            ) || {}
      }
    />
  );
}

export default BrandSelect;
