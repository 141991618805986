import { makeStyles, Theme } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useEffect, useState } from 'react';

import { Props } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  primaryButton: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    fontWeight: 'bold',
    // color: `${theme.palette.primary.contrastText}`,
    '&$selected': {
      color: `${theme.palette.primary.contrastText}`,
    },

    '&:hover': {
      backgroundColor: `lightgrey !important`,
      color: '#363636 !important'
    },
    color: 'white !important'
  },
  secondaryButton: {
    // backgroundColor: `${theme.palette.secondary.main} !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
    color: '#363636 !important'
  },
  defaultButton: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: '#ffffff',

    '&:hover': {
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
  },
}));

function ToggleButtonList({
  exclusive = true,
  values: valuesProp = [],
  updateSelectedValues,
}: Props) {
  const classes = useStyles();
  const [values, setValues] = useState<string[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  useEffect(() => {
    setValues(valuesProp);
    setSelectedValues([valuesProp[0]])
  }, [valuesProp]);

  return (
    <ToggleButtonGroup
      exclusive={exclusive}
      value={selectedValues}
      onChange={(_, selectedValues) => {
        setSelectedValues([selectedValues]);
        updateSelectedValues([selectedValues]);
      }}
    >
      {values.map((value) => (
        <ToggleButton
          value={value}
          key={`toggle-button-${value}`}
          className={
            selectedValues?.includes(value)
              ? classes.primaryButton
              : classes.secondaryButton
          }
        >
          {value}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

export default ToggleButtonList;
