import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { pick } from 'lodash';
import Api from '@oneAppCore/services/Api';

import { Button, ButtonGroup, Grid } from '@material-ui/core';
import ParentOrder from './ParentOrder';
import ActionCard from './ActionCard';
import NotesCard from './NotesCard';

function EditOrderPage() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams<{ id?: string }>();

  const [isViewMode, setIdViewMode] = useState<boolean>(false);
  const [order, setOrder] = useState<any>({});

  // const updateForm = <K extends keyof Form>(key: K, value: Form[K]) => {
  //   setForm({
  //     ...form,
  //     [key]: value,
  //   });
  // };

  useEffect(() => {
    const orderId = Number(id);

    if (orderId) {
      const loadOrder = async () => {
        const orderCall = await Api.get(`/api/v1/orders/${orderId}`);
        setOrder(orderCall);
      };
      loadOrder();
    }
  }, [id]);

  return (
    <div>
      <Grid container xs={12}>
        <Grid item xs={10}>
          <ParentOrder isViewMode={isViewMode} row={order.order} />
        </Grid>
        <Grid item xs={2} justify="space-between">
          <Grid item style={{ marginBottom: 10 }}>
            <ActionCard />
          </Grid>
          <Grid item style={{ marginTop: 10 }}>
            <NotesCard />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default EditOrderPage;
