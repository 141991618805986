import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import type { Props } from './types';

function OverrideDialog({ open, onConfirm, close }: Props) {
  return (
    <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Confirm Override</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to override the inventory?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          color="secondary"
          onClick={() => onConfirm(false)}
        >
          No
        </Button>
        <Button type="submit" color="primary" onClick={() => onConfirm(true)}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OverrideDialog;
