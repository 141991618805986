import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import type { Props } from './types';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    height: '100%',
  },
}));

function SearchViewMiniReport({ children }: Props) {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={4} lg={4}>
      <Paper className={classes.root}>{children}</Paper>
    </Grid>
  );
}

export default SearchViewMiniReport;
