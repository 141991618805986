import { Grid } from '@material-ui/core';
import { issueList } from '@oneAppCore/constants/orders';
import SearchView from '@src/Components/common/containers/SearchView';
import { columns } from './constants';

function IssuesSearch() {
  const issueTypeList = issueList.map((iss) => ({
    value: iss,
    label: iss.replace(/_/g, ' '),
  }));

  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <SearchView
        url="api/v1/orders/search"
        constantFilters={{
          status: 'issue',
        }}
        filters={[
          {
            type: 'array',
            name: 'Issue Type',
            keyName: 'issueType',
            operator: 'is',
            properties: issueTypeList,
          },
          {
            type: 'date range',
            name: 'Purchased At',
            keyName: 'purchasedAt',
          },
        ]}
        columns={columns()}
        showChannelOptions
      />
    </Grid>
  );
}

export default IssuesSearch;
