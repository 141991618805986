import recoilSearch from '../../hooks/useSearchOptions';
import { useState } from 'react';
import ChannelOptions from '../../ChannelOptions';
import { useHistory } from 'react-router-dom';
import { MenuItem, Select, Button, Grid } from '@material-ui/core';

import SearchFilters from '../SearchFilters';
import DownloadButton from './DownloadButton';
import ExportButton from './ExportButton';
import BatchPrintButton from './BatchPrintButton';
import ApproveButton from './ApproveButton';
import BrandDrawer from '@src/Components/Products/Brands/BrandDrawer';

import type { Props } from './types';
import CreationDrawer from '../../CreationDrawer';
import RestockButton from './RestockButton';

const SearchBar = ({
  supportsDownload,
  showChannelOptions,
  filters,
  constantFilters,
  exportOptions,
  selectedRows,
  select,
  selectTypes,
  deselectAllRows,
  carrierData,
  selectRow,
  customComponents,
  me,
  resetPagination,
  setResetPagination,
  selectedFilterValues,
  setSelectedFilterValues,
}: Props) => {
  const [searchState, setSearchState] = recoilSearch.useState();
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const update = (limit: number) => {
    setSearchState((previous) => ({
      ...previous,
      limit,
    }));
  };

  const newUser = selectTypes?.includes('create') && (
    <Button
      variant="contained"
      color="primary"
      onClick={() => history.push('/admin/users/create')}
      size="small"
    >
      Create
    </Button>
  );

  const newCompany = selectTypes?.includes('createCompany') && (
    <Button
      variant="contained"
      color="primary"
      onClick={() => history.push('/admin/companies/create')}
      size="small"
    >
      Create
    </Button>
  );

  const newStoreChannel = selectTypes?.includes('createSChannel') && (
    <Button
      variant="contained"
      color="primary"
      onClick={() => history.push('/store-channel/create')}
      size="small"
    >
      Create
    </Button>
  );

  const newProduct = selectTypes?.includes('createProduct') && (
    <Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push('/products/create')}
        size="small"
      >
        Create
      </Button>
    </Grid>
  );

  // const dayChange = selectTypes?.includes('')

  const brandCreation = selectTypes?.includes('createBrand') && (
    <CreationDrawer Form={BrandDrawer} />
  );

  // const inventoryCreation = selectTypes?.includes('createInventory') && (
  //   <CreationDrawer Form={InventoryDrawer} />
  // );

  const download = supportsDownload && <DownloadButton />;
  // const exports = exportOptions && (
  //   <ExportButton
  //     selectedRows={selectedRows}
  //     deselectAllRows={deselectAllRows}
  //     selectRow={selectRow}
  //     exportOptions={exportOptions}
  //   />
  // );

  const exports = select && selectTypes?.includes('bulkExport') && (
    <ExportButton
      selectedRows={selectedRows}
      deselectAllRows={deselectAllRows}
      selectRow={selectRow}
      exportOptions={exportOptions}
    />
  );
  const batchPrint = select && selectTypes?.includes('batch-print') && (
    <BatchPrintButton
      carrierData={carrierData}
      selectedRows={selectedRows}
      deselectAllRows={deselectAllRows}
      selectRow={selectRow}
      me={me}
    />
  );

  const approveButton = select && selectTypes.includes('approve') && (
    <ApproveButton
      selectedRows={selectedRows}
      deselectAllRows={deselectAllRows}
    />
  );

  const restockButton = select && selectTypes.includes('restock') && (
    <RestockButton
      selectedRows={selectedRows}
      deselectAllRows={deselectAllRows}
    />
  )

  // const channelTabs = showChannelOptions && <ChannelOptions />;

  const bulkActions = select ? (
    <>
      {download}
      {/* {exports} */}
      {batchPrint}
      {approveButton}
      {restockButton}
      {/* {supportsDownload && <DownloadButton />} */}
      {exports}
      {/* {exportOptions && (
        <ExportButton
          exportOptions={exportOptions}
          selectedRows={selectedRows}
          deselectAllRows={deselectAllRows}
          selectRow={selectRow}
        />
      )} */}
    </>
  ) : (
    <></>
  );

  return (
    <Grid
      container
      spacing={3}
      style={{
        margin: 8,
        padding: 5,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Grid item xs={12} md={8} container spacing={2} alignItems="center">
        <Grid
          item
          xs={12}
          md={7}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {bulkActions}
          {!select && download}
          {newUser}
          {newStoreChannel}
          {newProduct}
          {newCompany}
          {/* {inventoryCreation} */}
          {brandCreation}
          {/* {channelTabs} */}
          {customComponents}
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        {filters && (
          <SearchFilters
            // resetPagination={resetPagination}
            setResetPagination={setResetPagination}
            filters={filters}
            constantFilters={constantFilters}
            selectedFilterValues={selectedFilterValues}
            setSelectedFilterValues={setSelectedFilterValues}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default SearchBar;
