import { useEffect, useState } from 'react';
import { Card, CardContent, Grid, makeStyles } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import dayjs from 'dayjs';
import ReportApi from '@oneAppCore/services/ReportApi';
import { Props } from '../types';
import FontAwesome from '@src/Components/common/FontAwesome';
import { EllipseLoader } from '@src/Components/common/Loaders';
import PieceRateChart from '../PieceRateChart';
import TopProducts from '../TopProductsCard';
import InventoryStats from '../InventoryStatsCard';
import TopCategories from '../TopCategories';
import ShipStationFee from '../ShipStationFee';
import formatters from '@oneAppCore/utils/formatters';
import { CANCELLED, ISSUE, UNASSIGNED } from '@oneAppCore/constants/orders';
import styles from './HeaderCards.style';
import recoilAnalyticsOptions from '../AnalyticsHooks/AnalyticsOptions';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import dateUtils from '@oneAppCore/utils/dates';

const useStyles = makeStyles(styles);

function HeaderCards({ supplierId, unitsBack, unitType }: Props) {
  const classes: any = useStyles();
  const [cardData, setCardData] = useState(null);
  const [pastCardData, setPastCardData] = useState(null);
  const [prevDays, setPrevDays] = useState(0);
  const isMobile = useMediaQuery('(max-width:900px)');

  const [productCostData, setProductCostData] = useState(null);
  const [totalSalesData, setTotalSalesData] = useState(null);
  const analyticsOptions = recoilAnalyticsOptions.useAnalyticsOptions();

  useEffect(() => {
    (async () => {
      setCardData(null);
      const { newerThan, newerThan2, daysPrev } = dateUtils.getDaysBack({
        unitsBack,
        unitType,
      });
      setPrevDays(daysPrev);

      const response = await ReportApi.get('ORDER_ITEMS', {
        supplierId,
        newerThan,
        page: 1,
        limit: 1000000,
      });

      // const respj = await ReportApi.get('ORDER_ITEMS', {
      //   supplierId,
      //   newerThan: dayjs('2024-06-12').startOf('day').toISOString(),
      //   olderThan: dayjs('2024-06-30').endOf('day').toISOString(),
      //   page: 1,
      //   limit: 1000000,
      // });
      // const respjp = await ReportApi.get('ORDER_ITEMS', {
      //   supplierId,
      //   newerThan: dayjs('2024-07-01').startOf('day').toISOString(),
      //   olderThan: dayjs('2024-07-18').endOf('day').toISOString(),
      //   page: 1,
      //   limit: 1000000,
      // });

      const pastResponse = await ReportApi.get('ORDER_ITEMS', {
        newerThan: newerThan2,
        olderThan: newerThan,
        page: 1,
        limit: 1000000,
      });
      const noCountStatuses = [CANCELLED];
      const noProfitStatuses = [ISSUE, UNASSIGNED, CANCELLED];
      const pastReport = pastResponse.rows.reduce(
        (prev, curr) => {
          const totalOrders = pastResponse.count;
          const grossSales = curr['Before Fees'];
          const totalSales = curr['After Fees'];
          const subcost = curr['Supplier Sub Total'] || 0;
          const taxcost = curr['Supplier Tax'] || 0;
          const shipcost = curr['Supplier Shipping Cost'] || 0;
          const totalCost = subcost + taxcost + shipcost;
          if (!noProfitStatuses.includes(curr['Order Status'])) {
            prev.sales = prev.sales + (totalSales || 0);
            prev.gross = prev.gross + (grossSales || 0);
            prev.cost = prev.cost + (totalCost || 0);
            prev.sc = prev.sc + (subcost || 0);
            prev.tx = prev.tx + (taxcost || 0);
            prev.sh = prev.sh + (shipcost || 0);
          }
          if (!noCountStatuses.includes(curr['Order Status'])) {
            prev.orders = totalOrders || 0;
          }
          return prev;
        },
        { sales: 0, cost: 0, orders: 0, gross: 0, sc: 0, tx: 0, sh: 0 },
      );
      const report = response.rows.reduce(
        (prev, curr) => {
          const totalOrders = response.count;
          const grossSales = curr['Before Fees'];
          const totalSales = curr['After Fees'];
          const subcost = curr['Supplier Sub Total'] || 0;
          const taxcost = curr['Supplier Tax'] || 0;
          const shipcost = curr['Supplier Shipping Cost'] || 0;
          const totalCost = subcost + taxcost + shipcost;
          if (!noProfitStatuses.includes(curr['Order Status'])) {
            prev.sales = prev.sales + (totalSales || 0);
            prev.gross = prev.gross + (grossSales || 0);
            prev.cost = prev.cost + (totalCost || 0);
            prev.sc = prev.sc + (subcost || 0);
            prev.tx = prev.tx + (taxcost || 0);
            prev.sh = prev.sh + (shipcost || 0);
          }
          if (!noCountStatuses.includes(curr['Order Status'])) {
            prev.orders = totalOrders || 0;
          }
          return prev;
        },
        { sales: 0, cost: 0, orders: 0, gross: 0, sc: 0, tx: 0, sh: 0 },
      );

      /* const repj = respj.rows.reduce(
        (prev, curr) => {
          const totalOrders = response.count;
          const grossSales = curr['Before Fees'];
          const totalSales = curr['After Fees'];
          const subcost = curr['Supplier Sub Total'] || 0;
          const taxcost = curr['Supplier Tax'] || 0;
          const shipcost = curr['Supplier Shipping Cost'] || 0;
          const totalCost = subcost + taxcost + shipcost;
          if (!noProfitStatuses.includes(curr['Order Status'])) {
            prev.sales = prev.sales + (totalSales || 0);
            prev.gross = prev.gross + (grossSales || 0);
            prev.cost = prev.cost + (totalCost || 0);
            prev.sc = prev.sc + (subcost || 0);
            prev.tx = prev.tx + (taxcost || 0);
            prev.sh = prev.sh + (shipcost || 0);
          }
          if (!noCountStatuses.includes(curr['Order Status'])) {
            prev.orders = totalOrders || 0;
          }
          return prev;
        },
        { sales: 0, cost: 0, orders: 0, gross: 0, sc: 0, tx: 0, sh: 0 },
      ); */
      /* const repjp = respjp.rows.reduce(
        (prev, curr) => {
          const totalOrders = response.count;
          const grossSales = curr['Before Fees'];
          const totalSales = curr['After Fees'];
          const subcost = curr['Supplier Sub Total'] || 0;
          const taxcost = curr['Supplier Tax'] || 0;
          const shipcost = curr['Supplier Shipping Cost'] || 0;
          const totalCost = subcost + taxcost + shipcost;
          if (!noProfitStatuses.includes(curr['Order Status'])) {
            prev.sales = prev.sales + (totalSales || 0);
            prev.gross = prev.gross + (grossSales || 0);
            prev.cost = prev.cost + (totalCost || 0);
            prev.sc = prev.sc + (subcost || 0);
            prev.tx = prev.tx + (taxcost || 0);
            prev.sh = prev.sh + (shipcost || 0);
          }
          if (!noCountStatuses.includes(curr['Order Status'])) {
            prev.orders = totalOrders || 0;
          }
          return prev;
        },
        { sales: 0, cost: 0, orders: 0, gross: 0, sc: 0, tx: 0, sh: 0 },
      ); */

      // console.log(repj);
      // console.log(repjp);

      setCardData(report);
      setPastCardData(pastReport);
      setProductCostData(response.rows);
      setTotalSalesData(response.rows);
    })();
  }, [unitsBack, supplierId]);

  let datesObject = {};

  let currentDate = dayjs().subtract(unitsBack, 'day');

  [...Array(unitsBack)].forEach((item) => {
    const date = dayjs(currentDate).format('MM/DD/YYYY');
    datesObject[date] = 0;
    currentDate = dayjs(currentDate).add(1, 'day');
  });

  const productDataSet = (productCostData || []).reduce((prev, curr) => {
    const cost =
      (curr['Supplier Sub Total'] || 0) +
      (curr['Supplier Tax'] || 0) +
      (curr['Supplier Shipping Cost'] || 0);
    const date = dayjs(curr['Purchase Date'], 'MM-DD-YYYY HH:mm:ss').format(
      'MM/DD/YYYY',
    );
    if (prev[date] || prev[date] === 0) {
      prev[date] = prev[date] + cost;
    }
    return prev;
  }, datesObject);

  const salesDataSet = (totalSalesData || []).reduce((prev, curr) => {
    const sales = curr['After Fees'];
    const date = dayjs(curr['Purchase Date'], 'MM-DD-YYYY HH:mm:ss').format(
      'MM/DD/YYYY',
    );
    if (prev[date] || prev[date] === 0) {
      prev[date] = prev[date] + sales;
    }
    return prev;
  }, datesObject);

  let costData = {
    labels: Object.keys(productDataSet),
    datasets: [
      {
        label: 'Total Cost',
        data: Object.values(productDataSet),
        fill: false,
        borderColor: 'white',
      },
    ],
  };

  let salesData = {
    labels: Object.keys(salesDataSet),
    datasets: [
      {
        label: 'Total Sales',
        data: Object.values(salesDataSet),
        fill: false,
        borderColor: 'white',
      },
    ],
  };

  const getCurrency = (number, decimal) => {
    return Intl.NumberFormat('en-US', {
      useGrouping: true,
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: decimal,
      minimumFractionDigits: decimal,
    }).format(number);
  };

  return (
    <>
      <Grid className={classes.container}>
        <Grid>
          <Card>
            <CardContent
              className={formatters.classNames([
                classes.totalSalesCard,
                classes.headerCard,
                classes.rowOneHgt,
              ])}
            >
              <Grid direction="row" container className={classes.jstSpcBtw}>
                <Grid item xs={2}>
                  <Grid className={classes.headerIcon}>
                    <FontAwesome
                      name="dollar-sign"
                      type="fas"
                      form="fa"
                    ></FontAwesome>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  {cardData ? (
                    <>
                      <p className={classes.cardFont}>
                        <span>Revenue</span>
                        <br />
                        {isMobile && cardData.sales > 1000
                          ? `$${(Math.round(cardData.sales) / 1000).toFixed(
                              2,
                            )}K`
                          : getCurrency(cardData.sales, 2)}
                      </p>
                    </>
                  ) : (
                    <EllipseLoader style={{ display: 'flex' }} />
                  )}
                </Grid>
                <Grid
                  direction="row"
                  container
                  item
                  xs={6}
                  className={classes.jstSpcBtw}
                  style={{ position: 'relative' }}
                >
                  <div
                    style={{
                      width: '90%',
                      height: '115%',
                      position: 'absolute',
                      right: 0,
                      bottom: '10%',
                      zIndex: 50,
                    }}
                  >
                    <Line
                      data={salesData}
                      options={{
                        elements: {
                          point: {
                            borderWidth: 0,
                            radius: 10,
                            backgroundColor: 'rgba(0,0,0,0)',
                          },
                          line: {
                            tension: 0.5,
                          },
                        },
                        scales: {
                          x: {
                            display: false,
                            grid: {},
                          },

                          y: {
                            display: false,
                            grid: {},
                          },
                        },
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              {cardData ? (
                <Grid container>
                  <Grid item xs={4}>
                    <p
                      className={formatters.classNames([
                        classes.cardFont,
                        'mini',
                      ])}
                    >
                      <span>Gross Sales</span>
                      <br />
                      {isMobile && cardData.gross > 1000
                        ? `$${(Math.round(cardData.gross) / 10000).toFixed(2)}K`
                        : getCurrency(cardData.gross, 2)}
                    </p>
                  </Grid>
                  <Grid item xs={4}>
                    <p
                      className={formatters.classNames([
                        classes.cardFont,
                        'mini',
                      ])}
                    >
                      <span>Est. Fees</span>
                      <br />
                      {isMobile && cardData.gross - cardData.sales > 1000
                        ? `$${(
                            Math.round(cardData.gross - cardData.sales) / 10000
                          ).toFixed(2)}K`
                        : getCurrency(cardData.gross - cardData.sales, 2)}
                    </p>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid>
          <Card>
            <CardContent
              className={formatters.classNames([
                classes.totalCostCard,
                classes.headerCard,
                classes.rowOneHgt,
              ])}
            >
              <Grid container direction="row" className={classes.jstSpcBtw}>
                <Grid container item xs={2}>
                  <Grid
                    className={classes.headerIcon}
                    style={{ backgroundColor: 'rgb(21, 101, 192)' }}
                  >
                    <FontAwesome
                      name="dollar-sign"
                      type="fas"
                      form="fa"
                    ></FontAwesome>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  {cardData ? (
                    <>
                      <p className={classes.cardFont}>
                        <span style={{ color: 'lightblue' }}>Total Cost</span>
                        <br />
                        {isMobile && cardData.cost > 1000
                          ? `$${(Math.round(cardData.cost) / 1000).toFixed(2)}K`
                          : getCurrency(cardData.cost, 2)}
                      </p>
                    </>
                  ) : (
                    <EllipseLoader style={{ display: 'flex' }} />
                  )}
                </Grid>
                <Grid
                  direction="row"
                  container
                  item
                  xs={6}
                  className={classes.jstSpcBtw}
                  style={{ position: 'relative' }}
                >
                  <div
                    style={{
                      width: '90%',
                      height: '115%',
                      position: 'absolute',
                      right: 0,
                      bottom: '10%',
                      zIndex: 50,
                    }}
                  >
                    <Line
                      data={costData}
                      options={{
                        elements: {
                          point: {
                            borderWidth: 0,
                            radius: 10,
                            backgroundColor: 'rgba(0,0,0,0)',
                          },
                          line: {
                            tension: 0.5,
                          },
                        },
                        scales: {
                          x: {
                            display: false,
                            grid: {},
                          },

                          y: {
                            display: false,
                            grid: {},
                          },
                        },
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                {cardData ? (
                  <>
                    <Grid item xs={4}>
                      <p
                        className={formatters.classNames([
                          classes.cardFont,
                          'mini',
                        ])}
                      >
                        <span style={{ color: 'lightblue' }}>
                          Subtotal Costs
                        </span>
                        <br />
                        {isMobile && cardData?.sc > 1000
                          ? `$${(Math.round(cardData?.sc) / 10000).toFixed(2)}K`
                          : getCurrency(cardData?.sc, 2)}
                      </p>
                    </Grid>
                    <Grid item xs={4}>
                      <p
                        className={formatters.classNames([
                          classes.cardFont,
                          'mini',
                        ])}
                      >
                        <span style={{ color: 'lightblue' }}>
                          Shippping Cost
                        </span>
                        <br />
                        {isMobile && cardData?.sh > 1000
                          ? `$${(Math.round(cardData?.sh) / 10000).toFixed(2)}K`
                          : getCurrency(cardData?.sh, 2)}
                      </p>
                    </Grid>
                    <Grid item xs={4}>
                      <p
                        className={formatters.classNames([
                          classes.cardFont,
                          'mini',
                        ])}
                      >
                        <span style={{ color: 'lightblue' }}>Taxes</span>
                        <br />
                        {isMobile && cardData?.tx > 1000
                          ? `$${(Math.round(cardData?.tx) / 10000).toFixed(2)}K`
                          : getCurrency(cardData?.tx, 2)}
                      </p>
                    </Grid>
                  </>
                ) : (
                  ''
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid>
          <Grid className={classes.profitCards}>
            <Grid>
              <Card className={[classes.marginBottom].join(' ')}>
                <CardContent
                  className={formatters.classNames([
                    classes.profitCard,
                    classes.headerCard,
                    'mini',
                    classes.rowHalfHgt,
                  ])}
                >
                  <Grid
                    container
                    xs={11}
                    direction="row"
                    className={formatters.classNames([classes.cardFont])}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      className={formatters.classNames([
                        classes.headerIcon,
                        'green',
                      ])}
                    >
                      <FontAwesome
                        name="money-bill"
                        type="fas"
                        form="fa"
                      ></FontAwesome>
                    </Grid>
                    <Grid>
                      <Grid item>
                        {cardData ? (
                          <p className={classes.cardFont}>
                            {isMobile && cardData.sales - cardData.cost > 1000
                              ? `${(
                                  Math.round(cardData.sales - cardData.cost) /
                                  1000
                                ).toFixed(2)}K`
                              : getCurrency(cardData.sales - cardData.cost, 2)}
                          </p>
                        ) : (
                          <EllipseLoader dark style={{ display: 'flex' }} />
                        )}
                      </Grid>
                      <Grid>
                        <p
                          className={formatters.classNames([
                            classes.cardFont,
                            'profit',
                          ])}
                        >
                          Total Profit
                        </p>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Grid item>
                        {pastCardData ? (
                          <p className={classes.cardFont}>
                            {isMobile &&
                            pastCardData.sales - pastCardData.cost > 1000
                              ? `${(
                                  Math.round(
                                    pastCardData.sales - pastCardData.cost,
                                  ) / 1000
                                ).toFixed(2)}K`
                              : getCurrency(
                                  pastCardData.sales - pastCardData.cost,
                                  2,
                                )}
                          </p>
                        ) : (
                          <EllipseLoader dark style={{ display: 'flex' }} />
                        )}
                        <Grid>
                          <p
                            className={formatters.classNames([
                              classes.cardFont,
                              'profit',
                            ])}
                          >
                            {/* {prevDays > 1
                              ? `Last ${prevDays} days`
                              : 'Last day'} */}
                              Prior Period
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Grid item>
                        {(pastCardData?.sales - pastCardData?.cost) / 2 >
                        (cardData?.sales - cardData?.cost) / 2 ? (
                          <FontAwesome
                            form="fas"
                            type="fa"
                            name="arrow-circle-down"
                            style={{ color: '#B61F1F' }}
                          />
                        ) : (
                          <FontAwesome
                            form="fas"
                            type="fa"
                            name="arrow-circle-up"
                            style={{ color: '#10960E' }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card>
                <CardContent
                  className={formatters.classNames([
                    classes.orderCard,
                    classes.headerCard,
                    'mini',
                    classes.rowHalfHgt,
                  ])}
                >
                  <Grid
                    container
                    xs={11}
                    direction="row"
                    className={formatters.classNames([classes.cardFont])}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      className={formatters.classNames([
                        classes.headerIcon,
                        'gold',
                      ])}
                    >
                      <FontAwesome
                        name="shopping-cart"
                        type="fas"
                        form="fa"
                      ></FontAwesome>
                    </Grid>
                    <Grid>
                      <Grid item>
                        {cardData ? (
                          <p className={classes.cardFont}>
                            {isMobile && cardData.orders > 1000
                              ? `${(Math.round(cardData.orders) / 1000).toFixed(
                                  2,
                                )}K`
                              : cardData.orders}
                          </p>
                        ) : (
                          <EllipseLoader dark style={{ display: 'flex' }} />
                        )}
                      </Grid>
                      <Grid>
                        <p
                          className={formatters.classNames([
                            classes.cardFont,
                            'profit',
                          ])}
                        >
                          Orders
                        </p>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Grid item>
                        {pastCardData ? (
                          <p className={classes.cardFont}>
                            {isMobile && pastCardData.orders > 1000
                              ? `${(
                                  Math.round(pastCardData.orders) / 1000
                                ).toFixed(2)}K`
                              : pastCardData.orders}
                          </p>
                        ) : (
                          <EllipseLoader dark style={{ display: 'flex' }} />
                        )}
                      </Grid>

                      <Grid>
                        <p
                          className={formatters.classNames([
                            classes.cardFont,
                            'profit',
                          ])}
                        >
                          {/* {prevDays > 1 ? `Last ${prevDays} days` : 'Last day'} */}
                          Prior Period
                        </p>
                      </Grid>
                    </Grid>

                    <Grid>
                      <Grid item>
                        {pastCardData?.orders > cardData?.orders ? (
                          <FontAwesome
                            form="fas"
                            type="fa"
                            name="arrow-circle-down"
                            style={{ color: '#B61F1F' }}
                          />
                        ) : pastCardData?.orders < cardData?.orders ? (
                          <FontAwesome
                            form="fas"
                            type="fa"
                            name="arrow-circle-up"
                            style={{ color: '#10960E' }}
                          />
                        ) : (
                          <FontAwesome
                            form="fas"
                            type="fa"
                            name="minus-circle"
                            style={{ color: '#f5811b' }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.container}>
        <Grid>
          <Card className={classes.marginTop}>
            <CardContent
              className={formatters.classNames([
                classes.inventoryCard,
                classes.bottomCard,
                classes.headerCard,
                !isMobile && classes.rowOneHgt,
              ])}
            >
              <Grid direction="row" className={classes.jstSpcBtw}>
                <Grid direction="column">
                  <InventoryStats
                    supplierId={
                      analyticsOptions.supplierId
                    } /* unitsBack={unitsBack} */
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card className={classes.marginTop}>
            <CardContent
              className={formatters.classNames([
                classes.categoryCard,
                classes.onlyScrollY,
                classes.bottomCard,
                classes.headerCard,
                classes.rowTwoHgt,
              ])}
            >
              <Grid direction="row" className={classes.jstSpcBtw}>
                <Grid direction="column">
                  <TopCategories unitsBack={unitsBack} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid>
          <Card className={classes.marginTop}>
            <CardContent
              className={formatters.classNames([
                classes.rateCard,
                classes.bottomCard,
                classes.headerCard,
                classes.rowThreeHgt,
              ])}
            >
              <PieceRateChart unitsBack={unitsBack} />
            </CardContent>
          </Card>
        </Grid>
        <Grid>
          <Card className={classes.marginTop} style={{ maxHeight: 150 }}>
            <CardContent
              className={formatters.classNames([
                classes.bottomCard,
                classes.rowOneHgt,
              ])}
            >
              <Grid direction="row" className={classes.jstSpcBtw}>
                <Grid direction="column">
                  <ShipStationFee unitsBack={unitsBack} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card
            className={classes.marginTop}
            style={{ maxHeight: 390, overflow: 'scroll' }}
          >
            <CardContent
              className={formatters.classNames([
                classes.bottomCard,
                classes.headerCard,
                classes.rowThreeHgt,
              ])}
            >
              <TopProducts />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default HeaderCards;
