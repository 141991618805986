import React from 'react';
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import {
  Typography,
  List,
  Drawer,
  Hidden,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from '@material-ui/core';
import clsx from 'clsx';

import { useLocation, useHistory } from 'react-router-dom';

import mainRoutes from '@src/routes/mainRoutes';
import SubCategoryRoute from './SubCategoryRoute';
import { FontAwesome } from '@src/Components/common';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';

const drawerWidth = 300;
const miniWidth = 50;

interface Props {
  openDrawer: boolean;
  close: () => void;
  mini: boolean;
  normalToggle: () => void;
}

interface StyleProps {
  mini: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      transition: 'width 0.33s ease',
      [theme.breakpoints.up('md')]: {
        width: ({ mini }: StyleProps) => (mini ? miniWidth : drawerWidth),
        flexShrink: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    drawerPaper: {
      transition: 'width 0.33s ease',
      width: drawerWidth,
      zIndex: 99,
    },
    smallPaper: {
      transition: 'width 0.33s ease',
      width: miniWidth,
      cursor: 'pointer',
    },
    title: {
      paddingTop: theme.spacing(2),
      paddingLeft: ({ mini }: StyleProps) =>
        mini ? theme.spacing(2) : theme.spacing(4),
      transition: 'padding 0.33s ease',
    },
    padded: {
      transition: 'padding 0.33s ease',
      paddingLeft: theme.spacing(2.5),
      // paddingTop: ({ mini }: StyleProps) => (mini ? theme.spacing(1) : 0),
    },
    miniListItem: {
      display: 'flex',
      justifyContent: 'center',
    },
    listItem: {
      cursor: 'pointer',
    },
    logo: {
      height: 'auto',
      width: 150,
      // width: 150,
    },
  }),
);

export default function ResponsiveDrawer({
  openDrawer,
  close,
  mini,
  normalToggle,
}: Props) {
  const classes = useStyles({ mini });
  const theme = useTheme();

  const history = useHistory();
  const location = useLocation();

  const drawer = (
    <div onMouseEnter={() => mini && normalToggle()}>
      {mini ? (
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ marginTop: 10 }}
          xs={12}
        >
          <Grid item>
            <img
              style={{ width: '40px', marginTop: 20 }}
              src={`https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/oneapp-logo1719426423419.PNG`}
              // src="/public/logo.png"
              alt="logo"
              className={classes.logo}
              onClick={() => {
                history.push('/');
              }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = '/public/logo.png';
                // currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/oa-logo.png`;
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ marginTop: 10 }}
        >
          <Grid item>
            <img
              src={`https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/oneapp-logo1719426423419.PNG`}
              // src="/public/logo.png"
              alt="logo"
              className={classes.logo}
              onClick={() => {
                history.push('/');
              }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = '/public/logo.png';
                // currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/oa-logo.png`;
              }}
            />
          </Grid>
        </Grid>
      )}
      <List component="nav" className={classes.padded}>
        <ListItem
          className={clsx(classes.listItem, {
            [classes.miniListItem]: mini,
          })}
        ></ListItem>
        {mainRoutes.map((route) => {
          let returnedRoute = (
            <ListItem
              key={route.key}
              selected={location.pathname === route.path && !mini}
              className={clsx(classes.listItem, {
                [classes.miniListItem]: mini,
              })}
              onClick={route.path ? () => history.push(route.path) : null}
            >
              <ListItemIcon>{route.icon}</ListItemIcon>
              {!mini ? <ListItemText primary={route.title} /> : <></>}
            </ListItem>
          );
          if (!route.authorized) {
            returnedRoute = <React.Fragment key={route.key} />;
          } else if (route.subRoutes && !mini) {
            returnedRoute = <SubCategoryRoute route={route} key={route.key} />;
          }
          return returnedRoute;
        })}
      </List>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={openDrawer}
          onClose={close}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: clsx({
              [classes.drawerPaper]: !mini,
              [classes.smallPaper]: mini,
            }),
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}
