import { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, IconButton, Tooltip, Modal, Button } from '@material-ui/core';
import FontAwesome from '@src/Components/common/FontAwesome';
import type { Props } from './types';
import { useSnackbar } from 'notistack';
import OrderIssueApi from '@oneAppCore/services/OrderIssueApi';
import OrdersApi from '@oneAppCore/services/OrdersApi';
import LocalShippingTwoToneIcon from '@material-ui/icons/LocalShippingTwoTone';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  },
}));

function DropshipButton({ row }: Props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setOrderInbox = async () => {
    try {
      const issuePayload = {
        orderId: row.id,
        issueType: 'OTHER',
        previousOrderStatus: row.orderStatus,
      };
      const orderUpdatePayload = { orderStatus: 'ISSUE' };
      await OrderIssueApi.post(issuePayload);
      await OrdersApi.update(row.id, orderUpdatePayload);
      enqueueSnackbar('Moved Order to Drop Ship!', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar('Error Moving Order', {
        variant: 'error',
      });
    } finally {
      {
        handleClose();
      }
    }
  };

  return (
    <>
      <Grid item container style={{ justifyContent: 'center' }}>
        <Tooltip title={'Drop Ship'}>
          <span>
            <IconButton style={{ fontSize: '14px' }} onClick={handleOpen}>
              {/* <FontAwesome name="parachute-box" type="fa" form="fa" /> */}
              <LocalShippingTwoToneIcon color="secondary" fontSize="default" />
            </IconButton>
          </span>
        </Tooltip>
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            style={{
              width: 500,
              backgroundColor: 'white',
              justifyContent: 'center',
              borderRadius: 10,
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{
                justifyContent: 'center',
                width: '100%',
                borderRadius: '10px 10px 0px 0px',
              }}
            >
              <h2>Are you sure?</h2>
            </Grid>
            <Grid item container xs={10} style={{ justifyContent: 'center' }}>
              <p
                style={{
                  fontSize: '20px',
                  marginTop: 15,
                  marginBottom: 25,
                  textAlign: 'center',
                }}
              >
                This will move the order to the dropship list.
              </p>
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{ padding: 10, justifyContent: 'flex-end' }}
            >
              <Grid
                item
                container
                xs={2}
                style={{ padding: 10, justifyContent: 'center' }}
              >
                <Button
                  variant="outlined"
                  style={{ border: '1px solid red', color: 'red' }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid
                item
                container
                xs={2}
                style={{ padding: 10, justifyContent: 'flex-end' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setOrderInbox();
                  }}
                >
                  Move
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    </>
  );
}

export default DropshipButton;
