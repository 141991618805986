import { useState, useEffect } from 'react';
import {
  Grid,
  IconButton,
  Tooltip,
  Modal,
  Button,
  Select,
  MenuItem,
  Typography,
  TextField,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Divider,
  Checkbox,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import ShippingPackageApi from '@oneAppCore/services/ShippingPackageApi';
import { getCheapestSupplierDetails, getShipRates } from '@src/utils/getShipRates';
import ShipStationApi from '@oneAppCore/services/ShipStationApi';
import { addressStringFormatter } from '@src/utils/addressStringLimiter';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
    backgroundColor: 'lightGray',
  },
}));

interface Form {
  name?: string;
  weight?: number;
  length?: number;
  width?: number;
  height?: number;
  type?: string;
}

function PackageButton({
  row,
  packages,
  setPackages,
  shipRates,
  setShipRates,
}) {
  const [open, setOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(row?.orderItems?.length == 1 && row?.orderItems?.[0]?.packageId ? row?.orderItems?.[0]?.packageId : 0);
  const [form, setForm] = useState({
    name: '',
    pounds: 0,
    ounces: 0,
    length: 0,
    width: 0,
    height: 0,
    type: 'box',
    saveForFuture: false,
  });

  const ITEM_HEIGHT = 80;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const updateForm = (key, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      [key]: value,
    }));
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateForm('type', event.target.value);
  };
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setForm({
      name: '',
      pounds: 0,
      ounces: 0,
      length: 0,
      width: 0,
      height: 0,
      type: '',
      saveForFuture: false,
    });
    setOpen(false);
  };

  const handleSubmit = async () => {
    let payload = {
      name: form.name ? form.name : null,
      length: form.length,
      height: form.height,
      width: form.width,
      weight: (Number(form.pounds) + (Number(form.ounces) / 16)).toFixed(2),
      type: form.type,
    };
    try {
      const data: any = await ShippingPackageApi.post(payload);
      if (data.packageId) {
        setSelectedPackage(data.packageId);
        if (data.message !== 'package already exists') {
          setPackages(prevPackages => {
            const tempPackages = [...prevPackages];
            tempPackages.push({
              ...payload,
              id: data.packageId,
            });
            return tempPackages;
          });
        };
        // setSelectedPackage(data.packageId);
        await updatePackageId(data.packageId);
        enqueueSnackbar('Saved Package!', { variant: 'success' });
        handleClose();
        await updateShipRates(form.length, form.width, form.height);
      };
      if (!data.packageId && data.message === `package already exists`) {
        enqueueSnackbar(`Package ${payload.name} already exists`, { variant: 'error' });
      };
    } catch (error) {
      console.error('Error saving package:', error);
      enqueueSnackbar('Error saving package!', { variant: 'error' });
    };
  };

  const handlePackageChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value != -1 && row.orderItems.length == 1) {
      await updatePackageId(event.target.value);
    };
    if (event.target.value !== -1) {
      setSelectedPackage(event.target.value as string);
      if (event.target.value == 0) {
        await updateShipRates();
      } else {
        const packageInfo = packages.find((p) => p.id == event.target.value);
        await updateShipRates(packageInfo.length, packageInfo.width, packageInfo.height);
      };
    };
  };

  const updatePackageId = async (packageId) => {
    let payload = {
      mpnId: row.orderItems[0].mpnId,
      packageId: packageId == 0 ? null : Number(packageId),
    };
    try {
      await ShippingPackageApi.updatePackageId(payload);
    } catch (e) {
      enqueueSnackbar('Error updating package id!', { variant: 'error' });
    };
  };

  const updateShipRates = async (length = 1, width = 4, height = 8) => {
    setShipRates(prevRates => ({ ...prevRates, [row.id]: { ...shipRates?.[row.id], loading: true } }));
    const getShipStationData = await ShipStationApi.getIntegration();
    const addressArray = addressStringFormatter(
      row.addressLine1,
      row.addressLine2,
      row.addressLine3,
    );
    let weight = 0;
    let variationError = {
      error: false,
      message: '',
    };
    for (const item of row?.orderItems || []) {
      if (!item?.variationId) {
        variationError = { error: true, message: `Order with ID of ${row.id} is missing a variation Id. Cannot pull weight. This probably means there is a data inconsistency` };
        break;
        // throw `Order item with ID of ${item.id} is missing a variation Id. Cannot pull weight. This probably means there is a data inconsistency.`;
      };
      try {
        // On the assumption that weight is stored in pounds
        weight += item.weight
          ? parseFloat(item.weight) * item.quantity * 16
          : 0;

      } catch (err) {
        const error = new Error(err);
        variationError = {
          error: true,
          message: `${error.message}`
        }
      }
    };

    if (variationError.error) {
      setShipRates(prevRates => ({
        ...prevRates, [row.id]: {
          error: true,
          message: variationError.message,
        }
      }));
      return;
    };

    const orderCosts = {
      subTotal: row?.orderItems?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue?.supplierSubTotal;
      }, 0),
      itemPrice: row?.orderItems?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue?.itemPrice;
      }, 0),
    };
    const shipForm = {
      shipFrom: getShipStationData?.shipStationData.integrationData.shipFrom,
      shipTo: {
        name: row.shippingName,
        company: row.orderNumber.includes('# ')
          ? row.orderNumber.split('# ')[1]
          : row.orderNumber,
        street1: addressArray[0],
        street2: addressArray[1],
        street3: addressArray[2],
        city: row.city,
        state: row.state,
        postalCode: row.zipCode,
        country: row.country === 'USA' ? 'US' : row.country,
        phone: '',
        residential: true,
      },
      weight: weight,
      dimensions: {
        units: 'inches',
        length: length,
        width: width,
        height: height,
      },
    };
    try {
      const allCarriersResponse = await getShipRates(shipForm);
      const carrierData = getCheapestSupplierDetails(allCarriersResponse, orderCosts);
      if (carrierData?.badGateWayError) {
        enqueueSnackbar('Error updating ship rates', { variant: 'error' });
        setShipRates(prevRates => ({
          ...prevRates, [row.id]: {
            error: true,
            message: 'Error updating ship rates',
          }
        }));
        return;
      };
      const finalRatesObj = {
        loading: false,
        error: false,
        message: 'success',
        ['carrierCode']: carrierData?.cheapestCarrier,
        ['carrierOptions']: carrierData?.defaultCarrierOptions,
        ['serviceOptions']: carrierData?.serviceRateArray,
        ['serviceCode']: carrierData?.serviceRateArray?.[carrierData?.lowestIndex]?.serviceCode,
        ['selectedServiceName']: carrierData?.serviceRateArray?.[carrierData?.lowestIndex]?.serviceName,
        ['packageCode']: carrierData?.packageCode,
        ['allRates']: carrierData?.allRates,
        profit: carrierData?.profit,
        selectedShippingCost: carrierData?.selectedService?.shipmentCost + carrierData?.selectedService?.otherCost,
        weight: weight.toFixed(2),
        length: length,
        width: width,
        height: height,
      };
      setShipRates(prevRates => ({ ...prevRates, [row.id]: finalRatesObj }));
    } catch (e) {
      enqueueSnackbar('Error updating ship rates', { variant: 'error' });
      setShipRates(prevRates => ({
        ...prevRates, [row.id]: {
          error: true,
          message: e,
        }
      }));
    };
  };

  return (
    <>
      {/* <form onSubmit={handleSubmit}> */}
      <Grid item container style={{ justifyContent: 'center' }}>
        {/* <Select defaultValue={row.row.orderItems?.[0]?.name}> */}
        <Select value={selectedPackage} onChange={handlePackageChange} disabled={shipRates?.[row.id]?.loading} MenuProps={MenuProps}>
          <MenuItem key={0} value={0}>
            <Grid container xs={12} style={{ flexDirection: 'column' }}>
              <Typography>Default</Typography>
              <Typography
                style={{ fontWeight: 'lighter', fontSize: '0.75rem' }}
              >
                1 x 4 x 8 In
              </Typography>
            </Grid>
          </MenuItem>
          {packages.length > 0 && packages?.filter((item) => item.id === selectedPackage && !item.name).map((item, i) => {
            // let custom = row?.orderItems?.length == 1 && row?.orderItems?.[0]?.packageId == item?.id && !item.name;
            return <MenuItem key={i} value={item?.id}>
              <Grid container xs={12} style={{ flexDirection: 'column' }}>
                <Typography>Custom</Typography>
                <Typography
                  style={{ fontWeight: 'lighter', fontSize: '0.75rem' }}
                >
                  {item.length} x {item.width} x {item.height}
                </Typography>
              </Grid>
            </MenuItem>
          })}
          {packages.length > 0 && packages?.filter((item) => (item.name)).map((item, i) => {
            // let custom = row?.orderItems?.length == 1 && row?.orderItems?.[0]?.packageId == item?.id && !item.name;
            return <MenuItem key={i} value={item?.id}>
              <Grid container xs={12} style={{ flexDirection: 'column' }}>
                <Typography>{item?.name}</Typography>
                <Typography
                  style={{ fontWeight: 'lighter', fontSize: '0.75rem' }}
                >
                  {item.length} x {item.width} x {item.height}
                </Typography>
              </Grid>
            </MenuItem>
          })}

          {/* {<MenuItem key={0} value={0}>1 x 4 x 8 In</MenuItem>} */}
          <MenuItem key={-1} value={-1} onClick={() => handleOpen()}>Add New Package</MenuItem>
        </Select>
      </Grid>
      <Grid container xs={12} style={{ justifyContent: 'center' }}>
        <Modal className={classes.modal} open={open} onClose={handleClose}>
          <Grid
            container
            style={{
              width: 500,
              backgroundColor: 'white',
              borderRadius: 10,
              justifyContent: 'center',
              padding: 15,
            }}
          >
            <Grid item style={{ marginBottom: 15 }}>
              <Typography variant="h6">Add Package</Typography>
            </Grid>
            <Grid
              item
              container
              xs={11}
              // style={{ border: '1px solid light-grey' }}
            >
              <Grid item>
                <Typography style={{ color: 'gray' }}>Package Type</Typography>
                <FormControl>
                  {/* // default to box for now. make it dynamic when we have package option sorted on ShipStation. */}
                  <RadioGroup value={'box'} onChange={handleTypeChange}>
                    <FormControlLabel
                      style={{ color: 'gray' }}
                      value="box"
                      control={<Radio size="small" />}
                      label="Box"
                    />
                    <FormControlLabel
                      style={{ color: 'gray' }}
                      value="envelope"
                      control={<Radio size="small" />}
                      label="Envelope"
                      disabled
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid
                item
                container
                xs={12}
                style={{ justifyContent: 'space-between', marginTop: 20 }}
              >
                <Grid item xs={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type='number'
                    label="Length(in)"
                    variant="outlined"
                    required
                    onChange={(e) => updateForm('length', e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type='number'
                    label="Width(in)"
                    variant="outlined"
                    required
                    onChange={(e) => updateForm('width', e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type='number'
                    label="Height(in)"
                    variant="outlined"
                    required
                    onChange={(e) => updateForm('height', e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                style={{ marginTop: 20, justifyContent: 'space-between' }}
              >
                <Grid item xs={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type='number'
                    label="lbs"
                    variant="outlined"
                    required
                    onChange={(e) => updateForm('pounds', e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type='number'
                    label="oz"
                    variant="outlined"
                    required
                    onChange={(e) => updateForm('ounces', e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>

              <Grid item container xs={12} className={classes.divider}>
                <Divider />
              </Grid>
              <Grid item container>
                <FormControlLabel
                  style={{ color: 'gray' }}
                  control={<Checkbox
                    name="checkedA"
                    defaultChecked={false}
                    onChange={(event) => updateForm('saveForFuture', event.target.checked)}
                  />}
                  label="Add to package options"
                />
              </Grid>
              {form.saveForFuture && <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
                <TextField
                  onChange={(e) => updateForm('name', e.target.value)}
                  variant="outlined"
                  label="Package Name"
                  style={{ minWidth: '100%', maxHeight: '30px' }}
                />
              </Grid>}
              <Grid item container xs={12} className={classes.divider}>
                <Divider />
              </Grid>
              <Grid container xs={12} style={{ justifyContent: 'flex-end' }}>
                <Grid item xs={2}>
                  <Button
                    variant="outlined"
                    style={{ border: '1px solid red', color: 'red' }}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid
                  item
                  container
                  xs={4}
                  style={{ justifyContent: 'flex-end' }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    // type="submit"
                    disabled={!form.length || !form.width || !form.height || form.length <= 0 || form.width <= 0 || form.height <= 0}
                    onClick={handleSubmit}
                  >
                    Add Package
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
      {/* </form> */}
    </>
  );
}

export default PackageButton;
