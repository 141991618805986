import { Grid } from '@material-ui/core';

import { columns } from './constants';

import SearchView from '@src/Components/common/containers/SearchView';

import SupplierDrawer from './SupplierDrawer';

const locatorProperties = [
  { label: 'Warehouse', value: 'internalSupplier' },
  { label: 'Dropship', value: 'dropshipSupplier' },
  // { label: 'Warehouse - 3ps', value: 'internalStock' },
  // { label: 'Static Quantity', value: 'hasStaticQuantity' },
  // { label: 'Out of Stock', value: 'outOfStock' },
];

function SupplierSearch() {
  return (
    <Grid item container justify="center" alignItems="center" spacing={1}>
      <SearchView
        url="/api/v1/suppliers/search"
        columns={columns}
        CreateFormComponent={SupplierDrawer}
        filters={[
          {
            type: 'selector',
            name: 'Internal Supplier',
            keyName: 'searchFilter',
            properties: locatorProperties,
          },
        ]}
      />
    </Grid>
  );
}

export default SupplierSearch;
