import numberUtils from '@oneAppCore/utils/numbers';

import SearchViewMiniReport from '@src/Components/common/containers/SearchViewMiniReport';
import { Grid, Typography, Tooltip } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    number: {
      fontWeight: 'bold',
    },
  }),
);

function AmountBreakdown(props: any) {
  const classes = useStyles();

  return (
    <SearchViewMiniReport>
      <Tooltip title="Dollar amount for charge types" placement="top">
        <Grid item container xs={12} direction="row" alignItems="center">
          <Grid item container xs={12} md={6}>
            <Grid
              item
              container
              spacing={1}
              style={{ justifyContent: 'space-between' }}
            >
              <Grid
                item
                container
                xs={6}
                style={{ justifyContent: 'flex-start' }}
              >
                <Typography variant="body1">Matched Total:</Typography>
              </Grid>
              <Grid
                item
                container
                xs={6}
                style={{ justifyContent: 'flex-start' }}
              >
                <Typography variant="body1" className={classes.number}>
                  {formatCentsToDollarsIntl(props?.matchedAmount)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={1}
              style={{ justifyContent: 'space-between' }}
            >
              <Grid
                item
                container
                xs={6}
                style={{ justifyContent: 'flex-start' }}
              >
                <Typography variant="body1">Unmatched Total:</Typography>
              </Grid>
              <Grid
                item
                container
                xs={6}
                style={{ justifyContent: 'flex-start' }}
              >
                <Typography variant="body1" className={classes.number}>
                  {formatCentsToDollarsIntl(props?.unmatchedAmount)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={1}
              style={{ justifyContent: 'space-between' }}
            >
              <Grid
                item
                container
                xs={6}
                style={{ justifyContent: 'flex-start' }}
              >
                <Typography variant="body1">Total Amount:</Typography>
              </Grid>
              <Grid
                item
                container
                xs={6}
                style={{ justifyContent: 'flex-start' }}
              >
                <Typography variant="body1" className={classes.number}>
                  {formatCentsToDollarsIntl(props?.totalAmount)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid
              item
              container
              spacing={1}
              style={{ justifyContent: 'space-between' }}
            >
              <Grid
                item
                container
                xs={6}
                style={{ justifyContent: 'flex-start' }}
              >
                <Typography variant="body1">Dropship Total:</Typography>
              </Grid>
              <Grid
                item
                container
                xs={6}
                style={{ justifyContent: 'flex-start' }}
              >
                <Typography variant="body1" className={classes.number}>
                  {formatCentsToDollarsIntl(props?.dropshipAmount)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={1}
              style={{ justifyContent: 'space-between' }}
            >
              <Grid
                item
                container
                xs={6}
                style={{ justifyContent: 'flex-start' }}
              >
                <Typography variant="body1">Inventory Total:</Typography>
              </Grid>
              <Grid
                item
                container
                xs={6}
                style={{ justifyContent: 'flex-start' }}
              >
                <Typography variant="body1" className={classes.number}>
                  {formatCentsToDollarsIntl(props?.inventoryAmount)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={1}
              style={{ justifyContent: 'space-between' }}
            >
              <Grid
                item
                container
                xs={6}
                style={{ justifyContent: 'flex-start' }}
              >
                <Typography variant="body1">Misc. Total:</Typography>
              </Grid>
              <Grid
                item
                container
                xs={6}
                style={{ justifyContent: 'flex-start' }}
              >
                <Typography variant="body1" className={classes.number}>
                  {formatCentsToDollarsIntl(props?.miscAmount)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Tooltip>
    </SearchViewMiniReport>
  );
}

export default AmountBreakdown;
