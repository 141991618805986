import FontAwesome from '@src/Components/common/FontAwesome';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { Props } from './types';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import { Tooltip, IconButton, Chip, Grid } from '@material-ui/core';

function EditButton(Props) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const close = () => {
    setOpen(false);
  };
  return (
    <Grid>
      <Tooltip title={`Edit Credentials`}>
        <span>
          <IconButton
            onClick={() => history.push(`/store-channel/edit/${Props.id}`)}
          >
            <EditTwoToneIcon color="primary" fontSize="default" />
          </IconButton>
        </span>
      </Tooltip>
    </Grid>
  );
}

export default EditButton;
