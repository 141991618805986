import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import DescIcon from '@src/Components/common/containers/SearchView/SearchTable/SearchHeader/DirectionIcons/DescIcon';
import AscIcon from '@src/Components/common/containers/SearchView/SearchTable/SearchHeader/DirectionIcons/AscIcon';
import { Column } from '@src/Components/common/containers/SearchView/types';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 650,
  },
  cellContentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filter: {
    width: 20,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(0.5),
  },
  column: {
    padding: theme.spacing(1),
    border: 0,
  },

  headerColor: {
    backgroundColor: '#f3f2f7',
    border: 0,
  },
}));

type Props = {
  columns: Column[];
};

export default function TableHeader({ columns }: Props) {
  const classes = useStyles();

  return (
    <TableHead className={classes.headerColor}>
      <TableRow>
        {columns.map((column) => {
          if (column.sortBy) {
            return (
              <TableCell
                align={column.align || 'center'}
                key={column.keyName}
                className={classes.column}
              >
                <div className={classes.cellContentWrapper}>
                  <div>{column.title}</div>
                  <div className={classes.filter}>
                    <AscIcon keyName={column.keyName} />
                    <DescIcon keyName={column.keyName} />
                  </div>
                </div>
              </TableCell>
            );
          }
          return (
            <TableCell
              align={column.align}
              key={column.keyName}
              className={classes.column}
            >
              {column.title}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
