import useMagikTime from '../hooks/useMagikTime';

import { channelOptions } from './constants';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

function ChannelOptions() {
  const [{ channelId }, updateState] = useMagikTime.useState();

  const changeChannelId = (channelId: number) => {
    updateState((previous) => ({
      ...previous,
      channelId,
      lostMoneyOrders: previous.lostMoneyOrders.map((order) => ({
        ...order,
        selected: false,
      })),
    }));
  };

  return (
    <Grid item container xs={12} lg={6} spacing={2}>
      {channelOptions.map((channel) => (
        <Grid item key={channel.value}>
          <Button
            onClick={() => changeChannelId(channel.value)}
            color={channelId === channel.value ? 'primary' : 'default'}
            variant={channelId === channel.value ? 'contained' : 'outlined'}
          >
            {channel.label}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}

export default ChannelOptions;
