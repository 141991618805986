import {
  Tooltip,
  IconButton,
  Chip,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
} from '@material-ui/core';
// import ProductApi from '@oneAppCore/services/ProductApi';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';

import { makeStyles, Theme } from '@material-ui/core/styles';
import type {
  Column,
  ExportOption,
} from '@src/Components/common/containers/SearchView/types';
import dayjs from 'dayjs';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import { useHistory } from 'react-router-dom';
import {
  BOOTSTRAP,
  GREENS,
  LIGHTBLACKS,
  PURPLES,
  THEME_PRIMARY,
} from '@oneAppCore/constants/colors';
import {
  DRAFT,
  ACTIVE,
  INACTIVE,
  PENDING,
  ERROR,
} from '@oneAppCore/constants/listings';

const useStyles = makeStyles((theme: Theme) => ({
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  tablePaper: {
    maxHeight: '30vh',
    width: '425px',
    borderRadius: '10px',
    justifyContent: 'flex-start',
    background: theme.palette.background.paper,
    boxShadow: `0px 0px 2px ${theme.palette.black.darkest}`,
  },
  tooltip: {
    maxWidth: '430px',
    borderRadius: '10px',
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
    background: 'transparent',
  },
  tableCell: {
    fontWeight: 'bold',
  },
  listingColumn: {
    padding: 3,
  },
}));

export const columns = (): Column[] => {
  const classes = useStyles();
  return [
    {
      title: 'Thumbnail',
      keyName: 'thumbnail',
      sortBy: false,
      align: 'center',
      customComponent: function ImageRender(row) {
        const { productImages: pi, variationImages: vi } = row;
        const filteredPi = pi?.filter((i) => i) || [];
        const filteredVi = vi?.filter((i) => i) || [];
        const images = filteredPi.length
          ? filteredPi
          : filteredVi.length
          ? filteredVi
          : [];
        return (
          <img
            src={
              images?.[0]
                ? images?.[0]
                : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;
            }}
            alt={`product image for ${
              row.name ? row.name : `product ${row.id}`
            }`}
            width={55}
            height={55}
            style={{ borderRadius: 5 }}
          />
        );
      },
    },
    {
      title: 'Product Name',
      keyName: 'name',
      sortBy: true,
      align: 'center',
    },
    {
      title: 'Product Score',
      keyName: 'productScore',
      sortBy: false,
      align: 'center',
      customComponent: function ProductScore(row) {
        const {
          name,
          mpns,
          skus,
          productImages: pi,
          variationImages: vi,
          category,
          categories,
        } = row;
        const filteredVi = pi?.filter((i) => i) || [];
        const filteredPi = vi?.filter((i) => i) || [];
        const x =
          'Quick Shifter Shift Gate Can Am Maverick X3 900 Ho MAX XRC XRS Turbo 2017-2023';
        if (name === x) console.log('row', row);
        let score = 0;
        if (name) {
          let nameLength = name.length;
          if (nameLength > 30) {
            if (nameLength > 60) {
              nameLength = 15;
            } else {
              nameLength = 30;
            }
          }
          score += nameLength;
        }
        if (mpns) {
          score += 2;
        }
        if (skus) {
          score += 2;
        }
        const placeHolderTxt = 'oneapp-images/imagePlaceholder.png';
        if (filteredPi.length) {
          let imgScore = 3;
          filteredPi.forEach((img) => {
            if (img.includes(placeHolderTxt)) {
              imgScore -= 2;
            } else {
              imgScore += 2;
            }
          });
          if (imgScore > 8) imgScore = 8;
          score += imgScore;
        }
        if (filteredVi.length) {
          let imgScore = 3;
          filteredVi.forEach((img) => {
            if (img.includes(placeHolderTxt)) {
              imgScore -= 2;
            } else {
              imgScore += 2;
            }
          });
          if (imgScore > 8) imgScore = 8;
          score += imgScore;
        }
        if (category || categories) {
          score += 5;
        }
        const lt30 = score < 30;
        const lt60 = score < 60;
        const lt90 = score < 90;

        const color = lt30
          ? LIGHTBLACKS.dark
          : lt60
          ? PURPLES.dark
          : lt90
          ? THEME_PRIMARY.main
          : GREENS.light;

        return (
          <Grid container xs={12}>
            <Grid item container sm={1} md={3} lg={4} xl={5} />
            <Grid
              item
              container
              sm={10}
              md={6}
              lg={4}
              xl={2}
              style={{ justifyContent: 'center' }}
            >
              <Chip
                style={{
                  backgroundColor: color,
                  color: 'white',
                  minWidth: 'fit-content%',
                  height: '100% ',
                }}
                label={score}
              />
            </Grid>
            <Grid item container sm={1} md={3} lg={4} xl={5} />
          </Grid>
        );
      },
    },
    {
      title: 'Listings',
      keyName: 'listing',
      sortBy: true,
      align: 'center',
      customComponent: function listings(row) {
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={classes.iconContainer}>
                  {row?.listingSkus &&
                    row?.listingSkus?.[0] &&
                    row?.listingSkus?.map((listing, lIndex) => {
                      return (
                        <Tooltip
                          key={lIndex}
                          interactive
                          classes={{ tooltip: classes.tooltip }}
                          title={
                            <TableContainer className={classes.tablePaper}>
                              <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Listing Sku</TableCell>
                                    <TableCell>Status</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {listing?.listingDetails &&
                                    listing?.listingDetails?.[0] &&
                                    listing?.listingDetails?.map(
                                      (channelListing, cIndex) => {
                                        const { status } = channelListing;
                                        const background =
                                          status === DRAFT
                                            ? BOOTSTRAP.v0.warning.color
                                            : status === INACTIVE
                                            ? BOOTSTRAP.v0.dark.color
                                            : status === PENDING
                                            ? BOOTSTRAP.v0.light.color
                                            : status === ACTIVE
                                            ? BOOTSTRAP.v0.success.color
                                            : status === ERROR
                                            ? BOOTSTRAP.v0.danger.color
                                            : BOOTSTRAP.v0.light.color;
                                        return (
                                          <TableRow key={cIndex}>
                                            <TableCell
                                              classes={{
                                                root: classes.tableCell,
                                              }}
                                            >
                                              {channelListing.listingSku}
                                            </TableCell>
                                            <TableCell>
                                              <p
                                                style={{
                                                  background,
                                                  fontFamily:
                                                    'Montserrat, sans-serif',
                                                  fontWeight: 400,
                                                  fontSize: '14px',
                                                  lineHeight: '18px',
                                                  padding: '1px 9px',
                                                  color: 'white',
                                                  borderRadius: '17px',
                                                  textAlign: 'center',
                                                }}
                                              >
                                                {status}
                                              </p>
                                            </TableCell>
                                          </TableRow>
                                        );
                                      },
                                    )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          }
                        >
                          <Grid item>
                            <Typography className={classes.listingColumn}>
                              <Avatar
                                key={lIndex}
                                alt={`icon-${lIndex}`}
                                src={listing.icon}
                              />
                            </Typography>
                          </Grid>
                        </Tooltip>
                      );
                    })}
                </div>
              </Grid>
            </Grid>
          </>
        );
      },
    },
    {
      title: 'Category',
      keyName: 'categories',
      sortBy: true,
      align: 'center',
      customComponent: function category(row) {
        if (row.categories === '' && row.category === null) {
          return <Typography>No Category Associated</Typography>;
        } else if (row.categories !== '') {
          return <Typography>{row.categories}</Typography>;
        } else {
          return <Typography>{row.category}</Typography>;
        }
      },
    },
    {
      title: 'Created By (At)',
      keyName: 'createdAt',
      sortBy: true,
      align: 'center',
      type: 'date',
      customComponent: function CreatedAt(row) {
        return (
          <Grid container xs={12} style={{ justifyContent: 'center' }}>
            <Typography>{row.username} </Typography>
            <Typography>
              (
              {dayjs(row.createdAt)
                .tz(dayjs.tz.guess())
                .format('MM/DD/YYYY hh:mma z')}
              )
            </Typography>
            {/* {dayjs(row.createdAt)
            .tz(dayjs.tz.guess())
            .format('MM/DD/YYYY hh:mma z')} */}
          </Grid>
        );
      },
    },
    {
      title: 'Edit',
      keyName: 'edit',
      sortBy: false,
      align: 'center',
      customComponent: function EditRender(row) {
        const history = useHistory();
        const { id } = row;
        return (
          <Grid key={row.id}>
            <Tooltip title={`Edit Product`}>
              <span>
                <IconButton
                  onClick={() => history.push(`/products/view/${id}`)}
                >
                  <EditTwoToneIcon color="primary" fontSize="default" />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        );
      },
    },
  ];
};

export const exportOptions: ExportOption[] = [
  {
    label: 'New Listings',
    value: 'newListings',
    supportedChannels: ['ebay'],
    url: `/api/v1/products/export/newListings/single`,
  },
];
