import { DatePicker } from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';

import type { Props } from './types';
import type { Dayjs } from 'dayjs';

const useStyles = makeStyles((theme: Theme) => ({
  selectContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    color: theme.palette.grey[700],
  },
}));

export default function DateSelect(props: Props) {
  const classes = useStyles();

  const {
    containerClass,
    label,
    labelClass,
    value,
    onChange,
    startOfDay,
    endOfDay,
    ...rest
  } = props;

  const handleChange = (dayjs: Dayjs) => {
    const date = (() => {
      if (!dayjs) return null;
      if (startOfDay) {
        return dayjs.startOf('day').toDate();
      }
      if (endOfDay) {
        return dayjs.endOf('day').toDate();
      }
      return dayjs.toDate();
    })();
    return onChange(date);
  };

  return (
    <div className={containerClass || classes.selectContainer}>
      {label && (
        <Typography
          variant="caption"
          component="span"
          className={labelClass || classes.label}
        >
          {`${label}${props.required ? '*' : ''}`}
        </Typography>
      )}
      <DatePicker
        value={value || null}
        onChange={(dayjs) => handleChange(dayjs)}
        inputVariant="outlined"
        variant="dialog"
        clearable
        autoOk
        {...rest}
      />
    </div>
  );
}
