import { useState, useMemo, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import Api from '@oneAppCore/services/Api';
import useCompanies from '@src/hooks/swr/useCompanies';
import { generatePassword } from '@oneAppCore/utils/authentication';

import {
  Grid,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  Paper,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  TextField,
  Select,
  FontAwesome,
  Backdrop,
} from '@src/Components/common';

import type { FormProps } from '@src/Components/common/containers/SearchView/CreationDrawer/types';
import type { Company } from '@oneAppCore/types/companyTypes';
import type { User } from '@oneAppCore/types/userTypes';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    paddingTop: theme.spacing(1),
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  root: {
    padding: theme.spacing(2),
  },
  botmarg: {
    marginBottom: 10,
  },
  containerClass: {
    width: '100%',
    marginBottom: '-5px',
    position: 'relative',
  },
}));

type Form = Company & User;

function CreateCompany({ onSubmit, close }: FormProps) {
  const classes = useStyles();
  const history = useHistory();

  const [form, setForm] = useState<Form>({});
  const [saving, setSaving] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const update = <K extends keyof Form>(key: K, value: Form[K]) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const submit = async (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setSaving(true);
    try {
      await Api.post(`/api/v1/companies`, form);
      form['companyName'] = form.name;
      await Api.post(`/api/v1/mailer/send`, form);
      enqueueSnackbar(`Created Company`, {
        variant: 'success',
      });
      if (onSubmit && typeof onSubmit === 'function') await onSubmit();
    } catch (e) {
      enqueueSnackbar(`${e.message || e}`, {
        variant: 'error',
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Paper elevation={1} className={classes.root}>
      <form className={classes.form} onSubmit={submit}>
        <Grid container spacing={2} direction="column">
          <Grid item container justify="flex-start">
            <Typography variant="h5">Create Company</Typography>
          </Grid>
          <Grid container xs={12} justify="space-around">
            <Grid item xs={4} style={{ padding: 10 }}>
              <TextField
                required
                fullWidth
                variant="outlined"
                label="Company/Store Name"
                name="name"
                value={form?.name || ''}
                onChange={(e) => update('name', e.target.value)}
              />
            </Grid>
            <Grid item xs={4} style={{ padding: 10 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="User's Email"
                type="email"
                value={form?.email || ''}
                onChange={(e) => update('email', e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={4} style={{ padding: 10 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="First Name"
                value={form?.firstName || ''}
                onChange={(e) => update('firstName', e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={4} style={{ padding: 10 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Last Name"
                value={form?.lastName || ''}
                onChange={(e) => update('lastName', e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={4} style={{ padding: 10 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="User's Username"
                type="username"
                value={form?.username || ''}
                onChange={(e) => update('username', e.target.value)}
                required
              />
            </Grid>
            <Grid
              item
              container
              xs={4}
              style={{ padding: 10, alignItems: 'center' }}
            >
              <Grid item xs={9} md={11}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="User's Password"
                  type={showPassword ? 'text' : 'password'}
                  value={form?.password || ''}
                  onChange={(e) => update('password', e.target.value)}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ cursor: 'pointer' }}
                      >
                        {showPassword ? (
                          <FontAwesome name="eye-slash" type="fa" form="fa" />
                        ) : (
                          <FontAwesome name="eye" type="fa" form="fa" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={1} justify="center">
                <IconButton
                  onClick={() => update('password', generatePassword())}
                  color="primary"
                  style={{ marginTop: 15 }}
                  size="small"
                >
                  <FontAwesome name="random" type="fa" form="fas" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} justify="space-between">
            <Grid item xs={1}>
              <Button
                variant="contained"
                style={{ backgroundColor: '#B61F1F', color: 'white' }}
                fullWidth
                onClick={() => history.push('/admin/companies/search')}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Create Company
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}

export default CreateCompany;
