import { columns, exportOptions } from './constants';

import SearchView from '@src/Components/common/containers/SearchView';
import { Grid } from '@material-ui/core';

function OrdersSearch() {
  return (
    <Grid item container justify="center" alignItems="center" spacing={1}>
      <SearchView
        url="/api/v1/products/search"
        select
        selectTypes={['createProduct', 'bulkExport']}
        columns={columns()}
        filters={[
          {
            type: 'date range',
            name: 'Created At',
            keyName: 'createdAt',
          },
          {
            type: 'string',
            name: 'Sku',
            keyName: 'sku',
          },
          {
            type: 'string',
            name: 'MPN',
            keyName: 'mpn',
          },
          {
            type: 'string',
            name: 'Brand',
            keyName: 'brand',
          },
          {
            type: 'string',
            name: 'Category',
            keyName: 'categories',
          },
        ]}
        exportOptions={exportOptions}
      />
    </Grid>
  );
}

export default OrdersSearch;
