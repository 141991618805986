import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ReactElement } from 'react';
import dayjs from 'dayjs';

export const issueColumns = ['issueUsername', 'issueType'];

export const columns: any[] = [
  {
    id: 1,
    title:'Variation Id',
    key: 'id',
    align:'left',
    // customComponent: function sku({row}) {
    //   console.log('row', row);
    //   return <></>
    // }
  },
  {
    id: 2,
    title:'Sku:',
    key: 'sku',
    align:'left',
  },
  {
    id: 3,
    title: 'Part Number:',
    key: 'manufacturerNo',
    align: 'left',
  },
  /* {
    id: 2,
    title: 'Order Total:',
    key: 'price',
    align: 'left',
    customComponent: function SalePrice({ order, itemIndex }): ReactElement {
      return (
        <Grid container>
          <Grid item container xs={6}>
            <Typography style={{ fontWeight: 600, fontSize: '1.15rem' }}>
              Sale Price:
            </Typography>
          </Grid>
          <Grid item container xs={6}>
            <Typography style={{ fontSize: '1.15rem' }}>
              ${order.price.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },

  {
    id: 3,
    title: 'Cost:',
    key: 'cost',
  },
  {
    id: 4,
    title: 'Ch. Order #:',
    key: 'orderNumber',
    align: 'left',
  },
  {
    id: 5,
    title: 'Supp. Order #:',
    key: 'supplierOrderNo',
    align: 'left',
  },
  {
    id: 6,
    title: 'Tracking #:',
    key: 'supplierTrackingNo',
    align: 'left',
  },

  {
    id: 7,
    title: 'Purchase Date:',
    key: 'purchaseDate',
    align: 'left',
    customComponent: function purchaseDate({ order, itemIndex }): ReactElement {
      return (
        <Grid container>
          <Grid item container xs={6}>
            <Typography style={{ fontWeight: 600, fontSize: '1.14rem' }}>
              Purchased At:
            </Typography>
          </Grid>
          <Grid item container xs={6}>
            <Typography style={{ fontSize: '1.15rem' }}>
              {dayjs(order.purchaseDate)
                .tz(dayjs.tz.guess())
                .format('MM/DD/YYYY hh:mma z')}
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    id: 8,
    title: 'Buyer Name:',
    key: 'shippingName',
    align: 'left',
  },
  {
    id: 9,
    title: 'Shipping Address:',
    key: 'addressLine1',
    align: 'left',
    customComponent: function ShippingAddress({
      order,
      itemIndex: number,
    }): ReactElement {
      return (
        <Grid container>
          <Grid item container xs={6}>
            <Typography style={{ fontWeight: 600, fontSize: '1.14rem' }}>
              Shipping <br />
              Address:
            </Typography>
          </Grid>
          <Grid item container xs={6}>
            <Typography style={{ fontSize: '1.15rem' }}>
              {order.addressLine1} {order.addressLine2} {order.addressLine3}
              {order.city}, {order.state} {order.zipCode} {order.country}
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    id: 10,
    title: 'Sales Channel:',
    key: 'channel',
    align: 'left',
  },
  {
    id: 11,
    title: 'Order Status:',
    key: 'orderStatus',
    align: 'left',
  },
  {
    id: 12,
    title: 'Placed By:',
    key: 'placedByUsername',
    align: 'left',
  },
  {
    id: 13,
    title: 'Tracked By:',
    key: 'trackedByUsername',
    align: 'left',
  },
  {
    id: 14,
    title: 'Issue Type:',
    key: 'issueType',
    align: 'left',
  },
  {
    id: 15,
    title: 'Issue Created By:',
    key: 'issueUsername',
    align: 'left',
  }, */
];
