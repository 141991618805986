import {columns} from './constants'
import Grid from '@material-ui/core/Grid';
import SearchView from '@src/Components/common/containers/SearchView';

function NotificationList() {
  return (
    <Grid item container justify="center" alignItems="center" spacing={1}>
      <SearchView 
        url=''
        columns={columns}
        filters={[
          {
            type: 'string',
            name: 'Category',
            keyName: 'category'
          },
        ]}
      />
    </Grid>
  )
} 

export default NotificationList;