import { startCase, lowerCase } from 'lodash';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { Grid, Tooltip, IconButton, Chip, Typography } from '@material-ui/core';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import { internalSuppliers } from '@oneAppCore/constants/suppliers';
import numberUtils from '@oneAppCore/utils/numbers';
import supplierLinkUtils from '@oneAppCore/utils/links/suppliers';
import type { Column } from '@src/Components/common/containers/SearchView/types';
import { dateFormat } from '@oneAppCore/constants/dates';
import ViewDrawerButton from '../ViewDrawerButton';
import InternalButton from '../InternalOrderButton';
import formatters from '@oneAppCore/utils/formatters';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';

import { orderProfitColors, orderStatusColors } from '@src/utils/orders';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';

export const columns = (suppliers, classes): Column[] => [
  {
    title: 'Placed At',
    keyName: 'purchaseDate',
    sortBy: true,
    align: 'center',
    customComponent: function purchaseDate(row) {
      return (
        <div style={{ minWidth: '120px' }}>
          {dayjs(row.purchaseDate).format(dateFormat)}
        </div>
      );
    },
  },
  {
    keyName: 'expectedShipDate',
    title: 'Ship By',
    sortBy: true,
    align: 'center',
    customComponent: function expectedShipDate(row) {
      return (
        <div style={{ minWidth: '120px' }}>
          {dayjs(row.expectedShipDate).format(dateFormat)}
        </div>
      );
    },
  },
  {
    title: 'Order Number',
    keyName: 'orderNumber',
    sortBy: true,
    customComponent: function OrderNumberDisplay(row) {
      const backgroundColor = orderStatusColors(row.orderStatus);
      return (
        <Grid
          container
          item
          xs={12}
          key={row.id}
          style={{
            minWidth: '160px',
            justifyContent: 'center',
          }}
        >
          <Grid
            item
            xs={12}
            style={{ justifyContent: 'center', textAlign: 'center' }}
          >
            # {row.orderNumber}
          </Grid>
          <Grid item xs={6}>
            <Chip
              style={{
                backgroundColor: backgroundColor,
                color: '#ffffff',
                width: 'fit-content',
                height: '100%',
              }}
              label={startCase(lowerCase(row.orderStatus))}
            />
          </Grid>
          <br />
          {row.issueType && (
            <Grid item xs={12}>
              <Chip
                style={{
                  backgroundColor: 'crimson',
                  color: '#ffffff',
                  width: 'fit-content',
                  height: '100%',
                }}
                label={startCase(lowerCase(row.issueType))}
              />
            </Grid>
          )}
        </Grid>
      );
    },
  },
  {
    title: 'Supplier Order #',
    keyName: 'supplierOrderNo',
    align: 'center',
    customComponent: function SupplierOrderNumbers(row) {
      // supplierLinkUtils.productLink(row.supplier)
      const holder = {};
      if (!row?.orderItems?.[0]) return <div key={row.id} />;
      row.orderItems.forEach((item) => {
        if (item.supplierOrderNo) {
          if (!holder[item.supplierOrderNo]) {
            holder[item.supplierOrderNo] = item.supplierId;
          }
        } else {
          holder['null'] = item.supplierId;
        }
        // if (item.supplierOrderNo && !holder[item.supplierOrderNo]) holder[item.supplierOrderNo] = item.supplierId;
      });
      const supplierOrderNumbers = Object.keys(holder).map((item, index) => {
        let ret;
        if (!internalSuppliers.includes(holder[item])) {
          if (item === 'null') {
            ret = 'No Supplier Order #';
          } else {
            const link = supplierLinkUtils.trackingLink(
              suppliers,
              { supplierOrderNo: item, supplierId: holder[item] },
              row,
            );
            if (!link) {
              ret = 'Item not yet supported';
            } else {
              ret = (
                <a key={`${row.id}-${index}`} href={link}>
                  {item}
                </a>
              );
            }
          }
        } else {
          ret = `${item !== 'null' ? item : 'Internal'} Item`;
        }
        return ret;
      });
      return (
        <div key={row.id}>
          {supplierOrderNumbers.map((a) => (
            <>
              {a}
              <br />
            </>
          ))}
        </div>
      );
    },
  },

  {
    keyName: 'channel',
    title: 'Store & Channel',
    sortBy: true,
    align: 'center',
    customComponent: function StoreChannels(row) {
      return (
        <Grid
          container
          item
          xs={12}
          justify="space-around"
          style={{ alignItems: 'center' }}
        >
          <Grid item xs={4}>
            <Typography style={{ textTransform: 'uppercase' }}>
              {row.store}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <img
              width={35}
              height={35}
              style={{ borderRadius: 10 }}
              src={
                row.icon != null
                  ? row.icon
                  : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
              }
            />
          </Grid>
        </Grid>
      );
    },
  },
  {
    keyName: 'price',
    title: 'Order Total',
    align: 'center',
    customComponent: function OrderNumberDisplay(row) {
      if (row.orderNumber === '114-8081674-6966639') console.log(row.orderItems[0]);
      const totalPrice = row.orderItems?.reduce(
        (acc, item) => acc + item.itemPrice,
        0,
      );
      const totalFees =
        row.channelFee 
          ? row.channelFee
          : row.orderItems?.every((e) => e.itemFee)
            ? row.orderItems?.reduce((acc, item) => acc + item.itemFee, 0)
            : (totalPrice * 0.12);
      let itemCost = 0;
      let itemShip = 0;
      row.orderItems?.forEach((item) => {
        const supp = item?.allSuppliersArray[0];
        let index = 0;
        supp?.internal?.forEach((int, idx) => {
          if (index === 0 && int) index = idx;
        })
        supp?.available?.forEach((int, idx) => {
          if (index === 0 && int) index = idx;
        })
        itemCost += (supp?.cost[index] || 0) * item.quantity;
        itemShip += (supp?.estimatedShipping[index] || supp?.shippingCost[index] || 0);
      })
      const totalItemCost = row.orderItems?.reduce(
        (acc, item) => acc + item.supplierSubTotal,
        0,
      );

      const totalItemShipping = row.supplierShippingCost || row.orderItems?.reduce(
        (acc, item) => acc + item.supplierShippingCost,
        0,
      );

      const totalCost = totalFees + itemCost + itemShip;
      const totalProfit = totalPrice - totalCost;
      const backgroundColor = (() => {
        const colorsAndTints: any[] = [
          { tint: 'main', color: 'red' },
          { tint: 'main', color: 'orange' },
          { tint: 'main', color: 'green' },
        ];
        return orderProfitColors(totalProfit, [0, 5], colorsAndTints);
      })();

      return (
        <>
          <Grid
            container
            item
            xs={12}
            key={row.id}
            style={{ minWidth: '170px', justifyContent: 'center' }}
          >
            {' '}
            <Grid item xs={12}>
              {formatCentsToDollarsIntl(totalPrice || 0)}
            </Grid>
            <br />
            <Grid
              item
              xs={4}
            >
              <Chip
                style={{
                  backgroundColor: backgroundColor,
                  color: 'white',
                  width: '100%',
                  height: '100%',
                }}
                label={`${Math.floor((totalProfit / totalPrice) * 100)}%`}
              />
            </Grid>
          </Grid>
        </>
      );
    },
  },
  {
    keyName: 'quantity',
    title: 'QTY',
    sortBy: true,
    customComponent: function Quantity(row) {
      if (!row?.orderItems?.[0]) return;
      return (
        <Grid
          container
          item
          xs={1}
          direction="column"
          style={{
            maxWidth: '600px',
            justifyContent: 'flex-end',
          }}
        >
          {row.orderItems?.map((item) => {
            const colorClass = item.quantity > 1 ? classes.red : classes.black;
            return (
              <Grid item container key={item.id} alignItems="center">
                <Grid
                  item
                  container
                  xs={12}
                  direction="column"
                  alignItems="center"
                  justify="center"
                >
                  <Grid item>
                    <div
                      className={formatters.classNames([
                        classes.quantity,
                        colorClass,
                      ])}
                      style={{ width: 30, height: 30 }}
                    >
                      <p style={{ fontSize: '18px' }}>{item?.quantity}</p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      );
    },
  },
  {
    keyName: 'items',
    title: 'Items',
    sortBy: true,
    // align: 'center',
    customComponent: function OrderItemsDisplay(row) {
      if (!row?.orderItems?.[0]) return;
      return (
        <Grid
          container
          item
          xs={10}
          spacing={1}
          direction="column"
          style={{
            maxWidth: '600px',
            justifyContent: 'flex-end',
          }}
        >
          {row.orderItems?.map((item) => {
            return (
              <Grid item container key={item.id} alignItems="center">
                <Grid
                  item
                  container
                  xs={4}
                  direction="column"
                  alignItems="center"
                  justify="flex-end"
                  wrap="nowrap"
                >
                  <Grid item>
                    {item.variationImages?.[0] ? (
                      <>
                        <img
                          height={50}
                          width={50}
                          src={
                            item.variationImages?.[0]?.imageUrl != null
                              ? item.variationImages?.[0]?.imageUrl
                              : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;
                          }}
                          alt="Product"
                        />
                      </>
                    ) : (
                      <>
                        <img
                          height={50}
                          width={50}
                          src={`https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`}
                          alt="placeholder image for product image"
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item container direction="column" xs={8}>
                  <Grid item xs={12}>
                    <div className={classes.title}>
                      {item?.channelData?.title}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <span style={{ lineHeight: 0.5 }}>SKU: </span>
                    <span className={classes.bold}>{item.sku}</span>
                    {/* <span style={{ lineHeight: 0.5 }}>SKU: </span>
                    <span className={classes.bold}>{item.listingSku}</span> */}
                    {' | '}
                    <span style={{ lineHeight: 0.5 }}>MPN: </span>
                    <span className={classes.bold}>{item.manufacturerNo}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <span style={{ lineHeight: 0.5 }}>Location: </span>
                    {item.location ? (
                      <span className={classes.bold}>{item.location}</span>
                    ) : (
                      <span className={classes.bold}> N/A </span>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      );
    },
  },
  {
    title: 'Actions',
    keyName: 'actions',
    customComponent: function ActionButtons(row) {
      const history = useHistory();
      const { id } = row;
      return (
        <>
          <Grid
            container
            item
            xs={12}
            direction="row"
            style={{
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingRight: 5,
              paddingLeft: 15,
            }}
          >
            <Grid item xs={3} direction="column">
              <ViewDrawerButton id={row.id} row={row} />
            </Grid>
            <Grid item xs={3} direction="column">
              <InternalButton row={row} />
            </Grid>
            <Grid
              item
              container
              xs={3}
              direction="column"
              style={{ marginRight: '10px' }}
            >
              <Tooltip title="Edit Order">
                <span>
                  <IconButton
                    style={{ fontSize: '10px' }}
                    disabled={lowerCase(row.orderStatus) !== 'unassigned'}
                    onClick={() => history.push(`/orders/view/${id}`)}
                  >
                    <EditTwoToneIcon color="primary" fontSize="default" />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      );
    },
  },
];
