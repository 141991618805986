import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ReactElement } from 'react';
import dayjs from 'dayjs';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';

export const columns: any[] = [
  {
    title: 'Product',
    key: 'name',
    customComponent: function Name({ row }): ReactElement {
      const nameElements = (<>
        {row.name}{row.variation !== null ? ` ${row.variation}` : null}
      </>);
      return (
        <Grid container xs={12}>
          <Grid item xs={6}>
            <Typography style={{ fontWeight: 600, fontSize: '1.15rem' }}>
              Product
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ fontSize: '1.15rem' }}>{nameElements}</Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    title: 'Supplier',
    key: 'supplierName',
  },
  {
    title: 'Cost',
    key: 'cost',
    customComponent: function Cost({ row }) {
      return (
        <Grid container xs={12}>
          <Grid item xs={6}>
            <Typography style={{ fontWeight: 600, fontSize: '1.15rem' }}>
              Cost
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ fontSize: '1.15rem' }}>
              {formatCentsToDollarsIntl(row.cost)}
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    title: 'Shipping',
    key: 'shippng',
    customComponent: function Shipping({ row }) {
      return (
        <Grid container xs={12}>
          <Grid item xs={6}>
            <Typography style={{ fontWeight: 600, fontSize: '1.15rem' }}>
              Shipping (Estimated)
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ fontSize: '1.15rem' }}>
              {formatCentsToDollarsIntl(row.shipping)}<br/>({formatCentsToDollarsIntl(row.estimatedShipping)})
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
];
