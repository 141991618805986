import { useHistory } from 'react-router-dom';

import queryUtils from '@src/utils/queries';

import {
  Grid,
  Typography,
  Button,
  Switch,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';

import dayjs from 'dayjs';

import type { Column } from '@src/Components/common/containers/SearchView/types';

export const columns: Column[] = [
  {
    title: 'Name',
    keyName: 'name',
    sortBy: true,
  },
  // {
  //   title: 'Internal Supplier',
  //   keyName: 'internalSupplier',
  //   sortBy: true,
  // },
  {
    title: 'Supplier Type',
    keyName: 'internalSupplier',
    sortBy: false,
    customComponent: function InternalSupplier(row) {
      const inOrEx = row.internalSupplier ? 'Warehouse' : 'Dropship';
      const appOrEx = row.internalStock ? 'In House' : '3rd Party';
      return (
        <>
          {/* <Switch
          checked={row.internalSupplier}
          inputProps={{ 'aria-label': 'controlled' }}
          /> 
        */}
          <p>{inOrEx}</p>
          {row.internalSupplier === true ? <p>{`${appOrEx} Stock`}</p> : null}
        </>
      );
    },
  },
  {
    title: 'Inventory(OOS)',
    keyName: 'inventory',
    sortBy: true,
    align: 'center',
    customComponent: function Inventory(row) {
      return (
        <Grid>
          <Typography>
            {Number(row.suppInventory).toLocaleString()}(
            {Number(row.suppOOS).toLocaleString()})
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: 'Products',
    keyName: 'products',
    sortBy: true,
    align: 'center',
    customComponent: function Products(row) {
      return (
        <Grid>
          <Typography>
            {Number(row.suppProducts).toLocaleString()}
            {/* {Number(row.imported - row.created).toLocaleString()}) */}
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: 'Listings(Inactive)',
    keyName: 'totalListings',
    sortBy: true,
    align: 'center',
    customComponent: function listings(row) {
      return (
        <Grid>
          <Typography>
            {Number(row.listTotal).toLocaleString()}(
            {Number(row.listInactive).toLocaleString()})
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: 'Created At',
    keyName: 'createdAt',
    sortBy: true,
    customComponent: function CreatedAt(row) {
      console.log(row, 'supp row');
      return (
        <Grid container xs={12} style={{ justifyContent: 'center' }}>
          <Typography>
            {dayjs(row.createdAt)
              .tz(dayjs.tz.guess())
              .format('MM/DD/YYYY hh:mma z')}
          </Typography>
        </Grid>
      );
    },
  },

  // {
  //   title: 'Supplier Meta Data',
  //   keyName: 'metaData',
  //   sortBy: false,
  //   customComponent: function SupplierMetaData(row) {
  //     const metaData = row.metaData ? row.metaData : '';
  //     return (
  //       <>
  //         <p>{typeof metaData/* {metadata.map(ba => ba).join(', ')} */}</p>
  //       </>
  //     );
  //   },
  // },
  {
    title: 'View',
    keyName: 'view',
    sortBy: false,
    align: 'center',
    customComponent: function ViewSupplier(row) {
      const history = useHistory();

      const ViewSupplier = () => {
        const query = queryUtils.add('id', row.id);
        history.replace({
          search: query,
        });
      };
      return (
        <Grid key={row.id}>
          <Tooltip title={`View Supplier Details`}>
            <span>
              <IconButton onClick={ViewSupplier}>
                <VisibilityTwoToneIcon color="primary" fontSize="default" />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      );
    },
  },
];
