import { useState } from 'react';
import { makeStyles, Button, Card } from '@material-ui/core';
import CreateChannel from '../CreateChannel';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import ChannelsApi from '@oneAppCore/services/ChannelsApi';
import Api from '@oneAppCore/services/Api';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  button: {
    textTransform: 'none',
    width: '80%',
  },
  buttonCard: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    alignItems: 'center',
    width: '250px',
    height: '150px',
    marginTop: '40px',
  },
});

export type ChannelForm = {
  channelName?: string;
  storeName?: string;
  numberOfListings?: number;
  apiUrl?: string;
  consumerKey?: string;
  consumerSecret?: string;
  status?: string;
  updateForm?: string;
  apiCredentials?: string;
};

function ChannelPage() {
  const classes = useStyles();
  const { id }: any = useParams();

  const [form, setForm] = useState<ChannelForm>({});

  const [isViewMode, setIsViewMode] = useState<boolean>(false);

  return (
    <div>
      <CreateChannel
        form={form}
        updateForm={setForm}
        isViewMode={isViewMode}
        id={id}
      />
    </div>
  );
}

export default ChannelPage;
