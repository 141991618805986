import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ReactElement } from 'react';
import dayjs from 'dayjs';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { formatCentsToDollars } from '@oneAppCore/utils/currency';

export const columns = [
  {
    id: 1,
    title: 'MPN',
    key: 'manufacturerNo',
  },
  { id: 7, title: 'PO #', key: 'test' },
  {
    id: 2,
    title: 'Location',
    key: 'location',
  },
  {
    id: 3,
    title: 'Brand',
    key: 'brand',
  },
  {
    id: 4,
    title: 'Suppliers',
    key: 'suppliers',
    customComponent: function Suppliers(row) {
      return <Table></Table>;
    },
  },
  {
    id: 5,
    title: 'Inventory',
    key: 'inventory',
    customComponent: function Inventory(row) {
      return (
        <>
          <Grid container>
            <Grid item xs={5}>
              <Typography style={{ fontWeight: 600, fontSize: '1.15rem' }}>
                Inventory Data:
              </Typography>
            </Grid>
            <Grid item container xs={7}>
              <Typography>
                <Grid item container xs={12}>
                  <Grid item xs={7}>
                    <Typography style={{ fontSize: '1.15rem' }}>
                      Days Left:
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography style={{ fontSize: '1.15rem' }}>
                      {row?.row?.daysLeft}
                    </Typography>
                  </Grid>
                  <br />
                  <Grid item xs={7}>
                    <Typography style={{ fontSize: '1.15rem' }}>
                      Avaialable:
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography style={{ fontSize: '1.15rem' }}>
                      {row?.row?.quantity}
                    </Typography>
                  </Grid>
                  <br />
                  <Grid item xs={7}>
                    <Typography style={{ fontSize: '1.15rem' }}>
                      Inbound:
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography style={{ fontSize: '1.15rem' }}>
                      {row?.row?.virtualQty}
                    </Typography>
                  </Grid>
                  <br />
                  <Grid item xs={7}>
                    <Typography style={{ fontSize: '1.15rem' }}>
                      Recommended:
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography style={{ fontSize: '1.15rem' }}>
                      {row?.row?.qtyNeeded}
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    },
  },
  { id: 6, title: '7 Day Sales', key: 'salesInterval' },
  {
    id: 7,
    title: 'Suppliers',
    key: 'suppliers',
    customComponent: function suppliers(row) {
      return (
        <TableContainer style={{ marginTop: 10 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Supplier</TableCell>
                <TableCell>Cost</TableCell>
                <TableCell>Ship Act. / Est.</TableCell>
              </TableRow>
            </TableHead>
            {row?.supplierData &&
              row?.supplierData?.[0] &&
              row?.supplierData.map((supplier, index) => (
                <TableRow key={index}>
                  <TableCell>{supplier.name}</TableCell>
                  <TableCell>{formatCentsToDollars(supplier.cost)}</TableCell>
                  <TableCell>
                    {`${formatCentsToDollars(supplier.shippingCost)} / ${formatCentsToDollars(supplier.estimatedShipping)}`}
                  </TableCell>
                </TableRow>
              ))}
          </Table>
        </TableContainer>
      );
    },
  },
];
