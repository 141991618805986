import type { Column } from '@src/Components/common/containers/SearchView/types';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import { Grid, Typography } from '@material-ui/core';

export const columns: Column[] = [
  {
    keyName: 'image',
    title: 'Image',
    align: 'center',
    customComponent: function InventoryImage(row) {
      return (
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <img
              src={
                row.images?.[0]?.imageUrl != null
                  ? row.images?.[0]?.imageUrl
                  : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;
              }}
              alt={`product image for ${row.manufacturerNo}`}
              width={45}
              height={45}
              style={{ borderRadius: 5 }}
            />
          </Grid>
        </Grid>
      );
    },
  },
  {
    title: 'Item Name',
    keyName: 'name',
    customComponent: function Name(row) {
      return row?.variation ? (
        <Typography>
          {row.name} - {row.variation}
        </Typography>
      ) : (
        <Typography>{row.name}</Typography>
      );
    },
  },
  {
    title: 'MPN',
    keyName: 'manufacturerNo',
  },
  {
    title: 'Listing SKU',
    keyName: 'listingSku',
  },
  {
    title: 'Brand',
    keyName: 'brand',
  },
  {
    title: 'Last Week',
    keyName: 'lastweekcount',
    customComponent: function LastWeek(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography
            style={{
              color:
                row.lastweekdollaramount > row.twoweeksagodollaramount
                  ? 'green'
                  : row.lastweekdollaramount < row.twoweeksagodollaramount
                  ? 'red'
                  : 'inherit',
            }}
          >
            {row.lastweekcount} (${row.lastweekdollaramount})
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: '2 Weeks Ago',
    keyName: 'twoweeksagodata',
    customComponent: function TwoWeeks(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography
            style={{
              color:
                row.twoweeksagodollaramount > row.threeweeksagodollaramount
                  ? 'green'
                  : row.twoweeksagodollaramount < row.threeweeksagodollaramount
                  ? 'red'
                  : 'inherit',
            }}
          >
            {row.twoweeksagocount} (${row.twoweeksagodollaramount})
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: '3 Weeks Ago',
    keyName: 'threeweeksagodata',
    customComponent: function ThreeWeeks(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography
            style={{
              color:
                row.threeweeksagodollaramount > row.fourweeksagodollaramount
                  ? 'green'
                  : row.threeweeksagodollaramount < row.fourweeksagodollaramount
                  ? 'red'
                  : 'inherit',
            }}
          >
            {row.threeweeksagocount} (${row.threeweeksagodollaramount})
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: '4 Weeks Ago',
    keyName: 'fourweeksagodata',
    customComponent: function FourWeeks(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography
            style={{
              color:
                row.fourweeksagodollaramount > row.fiveweeksagodollaramount
                  ? 'green'
                  : row.fourweeksagodollaramount < row.fiveweeksagodollaramount
                  ? 'red'
                  : 'inherit',
            }}
          >
            {row.fourweeksagocount} (${row.fourweeksagodollaramount})
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: '5 Weeks Ago',
    keyName: 'fiveweeksagodata',
    customComponent: function FiveWeeks(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography
            style={{
              color:
                row.fiveweeksagodollaramount > row.sixweeksagoamount
                  ? 'green'
                  : row.fiveweeksagodollaramount < row.sixweeksagoamount
                  ? 'red'
                  : 'inherit',
            }}
          >
            {row.fiveweeksagocount} (${row.fiveweeksagodollaramount})
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: '6 Weeks Ago',
    keyName: 'sixweeksagodata',
    customComponent: function SixWeeks(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography>
            {row.sixweeksagocount} (${row.sixweeksagodollaramount})
          </Typography>
        </Grid>
      );
    },
  },
];
