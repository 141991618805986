import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import queryUtils from '@src/utils/queries';

import FontAwesome from '@src/Components/common/FontAwesome';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import { Tooltip, IconButton, Grid, Button } from '@material-ui/core';
import EditInventory from '../InventoryDrawer'
import { makeStyles, Theme } from '@material-ui/core/styles';

import type { Props } from './types';
import { BLUES, THEME_PRIMARY } from '@oneAppCore/constants/colors';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: THEME_PRIMARY.contrastText,
    backgroundColor: THEME_PRIMARY.main,
  },
}));

function AddButton() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const close = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid item style={{ justifyContent: 'flex-end', float: 'right' }}>
        <Tooltip title={'Add Inventory Item'}>
          <span>
            <Button
              variant='contained'
              onClick={() => setOpen(true)}
              color='primary'
              className={classes.button}
            >
              Add
            </Button>
          </span>
        </Tooltip>
      </Grid>
      <EditInventory isDrawerOpen={open} setDrawerOpen={close} />
    </>
  );
}

export default AddButton;
