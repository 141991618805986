import Grid from '@material-ui/core/Grid';

import IssuesBreakdown from './IssuesBreakdown';
import Unshipped from './Unshipped';
import ShipToday from './ShipToday';
import CostBreakdown from './CostBreakdown';

function MiniReports(props: any) {
  return (
    <Grid item container spacing={1}>
      {/* <IssuesBreakdown {...props} /> */}
      <Unshipped {...props} />
      <ShipToday {...props} />
      <CostBreakdown {...props} />
    </Grid>
  );
}

export default MiniReports;
