import { ReactElement } from 'react';
import { ISSUE, TRACKED, PLACED } from '@oneAppCore/constants/orders';
import { startCase, lowerCase } from 'lodash';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { getColor } from '@src/utils/colors';
import { orderStatusColors } from '@src/utils/orders';

const formatNumber = (inputCost: string | number | null) => {
  let returnedValue = 'N/A';
  if (typeof inputCost === 'number') {
    returnedValue = inputCost.toFixed(2);
  } else if (typeof inputCost === 'string') {
    returnedValue = parseFloat(inputCost).toFixed(2);
  }
  return returnedValue;
};

export const firstColumns = [
  {
    id: 1,
    title: 'Order # :',
    key: 'channelOrderId',
  },
  {
    id: 2,
    title: 'Order Placed :',
    key: 'purchaseDate',
  },
  {
    id: 3,
    title: 'Ship By :',
    key: 'expectedShipDate',
  },
];

export const secondColumns = [
  {
    id: 1,
    title: 'Status :',
    key: 'orderStatus',
    customComponent: function statusChip(row) {
      const backgroundColor = orderStatusColors(row.orderStatus);
      const { order } = row;
      return (
        <Grid key={order.id} container spacing={1} justify="space-between">
          <Grid item container xs={8} alignItems="center" justify="flex-start">
            <Grid item container xs={8}>
              <Typography>
                <strong>Status :</strong>
              </Typography>
            </Grid>

            <Grid
              style={{
                backgroundColor,
                padding: 10,
                borderRadius: 10,
                width: 100,
                height: 10,
                marginTop: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
              }}
            >
              {startCase(lowerCase(order.orderStatus))}
            </Grid>
            {order.issueType && (
              <Grid
                style={{
                  backgroundColor: getColor('red', 'main'),
                  padding: 10,
                  borderRadius: 10,
                  minWidth: 100,
                  height: 10,
                  marginTop: 5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                }}
              >
                {startCase(lowerCase(order.issueType))}
              </Grid>
            )}
          </Grid>
        </Grid>
      );
    },
  },
  {
    id: 2,
    title: 'Cost :',
    key: 'itemPrice',
    customComponent: function CostOfItem({ order, itemIndex }): ReactElement {
      const cost = order.orderItems?.reduce((acc, val) => {
        if (!val) return acc;
        const itemCost =
          (val.supplierTax || 0) / 100 +
          (val.supplierSubTotal || 0) / 100 +
          (val.supplierShippingCost || 0) / 100;
        return (acc += itemCost);
      }, 0);

      return (
        <>
          <Grid container spacing={1} justify="space-between">
            <Grid
              item
              container
              xs={8}
              alignItems="center"
              justify="flex-start"
            >
              <Grid item container xs={8}>
                <Typography>
                  <strong>Cost :</strong>
                </Typography>
              </Grid>

              <Grid item container xs={4}>
                <Typography>${formatNumber(cost)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    },
  },
  {
    id: 3,
    title: 'Profit :',
    key: 'profit',
    customComponent: function Profit({ order, itemIndex }) {
      const cost = order.orderItems?.reduce((acc, val) => {
        if (!val) return acc;

        const itemCost =
          (val.supplierTax || 0) / 100 +
          (val.supplierSubTotal || 0) / 100 +
          (val.supplierShippingCost || 0) / 100;
        return (acc += itemCost);
      }, 0);

      const price = order.orderItems?.reduce((acc, val) => {
        if (!val) return acc;
        const price = (val.itemPrice || 0) / 100;
        return (acc += price);
      }, 0);
      const profit = !cost ? 0 : price - price * 12 * 0.01 - cost;
      const profitMargin = (profit / price) * 100;
      return (
        <>
          <Grid container spacing={1} justify="space-between">
            <Grid
              item
              container
              xs={8}
              alignItems="center"
              justify="flex-start"
            >
              <Grid item container xs={8}>
                <Typography>
                  <strong>Profit :</strong>
                </Typography>
              </Grid>

              <Grid item container xs={4}>
                <Typography>${formatNumber(profit)} </Typography>
                <Grid item xs={12}>
                  <Chip label={`${formatNumber(profitMargin)}%`} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    },
  },
];

export const textColumns = [
  {
    id: 1,
    require: true,
    title: 'Address Line 1',
    key: 'addressLine1',
    list: 'left',
  },
  {
    id: 2,
    title: 'Address Line 2',
    key: 'addressLine2',
    list: 'left',
  },
  {
    id: 3,
    require: true,
    title: 'City',
    key: 'city',
    list: 'left',
  },
  {
    id: 4,
    require: true,
    title: 'State',
    key: 'state',
    list: 'right',
  },
  {
    id: 5,
    require: true,
    title: 'Zip Code',
    key: 'zipCode',
    list: 'right',
  },
  {
    id: 6,
    require: true,
    title: 'Country',
    key: 'country',
    list: 'right',
  },
];

export const buyerInfoColumns = [
  {
    id: 1,
    title: 'Buyer Name :',
    key: 'shippingName',
  },
];

export const orderItemsColumns = [
  {
    id: 1,
    title: 'Product Name :',
    key: 'channelData',
    customComponent: function ProductName(row) {
      const { channeldata } = row.item;
      return (
        <Grid container spacing={1} justify="flex-start">
          <Grid
            item
            container
            key={row.item?.id}
            xs={6}
            spacing={1}
            alignItems="center"
            justify="flex-start"
          >
            <Grid item container xs={5}>
              <Typography>
                <strong>Product Name :</strong>
              </Typography>
            </Grid>
            <Grid item container xs={6}>
              <Grid item>{channeldata?.[0].title}</Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    },
  },
  {
    id: 2,
    title: 'Listing Sku : ',
    key: 'listingSku',
  },
  {
    id: 3,
    title: 'Tracking No. :',
    key: 'supplierTrackingNo',
  },
];
