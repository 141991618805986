import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import pdfMerger from 'pdf-merger-js';
import { omit } from 'lodash';
import printJS from 'print-js';
import { createMuiTheme } from '@material-ui/core/styles';
import {
  Tooltip,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  ButtonBase,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Api from '@oneAppCore/services/Api';
import shipStationApi from '@oneAppCore/services/ShipStationApi';
import FontAwesome from '@src/Components/common/FontAwesome';
import { PrintLabelForm } from '@oneAppCore/one-app/src/Components/Orders/PrintLabelModal/types';
import useSearch from '@oneAppCore/one-app/src/Components/common/containers/SearchView/hooks/useSearch';
import PrintLabelModal from '@oneAppCore/one-app/src/Components/Orders/PrintLabelModal';
import { addressStringFormatter } from '@src/utils/addressStringLimiter';
import BarcodeScanner from '../BarcodeScanner';

const generateUrl = async (base64Pdfs: string[]) => {
  const merger = new pdfMerger();
  for await (const pdf of base64Pdfs) {
    const pdfBuffer = Buffer.from(pdf, 'base64');
    await merger.add(pdfBuffer);
  }
  const buff = await merger.saveAsBuffer();
  const mergedPDF = new Blob([buff], { type: 'application/pdf' });
  return URL.createObjectURL(mergedPDF);
};

// const theme = createMuiTheme({
//   breakpoints: {
//     values: {
//       xs: 0,
//       sm: 600,
//       md: 960,
//       lg: 1280,
//       xl: 3000,
//     },
//   },
// });

const blankForm = {
  carrierCode: 'stamps_com',
  serviceCode: 'usps_priority_mail',
  packageCode: 'package',
  carrierOptions: [],
  serviceOptions: [],
  packageOptions: [],
  selectedServiceName: 'USPS Priority Mail - Package',
  shipFrom: null,
  shipTo: null,
  weight: {
    value: null,
    units: 'ounces',
  },
  dimensions: {
    units: 'inches',
    length: 1,
    width: 4,
    height: 8,
  },
};

const useStyles = makeStyles((theme) =>
  createStyles({
    mobilePrint: {
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginBottom: 10,
        minHeight: '40px',
      },
      [theme.breakpoints.up('md')]: {
        width: 'fit-content',
        minHeight: '40px',
      },
    },
    mobileBarcode: {
      [theme.breakpoints.up('md')]: {
        marginRight: '5px',
      },
    },
  }),
);

function BatchPrintButton({
  selectedRows,
  deselectAllRows,
  selectRow,
  carrierData,
  me,
}) {
  const classes = useStyles();
  const [clicked, setClicked] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const [form, setForm] = useState<PrintLabelForm[]>([]);
  const [carrierServiceCodes, setCarrierServiceCodes] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [enableCamera, setEnableCamera] = useState(false);

  // const { mutate } = useSearch();
  const { data } = useSearch(false);
  const rows = data?.rows || [];
  const [upc, setUpc] = useState('');
  const upcRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const extractOuncesFromPounds = (weight: number) => {
    let afterDecimalWeight = (weight - Math.floor(weight)).toFixed(2);
    return parseFloat(afterDecimalWeight) * 16;
  };

  const initiateCamera = () => {
    setEnableCamera(!enableCamera);
  };

  const searchOrderByUpc = async (upc) => {
    deselectAllRows();
    try {
      const res = await Api.get('api/v1/orders/get-upc-orders', { upc: upc });
      if (res?.rows?.length > 0) {
        // setOpen(true);
        selectRow(res.rows[0]);
        await handleOpen([res.rows[0]]);
      } else if (res?.error) {
        enqueueSnackbar(res.error, {
          variant: 'error',
        });
      }
      // setOpen(true)
    } catch (error) {
      console.log('err:', error);
    }
  };

  const handleKeyPress = async (event) => {
    deselectAllRows();
    if (event.key === 'Enter') {
      // Handle the Enter key press event here
      searchOrderByUpc(upc);
    }
  };

  const printLabel = async (formList) => {
    setClicked(true);
    const labelList: any[] = [];
    let error_variable = '';
    const updateList: any[] = [];
    const isTest = false;
    try {
      for await (const form of formList) {
        error_variable = form.shipTo.name;
        const order = selectedRows.find(
          (row) => row.shippingName === form.shipTo.name,
        );
        const response = await shipStationApi.printLabel({
          order,
          shipStationForm: omit(form, ['orderNumber']),
          isTest,
        });

        const { labelData, orderUpdate } = response;
        labelList.push(labelData);
        updateList.push({ order, orderUpdate });
      }

      const url = await generateUrl(labelList);
      printJS(url);
      if (!isTest) {
        try {
          for await (const update of updateList) {
            const { order, orderUpdate } = update;
            await Api.put(`/api/v1/orders/${order.id}`, orderUpdate);
          }

          enqueueSnackbar(`Orders successfully updated!`, {
            variant: 'success',
          });
        } catch (updateError) {
          console.debug(
            `*** Updating orders from batch label print ${updateError} ***`,
          );
          enqueueSnackbar(`${updateError || updateError.message}`, {
            variant: 'error',
          });
          throw updateError;
        }
      }
      // mutate();
      deselectAllRows();
      setClicked(false);
    } catch (error) {
      if (labelList.length > 0) {
        const url = await generateUrl(labelList);
        printJS(url);
        if (!isTest) {
          try {
            for await (const update of updateList) {
              const { order, orderUpdate } = update;
              await Api.put(`/api/v1/orders/${order.id}`, orderUpdate);
            }

            enqueueSnackbar(`Orders successfully updated!`, {
              variant: 'success',
            });
          } catch (updateError) {
            console.debug(
              `*** Updating orders from batch label print ${updateError} ***`,
            );
            enqueueSnackbar(`${updateError || updateError.message}`, {
              variant: 'error',
            });
            throw updateError;
          }
        }
      }
      enqueueSnackbar(`${error || error.message}`, {
        variant: 'error',
      });
      enqueueSnackbar(
        `Error occured for order with shipping name ${error_variable}`,
        {
          variant: 'error',
        },
      );
      setClicked(false);
    }
  };

  const handleOpen = async (scannedUpcRows?: any) => {
    selectedRows?.length && setClicked(true);
    const loopRows = scannedUpcRows?.length > 0 ? scannedUpcRows : selectedRows;
    try {
      const formList = [];
      const orderItems = [];
      const territories = ['AS', 'GU', 'MH', 'FM', 'MP', 'PW', 'PR', 'VI'];
      for await (const selectedRow of loopRows) {
        let label;
        if (!selectedRow.label) {
          label = await shipStationApi.getLabel(selectedRow.id);
        } else {
          label = selectedRow.label;
        }
        if (!label) {
          // Get shipFrom Data
          const response = await shipStationApi.getIntegration();

          // Get product weights
          let weight = 0;
          let totalWeightInOunces = 0;
          let totalWeightInPounds = 0;
          const items = await Promise.all(
            selectedRow.orderItems.map(async (item) => {
              if (!item?.variationId) {
                throw `Order item with ID of ${item.id} is missing a variation Id. Cannot pull weight. This probably means there is a data inconsistency.`;
              }

              const { variation } = await Api.get(
                `/api/v1/variations/${item.variationId}`,
              );

              const orderItem = {
                id: item.id,
                imageUrl: item.variationImages[0]?.imageUrl,
                variationId: variation.id,
                sku: variation.sku,
                name: item?.channelData?.title,
                weight: variation?.weight
                  ? parseFloat(variation.weight) * 16
                  : 0,
                weightInPounds:
                  variation?.weight > 1 ? Math.floor(variation.weight) : 0,
                weightInOunces: variation?.weight
                  ? extractOuncesFromPounds(variation.weight)
                  : 0,
                quantity: item.quantity,
                location: item.location,
                manufacturerNo: item.manufacturerNo,
                supplierId: item.supplierId,
              };

              // On the assumption that weight is stored in pounds
              weight += variation.weight
                ? parseFloat(variation.weight) * item.quantity * 16
                : 0;
              totalWeightInOunces += variation.weight
                ? Math.round(extractOuncesFromPounds(variation.weight)) *
                  item.quantity
                : 0;
              totalWeightInPounds +=
                variation.weight && variation.weight > 1
                  ? Math.floor(variation.weight) * item.quantity
                  : 0;

              return orderItem;
            }),
          );
          orderItems.push(items);

          if (
            territories.includes(
              selectedRow.country.replaceAll('.', '').toUpperCase(),
            )
          ) {
            selectedRow.state = selectedRow.country
              .replaceAll('.', '')
              .toUpperCase();
            selectedRow.country = 'US';
          }

          const addressArray = addressStringFormatter(
            selectedRow.addressLine1,
            selectedRow.addressLine2,
            selectedRow.addressLine3,
          );
          // Set a new form to overcome state sync issues
          const newForm = {
            ...blankForm,
            carrierCode:
              Math.round(weight) >= 128 ? 'ups_walleted' : 'stamps_com',
            serviceCode:
              Math.round(weight) >= 128
                ? 'ups_ground'
                : Math.round(weight) >= 16
                ? 'usps_priority_mail'
                : 'usps_first_class_mail',
            selectedServiceName:
              Math.round(weight) >= 128
                ? 'UPS® Ground'
                : Math.round(weight) >= 16
                ? 'USPS Priority Mail - Package'
                : 'USPS First Class Mail - Package',
            shipTo: {
              name: selectedRow.shippingName,
              company: selectedRow.orderNumber.includes('# ')
                ? selectedRow.orderNumber.split('# ')[1]
                : selectedRow.orderNumber,
              street1: addressArray[0],
              street2: addressArray[1],
              street3: addressArray[2],
              city: selectedRow.city,
              state: selectedRow.state,
              postalCode: selectedRow.zipCode,
              country:
                selectedRow.country === 'USA' ? 'US' : selectedRow.country, // Fix shipstations 2 letter requirement
              phone: '',
              residential: true,
            },
            shipFrom: response.shipStationData.integrationData.shipFrom,
            weight: { value: Math.round(weight), units: 'ounces' },
            totalWeightInOunces: totalWeightInOunces,
            totalWeightInPounds: totalWeightInPounds,
            orderCosts: {
              subTotal: selectedRow.orderItems.reduce(
                (accumulator, currentValue) => {
                  return accumulator + currentValue?.supplierSubTotal;
                },
                0,
              ),
              itemPrice: selectedRow.orderItems.reduce(
                (accumulator, currentValue) => {
                  return accumulator + currentValue?.itemPrice;
                },
                0,
              ),
            },
            profit:
              selectedRow.orderItems.reduce((accumulator, currentValue) => {
                return accumulator + currentValue?.itemPrice;
              }, 0) -
              selectedRow.orderItems.reduce((accumulator, currentValue) => {
                return accumulator + currentValue?.supplierSubTotal;
              }, 0),
          };
          formList.push(newForm);
        }
      }
      setOrderItems(orderItems);
      setForm(formList);
      setOpen(true);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(`Error while trying to open print module: ${e}`, {
        variant: 'error',
      });
      setClicked(false);
    }
  };

  const loadCarrierRates = async (form) => {
    const csResponse = await shipStationApi.loadCarrierRates(form);
    setCarrierServiceCodes(csResponse);
  };

  const handleClose = (value) => {
    setClicked(false);
    setOpen(false);
  };

  const handleCancelOpen = () => {
    setCancelOpen(true);
  };

  const handleCancelClose = () => {
    setCancelOpen(false);
  };

  const cancelShipment = async () => {
    const response = await shipStationApi.cancelShipment(selectedRows[0]);

    if (response.success) {
      const { orderUpdate } = response;

      await Api.put(`/api/v1/orders/${selectedRows[0].id}`, orderUpdate);

      enqueueSnackbar('Shipment successfully voided.', {
        variant: 'success',
      });
    } else {
      enqueueSnackbar('Error while trying to void shipment.', {
        variant: 'error',
      });
    }

    setCancelOpen(false);
  };

  return (
    <>
      {open && (
        <PrintLabelModal
          open={open}
          printLabel={printLabel}
          openHandler={handleOpen}
          closeHandler={handleClose}
          defaultForm={form}
          carrierServiceCodes={carrierServiceCodes}
          loadCarrierRates={loadCarrierRates}
          row={selectedRows[0]}
          originalOrderItems={orderItems}
          carrierData={carrierData}
          me={me}
        />
      )}
      {cancelOpen && (
        <Dialog open={cancelOpen} onClose={handleCancelClose}>
          <DialogTitle>{'Are you sure?'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to cancel these shipments? this will void
              the labels in ShipStation and void any currently printed labels.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelClose}>No</Button>
            <Button onClick={cancelShipment}>Yes</Button>
          </DialogActions>
        </Dialog>
      )}

      {enableCamera && (
        <Dialog
          open={enableCamera}
          fullWidth
          style={{ padding: '20px', zIndex: 12 }}
          onClose={() => {
            setEnableCamera(false);
          }}
        >
          <BarcodeScanner
            setEnableCamera={setEnableCamera}
            setUpc={setUpc}
            searchOrderByUpc={searchOrderByUpc}
          />
        </Dialog>
      )}
      <Grid
        item
        container
        xs={12}
        justify="flex-start"
        alignItems="center"
        style={{ marginBottom: 10 }}
      >
        <Grid item xs={12} md={5}>
          {!clicked && (
            <Tooltip title={'Batch Print'}>
              <span>
                <Button
                  disabled={selectedRows?.length < 1}
                  variant="contained"
                  color="primary"
                  onClick={async () => await handleOpen()}
                  className={classes.mobilePrint}
                  size="small"
                >
                  Print {selectedRows.length} label
                  {selectedRows.length > 1 ? 's' : ''}
                </Button>
              </span>
            </Tooltip>
          )}
          {clicked && (
            <Tooltip title={'loading'}>
              <span>
                <Button
                  disabled
                  color="default"
                  variant="contained"
                  style={{ width: 'calc(100%)' }}
                  startIcon={
                    <FontAwesome
                      name="circle-notch"
                      type="fas"
                      form="fa"
                      spin
                    />
                  }
                >
                  Loading
                </Button>
              </span>
            </Tooltip>
          )}
        </Grid>
        <Tooltip title={'Barcode Scanner'}>
          <span>
            <Button
              variant="contained"
              style={{
                width: 'fit-content',
                minHeight: '40px',
                marginRight: 5,
              }}
              color="secondary"
              onClick={async () => initiateCamera()}
            >
              <FontAwesome
                name="barcode"
                form="fa"
                type="fa-lg"
                style={{ border: '1.5px white dashed' }}
              />
            </Button>
          </span>
        </Tooltip>
        <Grid item xs={9} md={4}>
          <TextField
            required
            fullWidth
            variant="outlined"
            label="UPC"
            name="upc"
            ref={upcRef}
            // value={form?.name || ''}
            value={upc || ''}
            onKeyPress={handleKeyPress}
            onChange={(e) => setUpc(e.target.value)}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default BatchPrintButton;
