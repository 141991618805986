import useMagikTime from '../hooks/useMagikTime';

import { columns } from './constants';

import TextField from '@src/Components/common/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1.5),
    width: '100%',
    overflowX: 'scroll',
  },
}));

function TableView() {
  const [magikTimeState, setMagikTimeState] = useMagikTime.useState();
  const classes = useStyles();

  const onRowChange = (rowId: number, key: string, value: any) => {
    const update: Record<string, any> = {};
    const rowIndex = magikTimeState.lostMoneyOrders.findIndex(
      (order) => order.id === rowId,
    );
    update.lostMoneyOrders = magikTimeState.lostMoneyOrders.slice();
    update.lostMoneyOrders[rowIndex] = {
      ...update.lostMoneyOrders[rowIndex],
      [key]: value,
    };
    setMagikTimeState((previous) => ({
      ...previous,
      ...update,
    }));
  };

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow key={'header-columns'}>
            {columns(onRowChange).map((column) => (
              <TableCell
                style={{
                  borderRight: '1px solid rgba(224, 224, 224, 1)',
                  lineHeight: '1rem',
                }}
                key={column.key}
              >
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {magikTimeState.lostMoneyOrders.map((row) => (
            <TableRow key={row.name}>
              {columns(onRowChange).map((column: any) => {
                const valueElem = <>{row[column.key]}</>;
                const inputElem = (
                  <TextField
                    fullWidth
                    label=""
                    value={row[column.key]}
                    style={{ width: 75 }}
                    onChange={(e) =>
                      onRowChange(row.id, column.key, e.currentTarget.value)
                    }
                    disabled={!row.selected}
                  />
                );
                const checkboxElem = (
                  <Checkbox
                    checked={Boolean(row[column.key])}
                    onChange={(e) =>
                      onRowChange(row.id, column.key, e.currentTarget.checked)
                    }
                  />
                );
                const returnElem = column.customComponent ? (
                  <>{column.customComponent(row)}</>
                ) : column.type === 'value' ? (
                  valueElem
                ) : column.type === 'input' ? (
                  inputElem
                ) : (
                  checkboxElem
                );
                return (
                  <TableCell key={column.key + row.id}>{returnElem}</TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableView;
