import { useHistory } from 'react-router-dom';

import queryUtils from '@src/utils/queries';

import {
  Button,
  Grid,
  Tooltip,
  IconButton,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';

import type { Column } from '@src/Components/common/containers/SearchView/types';

export const columns: Column[] = [
  {
    title: 'Image',
    keyName: 'image',
    align: 'center',
    customComponent: function Image(row) {
      return (
        <img
          src={
            row?.image
              ? row.image
              : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
          }
          width={65}
          height={55}
        />
      );
    },
    // customComponent: function ImageRender(row) {
    //   return (
    //     <img
    //       src={
    //         row.image?.[0]?.[0] != null
    //           ? row.image?.[0]?.[0]
    //           : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
    //       }
    //       onError={({ currentTarget }) => {
    //         currentTarget.onerror = null;
    //         currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;
    //       }}
    //       alt={`product image for ${row.name ? row.name : `product ${row.id}`}`}
    //       width={55}
    //       height={55}
    //       style={{ borderRadius: 5 }}
    //     />
    //   );
    // },
  },
  {
    title: 'Brand Name',
    keyName: 'brand',
    sortBy: true,
    align: 'center',
  },
  // {
  //   title: 'Aliases',
  //   keyName: 'brandAliases',
  //   sortBy: false,
  // },
  /* {
    title: 'Category Name',
    keyName: 'categoryName',
    sortBy: true,
  },
  {
    title: 'Parent Category Name',
    keyName: 'parentCategoryName',
    sortBy: true,
  },
  {
    title: 'Amazon ID',
    keyName: 'amazonCategoryId',
    sortBy: true,
  },
  {
    title: 'Ebay ID',
    keyName: 'ebayCategoryId',
    sortBy: true,
  },
  {
    title: 'WooCommerce ID',
    keyName: 'wooCommerceCategoryId',
    sortBy: true,
  },
  {
    title: 'Walmart ID',
    keyName: 'walmartCategoryId',
    sortBy: true,
  },
  {
    title: 'Created At',
    keyName: 'createdAt',
    sortBy: true,
    type: 'date',
  }, */
  {
    title: 'Aliases',
    keyName: 'brandAliases',
    sortBy: false,
    align: 'left',
    customComponent: function BrandAliases(row) {
      return (
        <>
          <p>{row.brandAliases?.map((ba) => ba).join(', ')}</p>
        </>
      );
    },
  },
  {
    title: 'Supplier Discounts',
    keyName: 'brandDiscout',
    sortBy: false,
    align: 'center',
    customComponent: function BrandAliases(row) {
      return (
        <>
          {row?.discountObj?.map(
            (element, index) =>
              element?.discountId > -1 &&
              element?.discountAmount > 0 &&
              element?.supplier && (
                <p key={index}>
                  {element.supplier}:{' '}
                  <span style={{ color: 'green' }}>
                    <b>
                      {element.discountFlat ? '$' : ''} {element.discountAmount}
                      {!element.discountFlat ? '%' : ''}
                    </b>
                  </span>
                </p>
              ),
          )}
        </>
      );
    },
  },
  {
    title: 'MAP',
    keyName: 'isMap',
    sortBy: false,
    align: 'center',
    customComponent: function isMap(row) {
      return <>{row?.isMap === true ? <CheckIcon /> : null}</>;
    },
  },
  {
    title: 'Inventory(OOS)',
    keyName: 'inventory',
    sortBy: false,
    align: 'center',
    customComponent: function inventory(row) {
      return (
        <Grid>
          <Typography>
            {Number(row.brdInventory).toLocaleString()}(
            {Number(row.brdOOS).toLocaleString()})
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: 'Products',
    keyName: 'products',
    align: 'center',
    customComponent: function Products(row) {
      console.log(row, 'created');
      return (
        <Grid>
          <Typography>
            {Number(row.brdProducts).toLocaleString()}
            {/* {Number(row.imported - row.created).toLocaleString()}) */}
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: 'Listings(Inactive)',
    keyName: 'listings',
    align: 'center',
    customComponent: function Listings(row) {
      return (
        <Grid>
          <Typography>
            {Number(row.listTotal).toLocaleString()}(
            {Number(row.listInactive).toLocaleString()})
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: 'Edit',
    keyName: 'edit',
    sortBy: false,
    align: 'center',
    customComponent: function EditBrand(row) {
      const history = useHistory();

      const EditBrand = () => {
        const query = queryUtils.add('id', row.id);
        history.replace({
          search: query,
        });
      };
      return (
        <Grid key={row.id}>
          <Tooltip title={`Edit Brand`}>
            <span>
              <IconButton onClick={() => EditBrand()}>
                <EditTwoToneIcon color="primary" fontSize="default" />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      );
    },
  },
];
