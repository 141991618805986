import { Typography, Grid } from '@material-ui/core';
import { CustomComponentValues } from './types';
import { FontAwesome } from '@src/Components/common';

export const columns = [
  {
    title: 'Name',
    keyName: 'name',
    customComponent: function name({ form }) {
      return (
        <Grid item container xs={12}>
          <Typography
            style={{
              fontWeight: 600,
              fontSize: '25px',
              color: '#424242',
              marginBottom: 10,
            }}
          >
            {form.name}
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: 'Brand',
    keyName: 'brandName',
  },
  {
    title: 'Store',
    keyName: 'storeId',
  },
  {
    title: 'Category',
    keyName: 'category',
    customComponent: function renderCategories({ form }) {
      return (
        <>
          <Grid
            item
            container
            xs={12}
            style={{
              minHeight: 'min-content',
              border: 'lightGrey 1px solid',
              borderRadius: 10,
              padding: 10,
              marginBottom: 10,
            }}
          >
            <Grid xs={5}>
              <Typography style={{ fontSize: '20px' }}>Categories:</Typography>
            </Grid>
            <Grid item container xs={7} alignItems="flex-start">
              {form.grandparentCategory && (
                <Grid item xs={12}>
                  <Typography style={{ fontSize: '20px' }}>
                    {form.grandparentCategory}{' '}
                    <FontAwesome
                      name="arrow-right"
                      type="fal"
                      form="fa"
                      size="xs"
                    />
                  </Typography>
                </Grid>
              )}
              {form.parentCategory && (
                <Grid item xs={12}>
                  <Typography style={{ fontSize: '20px' }}>
                    {form.parentCategory}{' '}
                    <FontAwesome
                      name="arrow-right"
                      type="fal"
                      form="fa"
                      size="xs"
                    />
                  </Typography>
                </Grid>
              )}
              <Grid item xs={form.category ? 12 : 7}>
                <Typography style={{ fontSize: '20px' }}>
                  {form.category ? form.category : 'No Category Associated'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    },
  },
  {
    title: 'Keywords',
    keyName: 'keywords',
    customComponent: function renderKeywords({
      keyValue,
    }: CustomComponentValues) {
      return (
        <>
          <Grid
            item
            container
            xs={12}
            style={{ marginBottom: 10, maxHeight: 'min-content' }}
          >
            <Grid xs={5}>
              <Typography style={{ fontSize: '20px' }}>Keywords:</Typography>
            </Grid>
            <Grid xs={7}>
              {keyValue?.split(',').map((value, index) => {
                return (
                  <Grid key={`product-keyword-${index + 1}`}>
                    <Typography style={{ fontSize: '20px' }}>
                      <li>{value}</li>
                    </Typography>
                  </Grid>
                );
              })}
              {!keyValue && (
                <Typography style={{ fontSize: '20px' }}>
                  No Keywords
                </Typography>
              )}
            </Grid>
          </Grid>
        </>
      );
    },
  },
  {
    title: 'Created At',
    keyName: 'createdAt',
    customComponent: function renderDate({ keyValue }: CustomComponentValues) {
      return (
        <Grid
          item
          container
          xs={12}
          style={{ maxHeight: '45px', marginBottom: 10 }}
        >
          <Grid xs={5}>
            <Typography style={{ fontSize: '20px' }}>Created At: </Typography>
          </Grid>
          <Grid xs={7}>
            <Typography style={{ fontSize: '20px' }}>
              {new Date(keyValue).toISOString().split('T')[0]}
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    title: 'Created By',
    keyName: 'createdBy',
    customComponent: function renderCreatedBy({
      keyValue,
      form,
    }: CustomComponentValues) {
      return (
        <Grid
          item
          container
          xs={12}
          style={{ maxHeight: '45px', marginBottom: 10 }}
        >
          <Grid item container xs={5}>
            <Typography style={{ fontSize: '20px' }}>Created By:</Typography>
          </Grid>
          <Grid item container xs={7}>
            <Typography style={{ fontSize: '20px' }}>
              {keyValue} ({form.createdByAlias ? form.createdByAlias : null})
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    title: 'Updated By',
    keyName: 'updatedBy',
    customComponent: function createdByRender({
      keyValue,
      form,
    }: CustomComponentValues) {
      return (
        <Grid item container xs={12} style={{ marginBottom: 10 }}>
          <Grid xs={5}>
            <Typography style={{ fontSize: '20px' }}>Updated By:</Typography>
          </Grid>
          <Grid xs={7}>
            <Typography style={{ fontSize: '20px' }}>
              {keyValue} ({form.updatedByAlias ? form.updatedByAlias : null})
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
];
