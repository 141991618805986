import { useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Link,
} from '@material-ui/core';
import Api from '@oneAppCore/services/Api';
import FontAwesome from '@src/Components/common/FontAwesome';
import useSearch from '@oneAppCore/one-app/src/Components/common/containers/SearchView/hooks/useSearch';

type Props = {
  poId: number,
  poNumber: number,
  internalRestockData: Array<any>,
}

function ClosePoButton({ poId, poNumber, internalRestockData }: Props) {
  const [open, setOpen] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);

  const { mutate } = useSearch();
  const { enqueueSnackbar } = useSnackbar();

  const closePo = async () => {
    if (internalRestockData.length === 0) {
      closePurchaseOrder(false);
    } else {
      setConfirmCancel(true);
    };
  };

  const closePurchaseOrder = async (cancelItems = false) => {
    setClicked(true);
    try {
      const updateObj = {
        poId,
        internalRestockData: cancelItems ? internalRestockData.map((item) => {
          return {
            internalRestockId: item?.internalRestockId,
            mpnId: item?.mpnId,
            purchased: item?.qty ? item?.qty : 0,
            received: item?.recieved ? item?.recieved : 0,
          };
        }) : [],
      };
      const updateRes = await Api.put(`/api/v1/manufacturer-suppliers-restock/close-po`, updateObj);
      if (updateRes.id) {
        mutate();
        enqueueSnackbar(`Closed #PO ${poNumber}`, {
          variant: 'success',
        });
      };
    } catch (error) {
      enqueueSnackbar(`Error approving listing`, {
        variant: 'error',
      });
    } finally {
      {
        handleClose();
      }
    }
  };

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setClicked(false);
    setConfirmCancel(false);
  };

  return (
    <>
      {!open && (
        <Tooltip title={'Close #PO'}>
          <span>
            <Link
              component="button"
              variant="body2"
              underline='always'
              onClick={async () => handleOpen()}
            >
              Close
            </Link>
          </span>
        </Tooltip>
      )}
      {open && (
        <Tooltip title={'loading'}>
          <span>
            <FontAwesome
              name="circle-notch"
              type="fas"
              form="fa"
              spin
            />
          </span>
        </Tooltip>
      )}
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirm #PO Close</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to close the #PO: <b>{poNumber}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="secondary"
            onClick={() => handleClose()}
          >
            No
          </Button>
          <Button type="submit" disabled={clicked} color="primary" onClick={() => closePo()}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmCancel} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirm #PO Close</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to cancel all pending items?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="secondary"
            onClick={() => closePurchaseOrder(false)}
          >
            No
          </Button>
          <Button type="submit" disabled={clicked} color="primary" onClick={() => closePurchaseOrder(true)}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ClosePoButton;
