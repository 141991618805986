import Grid from '@material-ui/core/Grid';
import { Typography, Chip } from '@material-ui/core';

import EditButton from './EditButton';
import ViewButton from './ViewButton';
import OutOfStockButton from './OutOfStockButton';
import StaticQuantityButton from './StaticQuantityButton';
import NoRestockButton from './NoRestockButton';
import { FontAwesome } from '@src/Components/common';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';
import { getStartLetters } from '@oneAppCore/utils/formatters';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';

import type { Column } from '@src/Components/common/containers/SearchView/types';
import dayjs from 'dayjs';
import { SUPP_UN } from '@oneAppCore/constants/suppliers';

export const columns: Column[] = [
  {
    keyName: 'image',
    title: 'Image',
    align: 'center',
    customComponent: function InventoryImage(row) {
      return (
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <img
              src={
                row.images?.[0]?.imageUrl != null
                  ? row.images?.[0]?.imageUrl
                  : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;
              }}
              alt={`product image for ${row.manufacturerNo}`}
              width={45}
              height={45}
              style={{ borderRadius: 5 }}
            />
          </Grid>
        </Grid>
      );
    },
  },

  {
    keyName: 'supplierName',
    title: 'Supplier',
    sortBy: true,
    align: 'center',
    customComponent: function Suppliers(row) {
      return (
        <Grid item container style={{ justifyContent: 'center' }}>
          {row?.metaData?.shortName || getStartLetters(row?.supplierName, { caps: true })  || SUPP_UN}
        </Grid>
      );
    },
  },
  {
    keyName: 'brand',
    title: 'Brand',
    sortBy: true,
    align: 'center',
  },
  {
    keyName: 'manufacturerNo',
    title: 'MPN',
    sortBy: true,
    align: 'center',
  },
  {
    keyName: 'sku',
    title: 'SKU',
    sortBy: true,
    align: 'center',
  },
  {
    keyName: 'quantity',
    title: 'QTY(Cur / Inb)',
    sortBy: true,
    align: 'center',
    customComponent: function quantity(row) {
      return (
        <>
          <Grid xs={12}>
            {row?.inbound === null ? (
              <Typography>{row?.quantity} / 0</Typography>
            ) : (
              <Typography>
                {row?.quantity} /{' '}
                <span style={{ color: 'darkgreen', fontWeight: 'bold' }}>
                  {' '}
                  {row?.inbound}{' '}
                </span>
              </Typography>
            )}
          </Grid>
        </>
      );
    },
  },
  {
    keyName: 'cost',
    title: 'Cost/(MSRP)',
    sortBy: true,
    align: 'center',
    customComponent: function cost(row) {
      const msrp = row.msrp && row.msrp > 0 ? row.msrp : null;
      const reduceFunc = (max, curr) => {
        if (curr?.discountAmount > max?.discountAmount) {
          return curr;
        } else {
          return max;
        }
      };
      let discounts = [];
      if (row?.discountVariations?.filter((i: any) => i)?.length) {
        discounts = row?.discountVariations?.filter((i: any) => i);
      } else if (row?.discountProducts?.filter((i: any) => i)?.length) {
        discounts = row?.discountProducts?.filter((i: any) => i);
      } else if (row?.discountBrands?.filter((i: any) => i)?.length) {
        discounts = row?.discountBrands?.filter((i: any) => i);
      }
      const discountFunc = (disc) => {
        if (disc.discountType === 'f') {
          return row.cost - disc.discountAmount;
        } else if (disc.discountType === 'p') {
          return Math.ceil(row.cost * ((100 - disc.discountAmount) / 100));
        } else if (disc.discountType === 'd') {
          return disc.discountAmount;
        } else {
          return 0;
        }
      };
      const discountAmount = discounts.length ? discountFunc(discounts[0]) : 0;
      const discount = msrp
        ? Math.ceil(100 - ((discountAmount || row.cost) / msrp) * 100)
        : 0;
      const rend = (
        <>
          <Grid item>
            <Typography>
              {discount > 0
                ? `${formatCentsToDollarsIntl(discountAmount)}`
                : `${formatCentsToDollarsIntl(row.cost)}`}
            </Typography>
          </Grid>
          {discount > 0 ? (
            <Grid item>
              <Typography>{` \n/(${formatCentsToDollarsIntl(
                msrp,
              )})`}</Typography>
            </Grid>
          ) : null}
          {discount > 0 ? (
            <Grid item>
              <Typography>
                <span style={{ color: 'darkgreen' }}>{discount}</span>
                <span style={{ color: 'darkgreen' }}>% off</span>
              </Typography>
            </Grid>
          ) : null}
        </>
      );

      return (
        <Grid container style={{ justifyContent: 'center' }}>
          {rend}
        </Grid>
      );
    },
  },
  {
    keyName: 'map',
    title: 'MAP',
    sortBy: false,
    align: 'center',
    customComponent: function cost(row) {
      const map = row.map && row.map > 0 ? row.map : null;
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          {map ? (
            <Grid item>
              <Typography>{`${formatCentsToDollarsIntl(map)}`}</Typography>
            </Grid>
          ) : null}
        </Grid>
      );
    },
  },
  {
    keyName: 'location',
    title: 'Location',
    sortBy: true,
    align: 'center',
    customComponent: function Location(row) {
      console.log(row);
      return (
        <Grid item container xs={12} style={{ justifyContent: 'center' }}>
          {row?.location !== null ? (
            <Chip
              style={{
                color: '#000000',
                backgroundColor: row?.location?.includes('B BIN', 'B BIn')
                  ? '#C7E2FF'
                  : row?.location?.includes('BIN')
                  ? '#FFB9B9'
                  : row?.location?.includes('Pegboard')
                  ? '#FFBFA4'
                  : row?.location?.includes('Peg Board')
                  ? '#FFBFA4'
                  : row?.location?.includes('ENDCAP')
                  ? '#AFFFA4'
                  : row?.location?.includes('Endcap')
                  ? '#AFFFA4'
                  : row?.location?.includes('Wall')
                  ? '#A4FFE1'
                  : row?.location?.includes('Clip')
                  ? '#B7A4FF'
                  : row?.location?.includes('Sorter')
                  ? '#FFD972'
                  : row?.location?.includes('MAN')
                  ? '#A7E8BD'
                  : row?.location?.includes('Red')
                  ? '#BE3E82'
                  : row?.location?.includes('B Endcap')
                  ? '#D8D4F2'
                  : row?.location?.includes('Backroom')
                  ? '#BC9CB0'
                  : row?.location?.includes('Glass')
                  ? '#81D2C7'
                  : row?.location?.includes('Battery')
                  ? '#C1F7DC'
                  : row?.location?.includes('Tire') && '#FFA4DB',
                minWidth: '90%',
              }}
              label={row.location?.trim() !== '' ? row.location : 'No Location'}
            />
          ) : (
            <></>
          )}
        </Grid>
      );
    },
  },
  {
    keyName: 'outOfStock',
    title: 'Temp OOS',
    sortBy: false,
    align: 'center',
    customComponent: function customSwitch(row) {
      return (
        <OutOfStockButton outOfStock={row.tempOOS} id={row.id} key={row.id} />
      );
    },
  },
  {
    keyName: 'noRestock',
    title: 'No Restock',
    sortBy: false,
    align: 'center',
    customComponent: function customSwitch(row) {
      return (
        <NoRestockButton noRestock={row.noRestock} id={row.id} key={row.id} />
      );
    },
  },
  {
    keyName: 'updatedAt',
    type: 'date',
    sortBy: true,
    align: 'center',
    title: 'Updated At',
    customComponent: function updatedAt(row) {
      return (
        <Grid>
          <Typography>
            {dayjs(row.updatedAt)
              .tz(dayjs.tz.guess())
              .format('MM/DD/YYYY hh:mma z')}
          </Typography>
        </Grid>
      );
    },
  },
  {
    keyName: 'staticQuantity',
    title: 'Static QTY',
    sortBy: false,
    align: 'center',
    customComponent: function customSwitch(row) {
      return (
        <StaticQuantityButton
          staticQuantity={row.staticQ}
          id={row.id}
          key={row.id}
        />
      );
    },
  },
  {
    keyName: 'daysSinceLastSold',
    title: 'Days Aged',
    sortBy: false,
    align: 'center',
  },
  {
    keyName: 'actions',
    title: 'Actions',
    sortBy: false,
    align: 'center',
    customComponent: function CustomEdit(row) {
      // return <EditButton key={row.id} row={row} />;
      return (
        <Grid container xs={12}>
          <Grid item xs={6}>
            <EditButton key={row.id} row={row} />
          </Grid>
          <Grid item xs={6}>
            <ViewButton id={row.id} row={row} />
          </Grid>
        </Grid>
      );
    },
  },
];

export const stockProperties = [
  { label: 'Temp Out of Stock', value: 'quantityTrackingUnavailable' },
  { label: 'Static Quantity', value: 'hasStaticQuantity' },
  { label: 'Restock Ignored', value: 'noRestock' },
  { label: 'Out of Stock', value: 'outOfStock' },
  { label: 'In Stock', value: 'inStock' },
];
