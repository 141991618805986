import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { colorTheme } from '@oneAppCore/constants/channels';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme as createTheme } from '@material-ui/core/styles';
import BarChart from '@src/Components/common/charts/BarChart';

import ReportApi from '@oneAppCore/services/ReportApi';

import { Props } from '../types';
import { unitDateLabel } from '../utilities';
import dateUtils from '@oneAppCore/utils/dates';

function GrossRevenueByDay({ unitsBack, unitType }: Props) {
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [today, setToday] = useState(false);
  const [yesterday, setYesterday] = useState(false);
  const theme = createTheme(colorTheme);

  useEffect(() => {
    (async () => {
      const { newerThan } = dateUtils.getDaysBack({ unitsBack, unitType });
      setData([]);
      const response = await ReportApi.get('GROSS_REVENUE', {
        newerThan,
        page: 1,
        limit: 1000000,
      });

      const today = dayjs().startOf('day');
      const yesterday = today.subtract(1, 'day');

      const rows = response.rows;
      const lastWeek = response.last;

      console.log(lastWeek, 'last week');

      const isToday = rows.some((row) => 'hour' in row);
      setToday(isToday);

      const isYesterday = rows.some((row) =>
        dayjs(row.hour).isSame(yesterday, 'day'),
      );
      setYesterday(isYesterday);

      if (isToday || isYesterday) {
        const hours = Array.from({ length: 24 }, (_, i) => {
          const hour = i % 12 || 12;
          const period = i < 12 ? 'AM' : 'PM';
          return `${hour.toString().padStart(2, '0')}:00 ${period}`;
        });

        const channelsToday = Array.from(
          new Set(rows.map((row) => row['Channel Name'])),
        );

        const channelsLast = Array.from(
          new Set(lastWeek?.map((row) => row['Channel Name'])),
        );

        const orderedRows = channelsToday.map((channel) => ({
          data: hours.map((hour) => {
            const found = rows.find(
              (row) =>
                dayjs(row.hour).format('hh:00 A') === hour &&
                row['Channel Name'] === channel,
            );
            return found ? Number(found.ItemPrice) : 0;
          }),
          label: channel,
          backgroundColor: theme.palette[channel].main,
          hoverOffset: 5,
        }));

        const lineDataLastWeek = channelsLast.map((channel) => ({
          data: hours.map((formattedHour) => {
            const found = lastWeek.find((row) => {
              const rowHour = dayjs(row.hour).format('hh:00 A');
              return (
                rowHour === formattedHour && row['Channel Name'] === channel
              );
            });
            return found ? Number(found.ItemPrice) : 0;
          }),
          label: `${channel} (Last Week)`,
          backgroundColor: theme.palette[channel].dark,
          borderColor: theme.palette[channel].dark,
          borderWidth: 2,
          fill: false,
          type: 'line',
        }));

        setLineData(lineDataLastWeek);
        setLabels(hours);
        setData(orderedRows);
      } else {
        const obj = {};
        const rows = response.rows.reduce((acc, row) => {
          const keyName = dayjs(
            row['Purchase Date'],
            'MM-DD-YYYY HH:mm:ss',
          ).format('MM/DD/YYYY');
          return {
            ...acc,
            [keyName]: acc[keyName]
              ? acc[keyName] + row['Item Price']
              : row['Item Price'],
          };
        }, {});
        const labelRows = Object.keys(rows).reduce(
          (acc, key) => ({
            ...acc,
            [key]: rows[key],
          }),
          {},
        );
        response.rows.forEach((row) => {
          if (!obj[row['Channel Name']]) obj[row['Channel Name']] = [];
          obj[row['Channel Name']].push(row);
        });
        const orderedRows = [];

        Object.keys(obj).forEach((objKey) => {
          const rows = obj[objKey].reduce((acc, row) => {
            const keyName = dayjs(
              row['Purchase Date'],
              'MM-DD-YYYY HH:mm:ss',
            ).format('MM/DD/YYYY');
            return {
              ...acc,
              [keyName]: acc[keyName]
                ? acc[keyName] + row['Item Price']
                : row['Item Price'],
            };
          }, {});
          let arrayOfOrderedRows = [];
          Object.keys(labelRows)
            .filter((name) => name !== 'length')
            .forEach((labelName) => {
              if (!arrayOfOrderedRows[labelName])
                arrayOfOrderedRows[labelName] = 0;
            });
          const reducedRows = Object.keys(labelRows).reduce((acc, key) => {
            let returningReducedObject = {
              ...acc,
              [key]: rows[key],
            };
            if (!rows[key])
              returningReducedObject = {
                ...acc,
                [key]: 0,
              };
            return returningReducedObject;
          }, {});
          arrayOfOrderedRows = Object.keys(reducedRows).map((reducedRow) => {
            return reducedRows[reducedRow];
          });
          const ret = {
            data: arrayOfOrderedRows,
            label: objKey,
            backgroundColor: theme.palette[objKey].main,
            hoverOffset: 5,
          };
          orderedRows.push(ret);
        });

        setData(orderedRows);
        setLabels(Object.keys(labelRows).filter((name) => name !== 'length'));
      }
    })();
  }, [unitsBack]);

  const unitsLabel = `Gross Revenue ${unitDateLabel({
    unitsBack,
    unitType,
  })}`;

  return (
    <Grid
      container
      justify="center"
      direction="column"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h5">{unitsLabel}</Typography>
      </Grid>
      <Grid item style={{ width: '80%' }}>
        <BarChart
          labels={labels}
          data={today || yesterday ? [...lineData, ...data] : data}
          stacked
          title={unitsLabel}
          plugins={{
            legend: {
              display: true,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default GrossRevenueByDay;
