import { useState } from 'react';

import Switch from '@material-ui/core/Switch';
import { Tooltip } from '@material-ui/core';

import type { Props } from './type';

import ManufacturerSuppliersApi from '@oneAppCore/services/ManufacturerSuppliersApi';
import { useSnackbar } from 'notistack';

const NoRestockButton = ({ id, noRestock }: Props) => {
  const [checked, setChecked] = useState(noRestock);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = async (event) => {
    setChecked(event.target.checked);
    try {
      await ManufacturerSuppliersApi.put(parseInt(id), {
        noRestock: event.target.checked,
      });
    } catch (err) {
      setChecked(!event.target.checked);
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };
  return (
    <>
      <Tooltip title="No Restock">
        <Switch
          onChange={handleChange}
          checked={checked}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Tooltip>
    </>
  );
};

export default NoRestockButton;
