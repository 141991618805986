import { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { SwipeableDrawer, Grid, Typography, Divider } from '@material-ui/core';
import type { DrawerProps } from './types';
import { columns } from './constants';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import { formatCentsToDollars } from '@oneAppCore/utils/currency';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
      width: '475px',
    },
    [theme.breakpoints.down('md')]: {
      width: '350px',
    },
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
  },
  title: { paddingTop: theme.spacing(1) },
  root: {
    display: 'flex',
    flexBasis: 0,
    margin: theme.spacing(3, 0),
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

export default function ListingDrawer({
  isDrawerOpen,
  setDrawerOpen,
  row,
  mobile,
}: DrawerProps) {
  const [item, setItem] = useState(0);
  const classes = useStyles({ mobile });

  return (
    <>
      <SwipeableDrawer
        open={isDrawerOpen}
        onClose={() => setDrawerOpen()}
        onOpen={() => null}
        classes={{ paper: classes.drawerPaper }}
        anchor="right"
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              align="center"
              variant="h5"
              style={{ marginBottom: 15, fontWeight: 500 }}
            >
              {row.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} justify="center" style={{ flexBasis: 0 }}>
          {row.listingImages?.[0] ? (
            <img
              style={{
                height: 100,
                width: 100,
                marginTop: 20,
                marginBottom: 20,
              }}
              src={row.listingImages?.[0]}
            />
          ) : (
            <Grid>
              <img
                height={100}
                width={100}
                src={`https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`}
                alt="placeholder image for product image"
              />
            </Grid>
          )}
        </Grid>
        {columns.map((column) => {
          if (column.customComponent) {
            return (
              <Grid item key={row.id} style={{ marginTop: 10 }}>
                {column.customComponent(row)}
              </Grid>
            );
          }
          return (
            <>
              <Grid key={column.key} container style={{ marginTop: 5 }}>
                <Grid key={`${column.key}-title`} item xs={6} md={6} lg={6}>
                  <Typography
                    style={{ fontWeight: 600, fontSize: '1.15rem' }}
                    key={column.id}
                  >
                    {column.title}
                  </Typography>
                </Grid>
                <Grid key={`${column.key}-value`} item xs={6} md={6} lg={6}>
                  <Typography style={{ fontSize: '1.15rem' }}>
                    {column.key === 'manufacturerNo'
                      ? row[column.key][0]
                      : column.key === 'price'
                      ? formatCentsToDollars(row[column.key])
                      : row[column.key]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item key={`${column.key}-div-parent`}>
                {column.key === 'suppliers' || column.key === 'price' ? null : (
                  <Divider
                    key={`${column.key}-div`}
                    className={classes.divider}
                  />
                )}
              </Grid>
            </>
          );
        })}
      </SwipeableDrawer>
    </>
  );
}
