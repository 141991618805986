import { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DonutChart from '@src/Components/common/charts/DonutChart';
import dateUtils from '@oneAppCore/utils/dates';

import dayjs from 'dayjs';
import ReportApi from '@oneAppCore/services/ReportApi';
import { AMAZON, colorTheme, EBAY, WALMART, WOO_COMMERCE } from '@oneAppCore/constants/channels'
import onlyUnique from '@oneAppCore/server/utils/arrayUnique';

import { Props } from '../types';

function OrdersByChannelChart({ unitsBack, unitType }: Props) {
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);

  const colors = [
    colorTheme.palette[AMAZON].main,
    colorTheme.palette[AMAZON].dark,
    colorTheme.palette[EBAY].main,
    colorTheme.palette[WALMART].main,
    colorTheme.palette[WOO_COMMERCE].main,
  ];

  useEffect(() => {
    (async () => {
      const { newerThan } = dateUtils.getDaysBack({ unitsBack, unitType });

      const response = await ReportApi.get('ORDERS_BY_CHANNEL', {
        newerThan,
        page: 1,
        limit: 10000,
      });
      const labelRows = response.rows.map(r => r['Name']).filter(onlyUnique);
      const obj = {};
      response.rows.forEach(row => {
        if (!obj[row['Name']]) obj[row['Name']] = 0;
        obj[row['Name']] ++;
      });
      const data = labelRows.sort().map(label => {
        return obj[label];
      })
      setData(data);
      setLabels(labelRows.sort());
    })();
  }, [unitsBack]);

  return (
    <Grid
      container
      direction="column"
      spacing={2}
    >
      <Grid
        alignItems="center"
        justify="center"
        item
        container
      >
        <Typography variant="h5">Orders By Channel</Typography>
      </Grid>
      <Grid item xs={12} >
        <DonutChart
          labels={labels}
          data={data}
          title="Placed By"
          colors={colors}
          plugins={{
            legend: {
              display: true,
              position: 'right',
              labels: {
                usePointStyle: true,
              },
            }
          }}
        />
      </Grid>
    </Grid>
  );
}

export default OrdersByChannelChart;
