import { useMemo, useState } from 'react';

import { columns } from './constants';

import Grid from '@material-ui/core/Grid';

import SearchView from '@src/Components/common/containers/SearchView';

function BrandAging() {
  return (
    <Grid item container justify="center" alignItems="center">
      <SearchView
        url="api/v1/brands/search"
        columns={columns}
        filters={[{ type: 'string', name: 'Brand', keyName: 'brand' }]}
      />
    </Grid>
  );
}

export default BrandAging;
