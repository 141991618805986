import Grid from '@material-ui/core/Grid';
import NumberBlock from './NumberBlock';

import { ChannelReportProp } from './types';

function ChannelReports(props: ChannelReportProp) {
  const { trackNumbers } = props;
  return (
    <Grid item container spacing={1}>
      <NumberBlock
        value={
          trackNumbers?.manSupTotal
            ? (
                (parseInt(trackNumbers?.outOfStock) /
                  parseInt(trackNumbers?.manSupTotal)) *
                100
              ).toFixed(2) + '%'
            : null
        }
        title="Difference"
        description="Difference between out of stock and the total supplier count"
      />
      <NumberBlock
        value={Number(trackNumbers?.outOfStock).toLocaleString()}
        title="Out of Stock"
        description="Numbers of items that are out of stock"
      />
      <NumberBlock
        value={Number(trackNumbers?.inStock).toLocaleString()}
        title="In Stock"
        description="Numbers of items that are in stock"
      />
      <NumberBlock
        value={Number(trackNumbers?.tempOutOfStock).toLocaleString()}
        title="Temp Out of Stock"
        description="Numbers of items that are temporarily out of stock"
      />
      <NumberBlock
        value={Number(trackNumbers?.staticQuantity).toLocaleString()}
        title="Static Quantity"
        description="Numbers of items with a static quantity"
      />
    </Grid>
  );
}

export default ChannelReports;
