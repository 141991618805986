import React from 'react';

import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Props } from './types';


const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
  },
}));

export default function BackdropDisplay({ open }: Props) {
  const classes = useStyles();
  return (
    <Backdrop open={open} className={classes.backdrop}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
}
