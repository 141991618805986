import dayjs from 'dayjs';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getColor } from '@src/utils/colors';
import numberUtils from '@oneAppCore/utils/numbers';
import { COST_LAW, PRICE_TOO_LOW, SCENARIO_MAP_LABELS } from '@oneAppCore/constants/laws';
import ViewDrawerButton from './ViewDrawerButton';
import ApproveButton from './ApproveButton';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Grid,
  TextField,
  InputAdornment,
  Typography,
  ListItem,
  Tooltip,
  Button,
  Link,
  TableBody,
  Chip,
} from '@material-ui/core';
import type {
  Column,
  ExportOption,
} from '@src/Components/common/containers/SearchView/types';
import FontAwesome from '@src/Components/common/FontAwesome';
import { dateFormat } from '@oneAppCore/constants/dates';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import {
  ACTIVE,
  APPROVED,
  CREATED,
  DRAFT,
  EDIT,
  INACTIVE,
  PENDING,
  REJECTED,
} from '@oneAppCore/constants/listings';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';
import { BOOTSTRAP, GREENS, ORANGES, PURPLES, REDS } from '@oneAppCore/constants/colors';
import {
  AMAZON,
  AMAZON_ASIN_LINK,
  WALMART,
  WALMART_PRODUCT_LINK,
  EBAY,
  EBAY_PRODUCT_LINK,
  channelIds,
} from '@oneAppCore/constants/channels';
import { RMATV, WPS, AD, SUPP_UN } from '@oneAppCore/constants/suppliers';
import { formatCentsToDollars } from '@src/utils/currency';
import { capitalize, getStartLetters } from '@oneAppCore/utils/formatters';
import { COMPLETED } from '@oneAppCore/constants/orders';
import { lowerCase, startCase } from 'lodash';
import { listingChangeStatusColors } from '@src/utils/listings';
// import {} from '@oneAppCore/constants/laws';listingChangeStatusColors

export const columns = (
  suppliers,
  functions,
): Column[] => [
    {
      keyName: 'id',
      title: 'Id',
      sortBy: true,
    },
    {
      keyName: 'status',
      title: 'Status',
      sortBy: true,
      customComponent: function law(row) {
        const colors = listingChangeStatusColors(row.status)
        return <>
          <Grid container item xs={12} style={{ minWidth: '140px' }} justifyContent='center'>
            <Chip
              style={{
                backgroundColor: colors.bg,
                color: colors.text,
                width: '75%',
                height: '100%',
              }}
              label={startCase(lowerCase(row.status))}
            />
          </Grid>
          {/* <Grid>
            <p>{capitalize(row.status)}</p>
          </Grid> */}
        </>;
      },
    },
    // {
    //   keyName: 'type',
    //   title: 'type',
    // },
    // {
    //   keyName: 'approved',
    //   title: 'approved',
    // },
    // {
    //   keyName: 'storeListingId',
    //   title: 'storeListingId',
    // },
    {
      keyName: 'listing',
      title: 'Listing',
      customComponent: function listing(row) {
        let href = '';
        if (row?.channelProductId) {
          if (row?.channelId === channelIds[AMAZON]) {
            href = AMAZON_ASIN_LINK(row?.channelProductId);
          } else if (row?.channelId === channelIds[WALMART]) {
            href = WALMART_PRODUCT_LINK(row?.channelProductId);
          } else if (row?.channelId === channelIds[EBAY]) {
            href = EBAY_PRODUCT_LINK(row?.channelProductId);
          }
        }
        if (row.id === 4108) {
          console.log(row?.channelProductId);
          console.log(row?.channelId);
          console.log(href);
        }
        const text = row?.listingSku || 'No SKU';
        const link = (
          <Link href={href} target="_blank">
            {text}
          </Link>
        );
        return <>
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 5,
            }}
          >
            <img
              width={35}
              height={35}
              style={{ borderRadius: 10 }}
              src={
                row.icon != null
                  ? row.icon
                  : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
              }
            />
            {href !== '' ? link : text}
          </Grid>
          {/* <Grid item xs={12}>{row?.storeName} {row?.channelName}</Grid> */}
        </>;
      }
    },
    {
      keyName: 'values.scenario',
      title: 'Scenario',
      customComponent: function scenario(row) {
        const label = row?.newValues?.scenario ? SCENARIO_MAP_LABELS[row?.newValues?.scenario] : '';
        return <>
          <Grid>
            <span>{label}</span>
          </Grid>
        </>;
      }
    },
    {
      keyName: 'values.prices',
      title: 'Prices (Prev)',
      customComponent: function price(row) {
        if (row.id === 4999) console.log(row?.newValues);
        const newPrice = row?.newValues?.price ? row?.newValues?.price : 0;
        const oldPrice = row?.oldValues?.price ? row?.oldValues?.price : 0;
        const newMin = row?.newValues?.minPrice ? row?.newValues?.minPrice : 0;
        const oldMin = row?.oldValues?.minPrice ? row?.oldValues?.minPrice : 0;
        const newMax = row?.newValues?.maxPrice ? row?.newValues?.maxPrice : 0;
        const oldMax = row?.oldValues?.maxPrice ? row?.oldValues?.maxPrice : 0;
        return <>
          <Grid container xs={12} style={{ maxWidth: '220px' }}>
            <Grid container item xs={12} justifyContent='flex-start'>
              <Grid item xs={4} sm={3}>Price</Grid>
              <Grid item xs={4} sm={3}>{formatCentsToDollars(newPrice)}</Grid>
              <Grid item xs={4} sm={3}>({formatCentsToDollars(oldPrice)})</Grid>
            </Grid>
            {/* make min and max a hover? */}
            <Grid container item xs={12} justifyContent='flex-start'>
              <Grid item xs={4} sm={3}>Min</Grid>
              <Grid item xs={4} sm={3}>{formatCentsToDollars(newMin)}</Grid>
              <Grid item xs={4} sm={3}>({formatCentsToDollars(oldMin)})</Grid>
            </Grid>
            <Grid container item xs={12} justifyContent='flex-start'>
              <Grid item xs={4} sm={3}>Max</Grid>
              <Grid item xs={4} sm={3}>{formatCentsToDollars(newMax)}</Grid>
              <Grid item xs={4} sm={3}>({formatCentsToDollars(oldMax)})</Grid>
            </Grid>
          </Grid>
        </>;
      }
    },
    {
      keyName: 'values.profits',
      title: 'Profit (Prev)',
      customComponent: function profit(row) {
        const { newValues, oldValues } = row;
        const newProfPercCalc = newValues?.revenue ? ((newValues?.profit / newValues?.revenue) * 100).toFixed(3) : -100;
        const oldProfPercCalc = oldValues?.revenue ? ((oldValues?.profit / oldValues?.revenue) * 100).toFixed(3) : -100;
        const newProf = newValues?.profit ? newValues?.profit : 0;
        const oldProf = oldValues?.profit ? oldValues?.profit : 0;
        const newProfPerc = newValues?.profPercent ? newValues?.profPercent : newProfPercCalc;
        const oldProfPerc = oldValues?.profPercent ? oldValues?.profPercent : oldProfPercCalc;
        return <>
          <Grid container xs={12} style={{ maxWidth: '210px' }}>
            <Grid container item xs={12} justifyContent='flex-start'>
              <Grid item xs={4} sm={3}>Profit</Grid>
              <Grid item xs={4} sm={3}>{formatCentsToDollars(newProf)}</Grid>
              <Grid item xs={4} sm={3}>({formatCentsToDollars(oldProf)})</Grid>
            </Grid>
            <Grid container item xs={12} justifyContent='flex-start'>
              <Grid item xs={4} sm={3}>Percent</Grid>
              <Grid item xs={4} sm={3}>{newProfPerc}%</Grid>
              <Grid item xs={4} sm={3}>({oldProfPerc}%)</Grid>
            </Grid>
          </Grid>
        </>;
      }
    },
    {
      keyName: 'values.suppliers',
      title: 'Costs (Prev)',
      customComponent: function supplier(row) {
        const { newValues, oldValues, suppliers: rowSupps } = row;
        const newSuppId = newValues?.supplierId ? newValues?.supplierId : 0;
        const oldSuppId = oldValues?.supplierId ? oldValues?.supplierId : 0;
        if (row.id === 5057) {
          console.log('rowSupps', rowSupps);
          console.log('newValues', newValues);
          console.log('oldValues', oldValues);
          console.log('scenario', newValues?.scenario, PRICE_TOO_LOW);
        }
        const newSuppPick = rowSupps?.find((s) => s.supplierId === newSuppId)
        const oldSuppPick = rowSupps?.find((s) => s.supplierId === oldSuppId)
        const newSupp = suppliers?.find((s) => s.id === newSuppId);
        const oldSupp = suppliers?.find((s) => s.id === oldSuppId);
        const newSuppName = newSupp?.metaData?.shortName || getStartLetters(newSupp?.name, { caps: true }) || newSuppId;
        const oldSuppName = oldSupp?.metaData?.shortName || getStartLetters(oldSupp?.name, { caps: true }) || oldSuppId;
        return <>
          <Grid container xs={12} style={{ maxWidth: '200px' }}>
            <Grid container item xs={12} justifyContent='flex-start'>
              <Grid item xs={4} sm={3}>Supplier</Grid>
              <Grid item xs={4} sm={3}>{(newSuppName)}</Grid>
              <Grid item xs={4} sm={3}>({(oldSuppName)})</Grid>
            </Grid>
            <Grid container item xs={12} justifyContent='flex-start'>
              <Grid item xs={4} sm={3}>Cost</Grid>
              <Grid item xs={4} sm={3}>{formatCentsToDollars((newSuppPick?.cost) + newSuppPick?.estimatedShipping)}</Grid>
              <Grid item xs={4} sm={3}>({formatCentsToDollars((newValues?.scenario && newValues?.scenario === PRICE_TOO_LOW ? oldSuppPick?.oldCost : oldSuppPick?.cost) + oldSuppPick?.estimatedShipping)})</Grid>
            </Grid>
          </Grid>
        </>;
      }
    },
    {
      keyName: 'actions',
      title: 'Actions',
      customComponent: function ActionButtons(row) {

        let aprv = (<ApproveButton row={row} modalType={CREATED} />);
        let deny = (null);
        let edit = (null);
        switch (row.status) {
          case COMPLETED: {
            aprv = <ApproveButton row={row} disabled />;
            deny = <ApproveButton row={row} modalType={REJECTED} disabled />;
            edit = <ApproveButton row={row} modalType={EDIT} disabled />;
            break;
          }
          // case REJECTED: {
          //   // aprv = <ApproveButton row={row} modalType={CREATED} />;
          //   // deny = null;
          //   // deny = <ApproveButton row={row} modalType={REJECTED} disabled />;
          //   break;
          // }
          case PENDING: {
            aprv = <ApproveButton row={row} disabled />;
            deny = <ApproveButton row={row} modalType={REJECTED} disabled />;
            edit = <ApproveButton row={row} modalType={EDIT} disabled />;
            break;
          }
          // case APPROVED: {
          //   // aprv = <ApproveButton row={row} modalType={CREATED} />;
          //   // deny = null;
          //   // deny = <ApproveButton row={row} modalType={REJECTED} disabled />;
          //   break;
          // }
          case CREATED: {
            aprv = (<ApproveButton row={row} />)
            deny = (<ApproveButton row={row} modalType={REJECTED} />);
            edit = <ApproveButton row={row} modalType={EDIT} />;
          }
        }
        return (
          <Grid container xs={12}>
            <Grid item xs={4}>
              {aprv}
            </Grid>
            <Grid item xs={4}>
              {deny}
            </Grid>
            <Grid item xs={4}>
              {edit}
            </Grid>
          </Grid >
        );
      },
    },
  ];
