import { columns } from './constants';

import { Grid } from '@material-ui/core';
import SearchView from '@src/Components/common/containers/SearchView';

import CompanyCreateForm from './CompanyCreateForm';

function CompaniesSearch() {
  return (
    <Grid item container justify="center" alignItems="center" spacing={1}>
      <SearchView
        url="/api/v1/companies/search"
        selectTypes={['createCompany']}
        columns={columns}
        filters={[
          {
            type: 'string',
            name: 'Name',
            keyName: 'name',
          },
        ]}
      />
    </Grid>
  );
}

export default CompaniesSearch;
