import type { Column } from '@src/Components/common/containers/SearchView/types';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  Button,
  createChainedFunction,
} from '@material-ui/core';
import { ReactElement, useState } from 'react';
import { Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { formatCentsToDollars } from '@oneAppCore/utils/currency';

export const orderColumns = (
  handleChange,
  type,
  poNumbers,
  save,
  tableRowsState,
): Column[] => [
  {
    title: 'Transaction Date',
    keyName: 'transactionDate',
    sortBy: true,
    align: 'center',
    customComponent: function Transaction(row): ReactElement {
      return (
        <Grid container justify="center">
          <Typography>
            {dayjs(row.transactionDate).format('MM/DD/YYYY')}
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: 'Description',
    keyName: 'description',
    sortBy: false,
    align: 'left',
  },
  {
    title: 'CC#',
    keyName: 'lastDigits',
    sortBy: false,
    align: 'left',
  },
  {
    title: 'Amount',
    keyName: 'debit',
    sortBy: true,
    align: 'left',
    customComponent: function ChargeAmount(row) {
      return (
        <Grid container justify="center">
          {formatCentsToDollars(row.debit)}
        </Grid>
      );
    },
  },
  {
    title: 'Order/PO #',
    keyName: 'matchNo',
    sortBy: false,
    align: 'left',
    customComponent: function Input(row, functions) {
      const { id, matchType, matchNo } = row;
      const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          save(id, 'matchNo', e.target.value);
        }
      };
      if (matchNo !== null && matchType !== null) {
        return <Typography>{matchNo}</Typography>;
      } else {
        if (matchType === 'inventory' && matchNo === null) {
          return (
            <Grid container item xs={12}>
              {/* If matchNo is null and matchType is 'inventory', show the Select component */}
              <Select
                variant="outlined"
                style={{ maxHeight: 35, width: '100%' }}
                onChange={(e) => save(id, 'matchNo', `${e.target.value}`)} // Save the selected value
                value={row.matchNo || 'x'}
              >
                <MenuItem value="x">Select PO</MenuItem>
                {poNumbers.map((po: any) => (
                  <MenuItem key={po.purchaseOrderNo} value={po.purchaseOrderNo}>
                    PO# {po.purchaseOrderNo} - $
                    {formatCentsToDollars(po.purchaseOrderCost)}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          );
        }
        if (matchType === 'dropship' && matchNo === null) {
          return (
            <Grid container>
              {/* For other cases, show the TextField component */}
              <TextField
                onKeyDown={handleKeyPress} // Update the matchNo value
                variant="outlined"
                size="small"
                label="Order Number"
              />
            </Grid>
          );
        } else if (matchType !== null) {
          return (
            <Grid container>
              <Typography>{matchNo}X</Typography>
            </Grid>
          );
        } else {
          return (
            <Grid container>
              {/* For other cases, show the TextField component */}
              <TextField
                onChange={(e) => save(id, 'matchNo', e.target.value)} // Update the matchNo value
                variant="outlined"
                size="small"
              />
            </Grid>
          );
        }
      }
    },
  },
  {
    title: 'Cost',
    keyName: 'cost',
    sortBy: true,
    align: 'left',
    customComponent: function Cost(row) {
      if (row.cost !== null) {
        return (
          <Grid container justify="center">
            {formatCentsToDollars(row.cost)}
          </Grid>
        );
      } else {
        return <></>;
      }
    },
  },
  {
    title: 'Supplier',
    keyName: 'supplier',
    sortBy: true,
    align: 'left',
  },
  {
    title: 'Purchase Date',
    keyName: 'purchaseDate',
    sortBy: true,
    align: 'left',
    customComponent: function PurchaseDate(row): ReactElement {
      if (row.purchaseDate !== null) {
        return (
          <Grid container justify="center">
            <Typography>
              {dayjs(row.purchaseDate).format('MM/DD/YYYY')}
            </Typography>
          </Grid>
        );
      } else {
        return <></>;
      }
    },
  },
  {
    title: 'Type',
    keyName: 'chargeType',
    customComponent: function TypeSelect(row, functions) {
      const { id: id, matchType: type } = row;
      const changedRows = row;
      if (row.matchType === null) {
        return (
          <Grid
            container
            justify="center"
            style={{ justifyContent: 'flex-end' }}
          >
            <Select
              variant="outlined"
              onChange={(e) => {
                // handleChange(e);
                // const item = changedRows;
                save(id, 'matchType', e.target.value);
              }}
              style={{ maxHeight: 25, marginRight: 15 }}
              defaultValue=""
            >
              <MenuItem value={'dropship'}>Dropship</MenuItem>
              <MenuItem value={'inventory'}>Inventory</MenuItem>
              <MenuItem value={'shipping'}>Shipping</MenuItem>
              <MenuItem disabled value={'shipping'}>
                Returns
              </MenuItem>
              <MenuItem value={'misc'}>Misc.</MenuItem>
            </Select>
          </Grid>
        );
      } else {
        return (
          <Grid
            container
            justify="center"
            style={{ justifyContent: 'flex-end' }}
          >
            <Select
              variant="outlined"
              onChange={(e) => {
                // handleChange(e);
                // const item = changedRows;
                save(id, 'matchType', e.target.value);
              }}
              style={{ maxHeight: 25, marginRight: 15 }}
              value={row.matchType}
            >
              <MenuItem value={'dropship'}>Dropship</MenuItem>
              <MenuItem value={'inventory'}>Inventory</MenuItem>
              <MenuItem value={'shipping'}>Shipping</MenuItem>
              <MenuItem disabled value={'shipping'}>
                Returns
              </MenuItem>
              <MenuItem value={'misc'}>Misc.</MenuItem>
            </Select>
          </Grid>
        );
      }
    },
  },
];
