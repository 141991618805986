import { memo } from 'react';
import {
  TableCell,
} from '@material-ui/core';
import type { Props } from './types';

const SearchRow = ({
  row,
  rIndex,
  column,
  cIndex,
  classes,
  updateRows
}: Props) => {
  return (
    <TableCell
      key={`${column.keyName}-${cIndex}-${row.id}`}
      align={
        column.keyName === 'actions'
          ? 'center'
          : column.align
            ? column.align
            : 'left'
      }
      style={{
        width:
          column.keyName === 'thumbnail' || column.keyName === 'select'
            ? '50px'
            : 'auto',
        maxWidth: column.keyName === 'name' ? '120px' : '100%',
        padding: '6px, 0px, 0px, !important',
        borderBottom: 'none',
        maxHeight: '10px',
      }}
      className={classes.cell}
    >
      {column.customComponent
        ? column.customComponent(row, rIndex, updateRows)
        : row[column.keyName]}
    </TableCell>
  );
};

export default memo(SearchRow);
