import { memo, useState, useEffect, useMemo, useCallback } from 'react';
import useSearch from '../../hooks/useSearch';
import { makeStyles } from '@material-ui/core/styles';

import {
  TableBody as MuiTableBody,
  TableCell,
  TableRow,
  Checkbox,
  Divider,
} from '@material-ui/core';
import type { Props } from './types';
import SearchRow from './SearchRow';
import { TRANSPARENTPURPLES } from '@oneAppCore/constants/colors';

const useStyles = makeStyles((theme) => ({
  'bg-issue': {
    backgroundColor: 'rgba(207,131,132, .05) !important',
  },
  'bg-rejeccted': {
    backgroundColor: 'rgba(207,131,132, .1) !important',
  },
  'bg-rejected': {
    backgroundColor: '#CF83843F !important',
  },
  'bg-inactive': {
    backgroundColor: 'rgba(207,131,132, .1) !important',
  },
  'bg-inbound-inactive': {
    backgroundColor: 'rgba(207,131,132, .2) !important',
  },
  'bg-active': {
    backgroundColor: 'rgba(97,185,137, .1) !important',
  },
  'bg-inbound-active': {
    backgroundColor: 'rgba(97,185,137, .2) !important',
  },
  'bg-unassigned': {
    // backgroundColor: 'rgba(141,144,148, .05) !important',
  },
  'bg-placed': {
    backgroundColor: 'rgba(248,161,79, .05) !important',
  },
  'bg-tracked': {
    backgroundColor: 'rgba(97,185,137, .05) !important',
  },
  'bg-completed': {
    backgroundColor: 'rgba(97,185,137, .1) !important',
  },
  'bg-black': {
    backgroundColor: 'rgba(75,74,74, .05) !important',
  },
  'bg-purple': {
    backgroundColor: 'rgba(121,112,220, .05) !important',
  },
  'bg-pending': {
    backgroundColor: `${TRANSPARENTPURPLES.light} !important`,
  },
  'bg-blue': {
    backgroundColor: 'rgba(7,204,227, .1) !important',
  },
  'bg-approved': {
    backgroundColor: 'rgba(7,204,227, .1) !important',
  },
  'bg-misc': {
    backgroundColor: 'rgba(97,185,137, .1) !important',
  },
  'bg-dropship': {
    backgroundColor: 'rgba(97,185,137, .1) !important',
  },
  'bg-inventory': {
    backgroundColor: 'rgba(97,185,137, .1) !important',
  },
  'bg-shipping': {
    backgroundColor: 'rgba(97,185,137, .1) !important',
  },
  'bg-noNumber': {
    backgroundColor: 'rgba(245,173,5, .1) !important',
  },
  'bg-null': {
    backgroundColor: 'rgba(207,131,132, .1) !important',
  },
  oddRow: {
    backgroundColor: '#FFFFFF',
  },
  evenRow: {
    backgroundColor: '#EEF2F6',
  },
  cell: {
    borderRight: '1px solid #dddddd',
    paddingRight: '5px',
    paddingLeft: '5px',
    paddingTop: 2,
    paddingBottom: 1,
  },
}));

function TableBody({
  columns,
  select,
  selectRow,
  deselectRow,
  selectedRows,
  swrOptions = {
    revalidateOnFocus: true,
    focusThrottleInterval: 3500,
  },
}: Props) {
  const classes = useStyles();
  const { data } = useSearch(
    swrOptions.revalidateOnFocus,
    false,
    swrOptions.focusThrottleInterval,
  );
  // const rows = data?.rows || [];
  const [rowState, setRowState] = useState(data?.rows || []);

  useEffect(() => {
    setRowState(data?.rows);
  }, [data?.rows]);

  // using useCallback to store the memory reference of update function.
  // ! currently when user checks the box and tries change input field in a column,
  // ! js will have new memory address of selectedRows, hence new memory address for this function as well, 
  // ! so all rows will rerender. solution would be to use selectedRows as global state instead of passing in the props,
  // ! update the selected row directly in the SearchRow component.
  const updateRows = useCallback((updatedRow, rowIndex) => {
    setRowState(prevRows => {
      const allRows = [...prevRows];
      allRows[rowIndex] = updatedRow;
      return allRows;
    });

    const selectedIndex = selectedRows.findIndex((findRow) => findRow.id === updatedRow.id);
    if (selectedIndex > -1) {
      deselectRow(updatedRow);
      selectRow(updatedRow);
    }
  }, [selectedRows]);

  const bgClass = (row) => {
    let ret = '';
    if (row.orderStatus) {
      ret = classes[`bg-${row.orderStatus?.toLowerCase()}`];
    } else if (row.status) {
      ret = classes[`bg-${row.status?.toLowerCase()}`];
    } else if (row?.matchType && row?.matchNo) {
      ret = classes[`bg-${row?.matchType?.toLowerCase()}`];
    } else if (row?.matchType === 'inventory' && row?.matchNo === null) {
      ret = classes[`bg-noNumber`];
    } else if (row?.matchType === 'dropship' && row?.matchNo === null) {
      ret = classes[`bg-noNumber`];
    } else if (row?.matchType === 'misc') {
      ret = classes[`bg-${row?.matchType?.toLowerCase()}`];
    } else if (row?.matchType === 'shipping') {
      ret = classes[`bg-${row?.matchType?.toLowerCase()}`];
    } else if (row?.matchType === null) {
      if (row?.userStatus === 'active') {
        ret = classes[`bg-active`];
      } else {
        ret = classes[`bg-${row.userStatus?.toLowerCase()}`];
      }
    } else if (row?.userStatus === 'active') {
      ret = classes[`bg-active`];
    } else if (row?.userStatus) {
      ret = classes[`bg-null`];
    } else if (row?.inboundStatus == 'complete') {
      ret = classes['bg-inbound-active'];
    } else if (row?.inboundStatus == 'inbound') {
      ret = classes['bg-noNumber'];
    } else if (row?.inboundStatus == 'due') {
      ret = classes['bg-inbound-inactive'];
    } else if (row?.inboundStatus == 'extra') {
      ret = classes['bg-blue'];
    }
    return ret;
  }

  return (
    <MuiTableBody>
      {rowState.map((row, rIndex) => (
        <TableRow
          key={`row-${row.id}`}
          style={{ minHeight: '300px', overflow: 'auto' }}
          className={`${rIndex % 2 === 0 ? classes.evenRow : classes.oddRow} ${bgClass(row)}`}
        >
          {select && (
            <TableCell key={`select-box-row-${row.id}`}>
              <Checkbox
                onChange={() => {
                  if (selectedRows.find((findRow) => findRow.id === row.id)) {
                    deselectRow(row);
                  } else {
                    selectRow(row);
                  }
                }}
                checked={
                  selectedRows.find((findRow) => findRow.id === row.id)
                    ? true
                    : false
                }
              />
            </TableCell>
          )}
          {columns.map((column, cIndex) => (
            <SearchRow
              key={`row-${row.id}-${cIndex}`}
              row={row}
              rIndex={rIndex}
              column={column}
              cIndex={cIndex}
              classes={classes}
              updateRows={updateRows}
            />
          ))}
        </TableRow>
      ))}
    </MuiTableBody>
  );
}

export default memo(TableBody);
