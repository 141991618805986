import { useMemo, useState } from 'react';

import { columns } from './constants';

import Grid from '@material-ui/core/Grid';

import SearchView from '@src/Components/common/containers/SearchView';

function Aging() {
  return (
    <Grid item container justify="center" alignItems="center">
      <SearchView
        url="api/v1/manufacturer-suppliers/agingSearch"
        columns={columns}
        filters={[
          { type: 'string', name: 'SKU', keyName: 'listingSku' },
          { type: 'string', name: 'Item Name', keyName: 'name' },
        ]}
      />
    </Grid>
  );
}

export default Aging;
