import React, { memo } from 'react';

import useMe from '@src/hooks/swr/useMe';

import { WEBAHOLICS_ID } from '@oneAppCore/constants/domainLogic';

import { Switch, Route } from 'react-router-dom';

import type { LoggedInUser } from '@oneAppCore/types/userTypes';
import mainRoutes from './mainRoutes';

import type { Page } from './types';

const authenticateRoute = (route: Page, user: LoggedInUser) => {
  route.redirectUrl = '/login';
  route.authorized = true;
  if (route.userRequired && route.userRequired.length) {
    if (!user) {
      route.authorized = false;
    }
    if (user?.id) {
      const userIsIncluded = route.userRequired.includes(Number(user?.id))
      if (!userIsIncluded) {
        route.authorized = false;
      }
    } else {
      route.authorized = false;
    }
  }
  if (route.permissionRequired && route.permissionRequired.length) {
    if (!user) {
      route.authorized = false;
    }
    const findResults = user?.roles?.find((role: any) => {
      const { permissionType, permissionRequired } = route;
      const checkPermissionType = !permissionType || permissionType === 'any' ? role.read || role.write || role.update || role.delete : role[permissionType.toLowerCase()];
      return permissionRequired.includes(role.roleId) && checkPermissionType;
    }
    )
    if (!findResults) {
      route.authorized = false;
    }
  }
  if (route.loginRequired && !user) {
    route.authorized = false;
  }
  if (route.loggoutRequired && Boolean(user)) {
    route.redirectUrl = '/';
    route.authorized = false;
  }
  if (route.webaholicsOnly && user?.companyId !== WEBAHOLICS_ID) {
    route.redirectUrl = '/';
    route.authorized = false;
  }
  return route;
};

const RenderRoute = ({ route, user }: { route: Page, user: LoggedInUser }) => (
  <Switch key={route.key}>
    <Route
      exact={route.exact}
      path={route.path}
      render={() => (
        <route.layout
          authorized={route.authorized}
          redirectUrl={route.redirectUrl}
        >
          <route.component user={user} />
        </route.layout>
      )}
    />
  </Switch>
);

const Routes = () => {
  const { data: user, error } = useMe();

  // App hasn't loaded the user yet, don't load the routes.
  if (!error && !user) return <></>;

  return (
    <>
      {mainRoutes.map((route) => {
        route = authenticateRoute(route, user);
        return (
          <React.Fragment key={route.key}>
            {route.subRoutes &&
              route.subRoutes.map((subRoute) => {
                subRoute = authenticateRoute(subRoute, user);
                return <RenderRoute route={subRoute} key={subRoute.key} user={user} />;
              })}
            {route.path && <RenderRoute route={route} key={route.key} user={user} />}
          </React.Fragment>
        );
      })}

      {/* <RenderRoute route={notFound} key={notFound.key} /> */}
    </>
  );
};

export default memo(Routes);
