import React from 'react';

import { Redirect } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Header from './Header';
import Footer from './Footer';

import type { LayoutProps } from '../types';

const useStyles = makeStyles((theme: Theme) => ({
  layoutWrapper: {
    minHeight: 'calc(80vh - 168px)',
    marginTop: 152,
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(5)}px 0`,
      minHeight: 'calc(100vh - 128px)',
      marginTop: 112,
    },
  },
  layoutPaper: {
    padding: theme.spacing(4),
    maxWidth: '95%',
    margin: '0 auto',

    transition: '.33s ease',
    [theme.breakpoints.up('lg')]: {
      maxWidth: 750,
    },
  },
}));

function AuthLayout({ children, authorized, redirectUrl }: LayoutProps) {
  const classes = useStyles();

  if (!authorized) {
    return <Redirect to={redirectUrl} />;
  }

  return (
    <>
      {/* <Header /> */}
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.layoutWrapper}
      >
        <Paper elevation={3} className={classes.layoutPaper}>
          {children}
        </Paper>
      </Grid>
      {/* <Footer /> */}
    </>
  );
}

export default AuthLayout;
