import { useEffect } from 'react';
import useSelectedReport from '../hooks/useSelectedReport';
import recoilReportOptions from '../hooks/useReportOptions';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

export default function ReportDownload() {
  const [
    reportOptions,
    setReportOptions,
  ] = recoilReportOptions.useReportOptionsState();
  const { data: report } = useSelectedReport();

  useEffect(() => {
    if (reportOptions.csv && typeof report === 'string') {
      window.open(report, '_blank');
      noCsv();
    }
  }, [reportOptions, report]);

  useEffect(() => {
    noCsv();
  }, []);

  const noCsv = () => {
    setReportOptions((previous) => ({
      ...previous,
      csv: null,
    }));
  };

  const downloadCsv = () => {
    setReportOptions((previous) => ({
      ...previous,
      csv: true,
    }));
  };

  if (!reportOptions.selectedReport) {
    return <></>;
  }

  return (
    <Grid item>
      <Button
        disabled={Boolean(reportOptions.csv)}
        color="primary"
        variant="outlined"
        onClick={downloadCsv}
      >
        Download CSV
      </Button>
    </Grid>
  );
}
