import { useMemo, useState } from 'react';
import useSuppliers from '@src/hooks/swr/useSuppliers';

import { columns, stockProperties } from './constants';

import Grid from '@material-ui/core/Grid';

import SearchView from '@src/Components/common/containers/SearchView';
import ProgressModal from './ProgressModal';
import MiniReports from './MiniReports';
import AddButton from './AddButton';

const url = '/api/v1/manufacturer-suppliers/search';

function AdminInventorySearch() {
  const { data: suppliers = [] } = useSuppliers();

  const [openPro, setOpenPro] = useState(false);

  const supplierOptions = useMemo(
    () =>
      suppliers.map((company) => ({
        label: company.name,
        value: company.id,
      })),
    [suppliers],
  );

  const customSearchBarComponents = (
    <Grid
      item
      container
      xs={12}
      sm={10}
      md={6}
      spacing={1}
      style={{ justifyContent: 'space-around', paddingLeft: 8 }}
    >
      <Grid item container xs={12}>
        <AddButton />
      </Grid>
    </Grid>
  );

  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid
          item
          container
          justifyContent="flex-end"
          style={{ marginBottom: 15 }}
        >
          <ProgressModal open={openPro} close={setOpenPro} />
        </Grid>
      </Grid>
      <SearchView
        url={url}
        customSearchBarComponents={customSearchBarComponents}
        supportsDownload
        columns={columns}
        filters={[
          {
            type: 'selector',
            name: 'Supplier',
            keyName: 'supplierId',
            properties: supplierOptions,
          },
          {
            type: 'selector',
            name: 'Stock Status',
            keyName: 'stockFilter',
            properties: stockProperties,
          },
          {
            type: 'string',
            name: 'Locations',
            keyName: 'location',
          },
          {
            type: 'string',
            name: 'Brand',
            keyName: 'brand',
          },
          {
            type: 'equality',
            name: 'Days Aged',
            keyName: 'daysLeftFilter',
          },
        ]}
        MiniReportComponent={MiniReports}
      />
    </Grid>
  );
}

export default AdminInventorySearch;
