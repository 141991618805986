import React from 'react';
import useSelectedReport from '../hooks/useSelectedReport';
import recoilReportOptions from '../hooks/useReportOptions';

import { dateFormat } from '@oneAppCore/constants/dates';
import dayjs from 'dayjs';
import numberUtils from '@oneAppCore/utils/numbers';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import RunHistoryTable from '../RunHistoryTable';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    maxWidth: 'calc(100vw - 400px)',
    minWidth: 'calc(100vw - 400px)',
    overflowY: 'scroll',
    [theme.breakpoints.down('md')]: {
      width: '90vw',
      minWidth: '90vw',
      maxWidth: '90vw',
    },
  },
  oddRow: {
    backgroundColor: '#FFFFFF',
  },
  evenRow: {
    backgroundColor: '#EEF2F6',
  },
}));

export default function ReportTable() {
  const classes = useStyles();

  const reportOptions = recoilReportOptions.useReportOptions();
  const { data: report, isValidating } = useSelectedReport();
  if (!reportOptions.selectedReport) {
    return <></>;
  }

  if (typeof report === 'string' || !report?.rows || isValidating) {
    return <CircularProgress />;
  }

  console.log(report, 'report rows');

  return (
    <TableContainer component={Paper} classes={{ root: classes.root }}>
      <Table aria-label="simple table" stickyHeader>
        <TableHead
          style={{
            backgroundColor: '#f3f2f7',
            border: 0,
            whiteSpace: 'nowrap',
          }}
        >
          <TableRow>
            {report.columns.map((columnName) => (
              <TableCell key={columnName.title}>{columnName.title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {report.rows.map((row, index) => (
            <TableRow
              key={row.channelOrderId}
              className={index % 2 === 0 ? classes.evenRow : classes.oddRow}
            >
              {report.columns.map((column) => {
                const value = row[column.key];
                return (
                  <TableCell key={column.key + row.channelOrderId}>
                    {column.key === 'imageUrl' && value ? (
                      <img
                        src={value}
                        alt="Product"
                        style={{ width: '50px', height: '50px' }}
                      />
                    ) : column.key === 'lastWeek' ? (
                      <Typography variant="h2" style={{ fontSize: 30 }}>
                        {value}
                      </Typography>
                    ) : column.type === 'date' && value ? (
                      dayjs(value).format(dateFormat)
                    ) : column.type === 'string' ? (
                      value ? (
                        `${value}`?.slice(0, 25)
                      ) : (
                        value
                      )
                    ) : column.type === 'currency' ? (
                      numberUtils.formatNumber(value, '$00.00')
                    ) : column.type === 'percent' ? (
                      numberUtils.formatNumber(value, '00.00') + '%'
                    ) : typeof value === 'number' ? (
                      numberUtils.formatNumber(value, '0')
                    ) : (
                      value
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
