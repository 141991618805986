import recoilReportOptions from '../hooks/useReportOptions';
import FontAwesome from '@src/Components/common/FontAwesome';

import { makeStyles } from '@material-ui/core/styles';
import { options } from '../ReportSelect/constants';
import type { ReportName } from '../types';
import {
  Typography,
  Card,
  Button,
  Grid,
  Tooltip,
  IconButton,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    // maxWidth: 'calc(100vw - 400px)',
    // minWidth: 'calc(100vw - 400px)',
    width: '100%',
    marginTop: 30,
    // overflowY: 'scroll',
    [theme.breakpoints.down('md')]: {
      width: '90vw',
      minWidth: '90vw',
      maxWidth: '90vw',
    },
  },
  breakpoints: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'fles-start',
    },
  },
  gridList: {
    flexWrap: 'nowrap',
  },
  cardRoot: {
    maxWidth: '33%',
    transition: 'transform 0.15s ease-in-out',
  },
  cardHovered: {
    transform: 'scale3d(1.05, 1.05, 1)',
  },
  card: {
    [theme.breakpoints.down('md')]: {
      width: '45%',
      marginRight: '5px',
      marginBottom: '10px',
      padding: 10,
      justify: 'space-between'
    },
     [theme.breakpoints.up('md')] : {

       width: '33%',
       marginRight: '5px',
       marginBottom: '10px',
       padding: 10,
       justify: 'space-between',
     }
  },
}));

export default function ReportCards() {
  const classes = useStyles();

  const [
    reportOptions,
    setReportOptions,
  ] = recoilReportOptions.useReportOptionsState();

  const updateReport = (report: ReportName) => {
    setReportOptions((previous) => ({
      ...previous,
      selectedReport: report,
    }));
  };

  const downloadCsv = () => {
    setReportOptions((previous) => ({
      ...previous,
      csv: true,
    }));
  };

  return (
    <Grid item container xs={12} md={12} lg={12} className={classes.breakpoints}>
      {options.map((optionName) => (
        <Card key={optionName.value} classes={{ root: classes.card }}>
          <Grid container xs={12}>
            <Grid item xs={7}>
              <Typography variant="h6" style={{ padding: '8px' }}>
                {optionName.label}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              lg={5}
              style={{ justifyContent: 'flex-end' }}
            >
              <Tooltip title={'Run Report'}>
                <IconButton style={{ fontSize: 14 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => updateReport(optionName.value)}
                  >
                    <FontAwesome name="play" type="fa" form="fa" />
                  </Button>
                </IconButton>
              </Tooltip>
              <Tooltip title={'Download CSV'}>
                <IconButton style={{ fontSize: 14 }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      updateReport(optionName.value);
                      downloadCsv();
                    }}
                  >
                    <FontAwesome name="download" type="fa" form="fa" />
                  </Button>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Card>
      ))}
    </Grid>
  );
}
