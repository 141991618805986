import {
  TableCell,
  TableRow,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  Grid,
  Select,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { listingTable } from './constants';
import type { Listings, ListingProps, ListingConstants } from './types';
import FontAwesome from '@src/Components/common/FontAwesome';
import Api from '@oneAppCore/services/Api';
import AddListingRow from './addListingRow';
import { DRAFT } from '@oneAppCore/constants/listings';
import { convertDollarsToCents } from '@src/utils/currency';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { formatCentsToDollars } from '@oneAppCore/utils/currency';
import StoreListingApi from '@oneAppCore/services/StoreListingApi';
import ManufacturerSuppliersApi from '@oneAppCore/services/ManufacturerSuppliersApi';
import { primary } from '@src/Components/Theme/themes/constants';

const useStyles = makeStyles(() => ({
  headerColor: {
    border: 0,
    margin: 5,
  },
  fontColor: {
    color: '#FFF',
    backgroundColor: '#6e6b7b',
    fontSize: 10,
  },
  bodyFont: {
    fontSize: 12,
  },
  tableContainer: {
    maxHeight: 440,
    zIndex: 2,
    margin: 10,
    borderRadius: 5,
    border: '1px solid #6e6b7b',
  },
}));

const ListingTable = ({
  listingData,
  variationId,
  mutate,
  manufacturerNo,
  supplierData,
  discountData,
}: ListingProps) => {
  const [enableEdit, setEnableEdit] = useState<boolean>(null);
  const [stores, setStores] = useState([]);
  const [saving, setSaving] = useState(false);

  const { id }: any = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [editListing, setEditListing] = useState({
    id: 0,
    storeChannel: '',
    listingSku: '',
    price: 0,
    minPrice: 0,
    maxPrice: 0,
  });
  const [editableColumn, setEditableColumn] = useState<{
    fitIndex: number;
  }>(null);

  const classes = useStyles();

  useEffect(() => {
    const getStoreChannels = async () =>
      await Api.get(`/api/v1/store-channels/search`).then((res) => {
        const { rows } = res;
        setStores(rows);
      });
    getStoreChannels();
  }, []);

  const handleChange = (key, e) => {
    setEditListing((list) => {
      const updatedListing = {
        ...list,
        [key.keyName]: e.target.value,
      };
      return updatedListing;
    });
  };

  const invokeListingUpdate = async (id, fitIndex) => {
    // e.preventDefault();
    // e.stopPropagation();
    setSaving(true);
    if (editListing.storeChannel === null || editListing.listingSku === null) {
      enqueueSnackbar('Please fill out all fields!', {
        variant: 'error',
      });
    } else {
      const storeChannelId = editListing.storeChannel;
      const listingSku = editListing.listingSku;
      // const price = convertDollarsToCents(editListing.price);
      const originalPrice = listingData[fitIndex].price;
      const newPrice = editListing.price;
      const price =
        originalPrice !== newPrice ? convertDollarsToCents(newPrice) : newPrice;

      const storeProductId = id;
      const status = DRAFT;
      const payload = {
        storeChannelId,
        listingSku,
        status,
        price,
        // variationId,
        // storeProductId,
      };
      try {
        await Api.put(`/api/v1/store-listings/${editListing.id}`, payload);
        enqueueSnackbar('Listing Updated!', {
          variant: 'success',
        });
        mutate();
        setEditListing(editListing);
      } catch (e) {
        enqueueSnackbar(`${e.message || e}`, {
          variant: 'error',
        });
      } finally {
        setEnableEdit(false);
        setSaving(false);
        setEditListing({
          id: 0,
          storeChannel: '',
          listingSku: '',
          price: 0,
          minPrice: 0,
          maxPrice: 0,
        });
      }
    }
  };

  const enableEditing = (fitIndex: number) => {
    setEditListing({
      id: listingData[fitIndex].id,
      storeChannel: listingData[fitIndex].store,
      listingSku: listingData[fitIndex].listingSku,
      price: listingData[fitIndex].price,
      minPrice: listingData[fitIndex].minPrice,
      maxPrice: listingData[fitIndex].maxPrice,
    });
    setEnableEdit(true);
    setEditableColumn({ fitIndex });
  };

  const lowestSupp = supplierData
    ? supplierData?.reduce((lowest, supplier) => {
        const combinedCost = supplier.cost + supplier.shippingCost;
        if (!lowest || (combinedCost && combinedCost < lowest.combinedCost)) {
          return { supplier, combinedCost };
        }
        return lowest;
      }, null)
    : null;

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader size="small" aria-label="fitment">
        <TableHead>
          <TableRow className={classes.headerColor}>
            {listingTable.map((listing: ListingConstants, i) => (
              <TableCell
                className={classes.fontColor}
                key={'listing_' + listing.keyName + i}
              >
                {listing.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {listingData &&
          listingData.map((listing: Listings, fitIndex) => {
            return (
              <TableRow key={listing.id}>
                {listingTable.map((key: ListingConstants, index) => {
                  if (enableEdit && key.keyName === 'store') {
                    return (
                      <TableCell key={`listing_${key.keyName}_${listing.id}`}>
                        <Select
                          // value={editListing.storeChannel}
                          defaultValue={listing.store}
                          onChange={(e) =>
                            handleChange({ keyName: 'storeChannel' }, e)
                          }
                        >
                          <MenuItem value={'0'}></MenuItem>
                          {stores.map((s) => (
                            <MenuItem
                              value={s.id}
                              key={s.storeId + s.storeName + s.channelName}
                            >
                              {s.storeName} {s.channelName}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    );
                  } else if (enableEdit && key.keyName === 'channel') {
                    return <TableCell key={`empty-${index}`}>{null}</TableCell>;
                    // enableEdit ? (
                    //   <TableCell>{null}</TableCell>
                    // ) : (
                    //   <TableCell>{listing.channel}</TableCell>
                    // );
                  } else if (key.keyName === 'edit') {
                    return (
                      <TableCell key={'listing_' + key.keyName + listing.id}>
                        <FontAwesome
                          name={enableEdit ? 'check' : 'edit'}
                          color={enableEdit ? '#50C87' : primary.main}
                          type="fas"
                          form="fa"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            enableEdit
                              ? invokeListingUpdate(listing.id, fitIndex)
                              : enableEditing(fitIndex);
                            // setEnableEdit(!enableEdit);
                          }}
                        />
                      </TableCell>
                    );
                  } else if (key.keyName === 'cost') {
                    return (
                      <TableCell key={'listing_' + key.keyName + listing.id}>
                        <Tooltip
                          style={{
                            width: '425px',
                            borderRadius: '10px',
                            justifyContent: 'flex-start',
                          }}
                          title={
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Supplier</TableCell>
                                    <TableCell>Cost</TableCell>
                                    <TableCell>Act. Ship</TableCell>
                                    <TableCell>Est. Ship</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {supplierData?.map((supp) => (
                                    <TableRow
                                      style={{ backgroundColor: '#ffffff' }}
                                      key={supp.supplierId}
                                    >
                                      <TableCell>{supp.supplier}</TableCell>
                                      <TableCell>
                                        {formatCentsToDollars(supp.cost)}
                                      </TableCell>
                                      <TableCell>
                                        {formatCentsToDollars(
                                          supp.shippingCost,
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {formatCentsToDollars(
                                          supp.estimatedShipping,
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          }
                        >
                          <Grid item>
                            <Typography style={{ fontSize: '12px' }}>
                              {formatCentsToDollars(lowestSupp?.combinedCost)}
                            </Typography>
                          </Grid>
                        </Tooltip>
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        className={classes.bodyFont}
                        key={'listing_' + key.keyName + listing.id}
                      >
                        {enableEdit && key.editable !== false ? (
                          <TextField
                            variant="outlined"
                            value={editListing[key.keyName]}
                            onChange={(e) =>
                              handleChange({ keyName: key.keyName }, e)
                            }
                          />
                        ) : key.customComponent ? (
                          key.customComponent(listing)
                        ) : (
                          listing[key.keyName]
                        )}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            );
          })}
        {/* <AddListingRow variationId={variationId} mutate={mutate} /> */}
      </Table>
    </TableContainer>
  );
};

export default ListingTable;
