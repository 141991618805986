import clsx from 'clsx';
import { options } from './constants';

import { Grid, Button, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import recoilSearch from '../hooks/useSearchOptions';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

const useStyles = makeStyles((theme) => ({
  selectedButton: {
    backgroundImage: 'linear-gradient(147deg, #4f46b0 0%, #7970dc 90%)',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.blue.light,
    },
  },
  nonSelectedButton: {
    '&:hover': {
      backgroundImage: 'linear-gradient(147deg, #4ea3de 0%, #1a8cdb 74%)',
      color: theme.palette.common.white,
    },
  },
  responsiveUi: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      marginBottom: 10,
      marginTop: 10,
      minWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {},
    minWidth: 'max-content',
    overflow: 'wrap',
    paddingLeft: '16px',
  },
  toggleGroup: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '300px',
    },
  },
}));

function ChannelOptions() {
  const [searchOptions, setSearchOptions] = recoilSearch.useState();
  const classes = useStyles();

  const channelChange = (channelId?: number) => {
    setSearchOptions((previous) => ({
      ...previous,
      channel: channelId ? channelId : null,
    }));
  };

  return (
    <Grid item container alignItems="center" className={classes.responsiveUi}>
      <ToggleButtonGroup size="small" className={classes.toggleGroup}>
        {options.map((option) => (
          <ToggleButton
            key={option.name}
            style={{
              fontSize: '10px',
              fontWeight: 600,
            }}
            className={clsx(
              {
                [classes.selectedButton]:
                  (!searchOptions.channel && option.value === null) ||
                  searchOptions.channel === option.value,
              },
              {
                [classes.nonSelectedButton]:
                  (searchOptions.channel && option.value === null) ||
                  searchOptions.channel !== option.value,
              },
            )}
            onClick={() => channelChange(option.value)}
          >
            {option.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Grid>
  );
}

export default ChannelOptions;
