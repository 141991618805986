import type { Column } from '@src/Components/common/containers/SearchView/types';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import { Grid, Typography } from '@material-ui/core';

export const columns: Column[] = [
  {
    title: 'Image',
    keyName: 'image',
    customComponent: function Image(row) {
      console.log(row, 'Testing');
      return (
        <Grid container xs={12} style={{ justifyContent: 'center' }}>
          <img
            height={50}
            width={50}
            src={
              row?.image != null
                ? row.image
                : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
            }
          />
        </Grid>
      );
    },
  },
  {
    title: 'Brand',
    keyName: 'brand',
  },
  {
    title: 'Last Week',
    keyName: 'lastweekcount',
    customComponent: function LastWeek(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography
            style={{
              color:
                row.lastweekamount > row.twoweeksagoamount
                  ? 'green'
                  : row.lastweekdata < row.twoweeksagoamount
                  ? 'red'
                  : 'inherit',
            }}
          >
            {row.lastweekcount} {row.lastweekamount}
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: '2 Weeks Ago',
    keyName: 'twoweeksagodata',
    customComponent: function TwoWeeks(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography
            style={{
              color:
                row.twoweeksagoamount > row.threeweeksagoamount
                  ? 'green'
                  : row.twoweeksagoamount < row.threeweeksagoamount
                  ? 'red'
                  : 'inherit',
            }}
          >
            {row.twoweeksagocount} {row.twoweeksagoamount}
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: '3 Weeks Ago',
    keyName: 'threeweeksagodata',
    customComponent: function ThreeWeeks(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography
            style={{
              color:
                row.threeweeksagoamount > row.fourweeksagoamount
                  ? 'green'
                  : row.threeweeksagoamount < row.fourweeksagoamount
                  ? 'red'
                  : 'inherit',
            }}
          >
            {row.threeweeksagocount} {row.threeweeksagoamount}
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: '4 Weeks Ago',
    keyName: 'fourweeksagodata',
    customComponent: function FourWeeks(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography
            style={{
              color:
                row.fourweeksagoamount > row.fiveweeksagoamount
                  ? 'green'
                  : row.fourweeksagoamount < row.fiveweeksagoamount
                  ? 'red'
                  : 'inherit',
            }}
          >
            {row.fourweeksagocount} {row.fourweeksagoamount}
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: '5 Weeks Ago',
    keyName: 'fiveweeksagodata',
    customComponent: function FiveWeeks(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography
            style={{
              color:
                row.fiveweeksagoamount > row.sixweeksagoamount
                  ? 'green'
                  : row.fiveweeksagoamount < row.sixweeksagoamount
                  ? 'red'
                  : 'inherit',
            }}
          >
            {row.fiveweeksagocount} {row.fiveweeksagoamount}
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: '6 Weeks Ago',
    keyName: 'sixweeksagodata',
    customComponent: function SixWeeks(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography>
            {row.sixweeksagocount} {row.sixweeksagoamount}
          </Typography>
        </Grid>
      );
    },
  },
];
