import { ISSUE, TRACKED, PLACED } from '@oneAppCore/constants/orders';
import { getColor } from '@src/utils/colors';
import { startCase, lowerCase } from 'lodash';
import {
  Typography,
  Grid,
  Button,
  Select,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import ViewDrawerButton from '../ViewDrawerButton/ViewDrawerButton';
import PrintLabelButton from '../PrintLabelButton';
import DropshipButton from '../DropshipButton';
import OutOfStockButton from '../OutOfStockButton';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import formatters from '@oneAppCore/utils/formatters';
import FontAwesome from '@src/Components/common/FontAwesome';

import type {
  Column,
  ColumnAlign,
} from '@src/Components/common/containers/SearchView/types';
import { dateFormat } from '@oneAppCore/constants/dates';
import dayjs from 'dayjs';
import {
  convertDollarsToCents,
  formatCentsToDollarsIntl,
} from '@oneAppCore/utils/currency';
import { orderStatusColors } from '@src/utils/orders';
import Chip from '@material-ui/core/Chip';
import PackageButton from './PackageButton';

export const columns = (
  carrierData,
  me,
  classes,
  labels,
  labelMutate,
  shipRates,
  setShipRates,
  packages,
  setPackages,
): Column[] => [
    {
      title: 'Order Date',
      // title: 'Placed At/Ship By',
      keyName: 'purchaseDate',
      sortBy: true,
      // align: 'center',
      customComponent: function purchaseDate(row) {
        // make date formatting an option int he search table later
        return (
          <Grid container >
            <Grid item xs={12}>
              <div style={{ minWidth: '120px' }}>
                Placed At:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {dayjs(row.purchaseDate).format('MMM DD')}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} spacing={1}>
              <div
                style={{
                  minWidth: '120px',
                  justifyContent: 'center',
                }}
              >
                Ship By:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {dayjs(row.expectedShipDate).format('MMM DD')}
                </span>
              </div>
            </Grid>
          </Grid>
        );
      },
    },
    {
      title: 'Order',
      keyName: 'orderNumber',
      sortBy: true,
      customComponent: function OrderNumberDisplay(row) {
        const backgroundColor = orderStatusColors(row.orderStatus);
        return (
          <Grid
            container
            key={row.id}
            style={{
              minWidth: '200px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              xs={3}
              style={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <Grid
                item
                container
                xs={12}
                style={{ justifyContent: 'center', alignItems: 'center' }}
              >
                <img
                  width={35}
                  height={35}
                  style={{ borderRadius: 10 }}
                  src={
                    row.icon != null
                      ? row.icon
                      : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
                  }
                />
              </Grid>
            </Grid>
            <Grid item container xs={9} style={{ justifyContent: 'center' }}>
              <Grid
                item
                xs={12}
                style={{ justifyContent: 'center', textAlign: 'center' }}
              >
                # {row.orderNumber}
              </Grid>
              {/* <Grid
              item
              container
              xs={12}
              style={{ justifyContent: 'center', textAlign: 'center' }}
            >
              <Chip
                style={{
                  backgroundColor: backgroundColor,
                  color: '#ffffff',
                  width: '60%',
                  height: '100%',
                }}
                label={startCase(lowerCase(row.orderStatus))}
              />
            </Grid> */}

              <br />
              {row.issueType && (
                <Grid
                  item
                  xs={6}
                  style={{
                    backgroundColor: getColor('red', 'main'),
                    padding: 10,
                    borderRadius: 10,
                    minWidth: 100,
                    height: 10,
                    marginTop: 5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                  }}
                >
                  <Chip
                    style={{
                      backgroundColor: 'crimson',
                      color: '#ffffff',
                      width: '100%',
                      height: '100%',
                    }}
                    label={startCase(lowerCase(row.issueType))}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                style={{
                  justifyContent: 'center',
                  textAlign: 'center',
                  maxWidth: '200px',
                }}
              >
                {/* <span style={{ fontWeight: 'bold' }}>{row.shippingName}</span> */}
                <div
                  style={{
                    textOverflow: 'ellipsis',
                    display: 'block',
                    maxWidth: '150px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    lineHeight: 1,
                    fontWeight: 'bold',
                    fontSize: 'small',
                  }}
                  title={row.shippingName}
                >
                  {row.shippingName}
                </div>
              </Grid>
            </Grid>
          </Grid>
        );
      },
    },
    {
      keyName: 'quantity',
      title: 'QTY',
      sortBy: true,
      customComponent: function Quantity(row) {
        if (!row?.orderItems?.[0]) return;
        return (
          <Grid
            container
            xs={10}
            spacing={1}
            direction="column"
            style={{
              maxWidth: '600px',
              justifyContent: 'flex-end',
            }}
          >
            {row.orderItems?.map((item) => {
              const colorClass = item.quantity > 1 ? classes.red : classes.black;
              return (
                <Grid item container key={item.id} alignItems="center">
                  <Grid
                    item
                    container
                    xs={12}
                    direction="column"
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item>
                      <div
                        className={formatters.classNames([
                          classes.quantity,
                          colorClass,
                        ])}
                        style={{ height: 30, width: 30 }}
                      >
                        <p style={{ fontSize: '18px' }}>{item?.quantity}</p>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        );
      },
    },
    {
      keyName: 'items',
      title: 'Items',
      sortBy: true,
      // align: 'center',
      customComponent: function OrderItemsDisplay(row) {
        if (!row?.orderItems?.[0]) return;
        return (
          <Grid
            container
            xs={10}
            spacing={1}
            direction="column"
            style={{
              maxWidth: '650px',
              justifyContent: 'flex-end',
            }}
          >
            {row.orderItems?.map((item) => {
              const colorClass = item.quantity > 1 ? classes.red : classes.black;
              return (
                <Grid item container key={item.id} alignItems="center">
                  <Grid
                    item
                    container
                    xs={4}
                    direction="column"
                    alignItems="center"
                    justify="flex-end"
                    wrap="nowrap"
                  >
                    <Grid item>
                      {item.variationImages?.[0] ? (
                        <img
                          height={50}
                          width={50}
                          src={
                            item.variationImages?.[0]?.imageUrl != null
                              ? item.variationImages?.[0]?.imageUrl
                              : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;
                          }}
                          alt="Product"
                        />
                      ) : (
                        <>
                          <img
                            height={50}
                            width={50}
                            src={`https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`}
                            alt="placeholder image for product image"
                          />
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    // spacing={1}
                    direction="column"
                    xs={8}
                  // justify="flex-start"
                  >
                    <Grid item xs={12}>
                      <div
                        style={{
                          textOverflow: 'ellipsis',
                          display: 'block',
                          maxWidth: '300px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          lineHeight: 1,
                          fontWeight: 'bold',
                          fontSize: 'small',
                        }}
                        title={item?.channelData?.title}
                      >
                        {item?.channelData?.title}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <span style={{ lineHeight: 0.5, fontSize: 'small' }}>
                        SKU:{' '}
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                          lineHeight: 0.5,
                          fontSize: 'small',
                        }}
                      >
                        {item.sku}
                        {/* {item.listingSku} */}
                      </span>
                      <span
                        style={{
                          // fontWeight: 'bold',
                          lineHeight: 0.5,
                          fontSize: 'small',
                        }}
                      >
                        &nbsp;({item.manufacturerNo})
                      </span>
                    </Grid>
                    <Grid item xs={12}>
                      {/* put icon here to replace location? */}
                      {item.location ? (
                        <span
                          style={{
                            fontWeight: 'bold',
                            lineHeight: 0.5,
                            fontSize: 'small',
                            color: '#399ade',
                          }}
                        >
                          {item.location}
                          {/*  {' | '} */}
                        </span>
                      ) : (
                        <span
                          style={{
                            fontWeight: 'bold',
                            lineHeight: 0.5,
                            fontSize: 'small',
                          }}
                        >
                          {' '}
                          N/A{' '}
                        </span>
                      )}
                      {' | '}
                      <span style={{ lineHeight: 0.5, fontSize: 'small' }}>
                        Inv:{' '}
                      </span>
                      {/* <span style={{ lineHeight: 0.5 }}>Inventory: </span> */}
                      <span
                        style={{
                          fontWeight: 'bold',
                          lineHeight: 0.5,
                          fontSize: 'small',
                        }}
                      >
                        {item.inventory + item.quantity}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        );
      },
    },
    {
      title: 'Package Info',
      keyName: 'packageInfo',
      align: 'center',
      customComponent: function packageInfo(row) {
        let weight = shipRates?.[row.id]?.weight;
        if (!shipRates?.[row.id]?.weight) {
          weight = 0;
          for (let index = 0; index < row?.orderItems?.length; index++) {
            const item = row?.orderItems?.[index];
            weight += item.weight
              ? parseFloat(item.weight) * item.quantity * 16
              : 0;
          };
        };
        return (
          <Grid container style={{ textAlign: 'left' }}>
            <PackageButton row={row} packages={packages} setPackages={setPackages} setShipRates={setShipRates} shipRates={shipRates} />
            <Grid item container spacing={2} xs={12}>
              <Grid item container xs={12} justify="space-around">
                <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
                  {/* Total  */}Weight:{' '}
                </span>
                {weight && (
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: weight > 16 ? 'orange' : 'green',
                    }}
                  >
                    {weight}oz
                  </span>
                )}
                {!weight && !shipRates?.[row.id]?.error && (
                  <FontAwesome spin name="spinner" type="fas" form="fa" />
                )}
              </Grid>
            </Grid>
          </Grid>
        );
      },
    },
    {
      title: 'Rates',
      keyName: 'rates',
      align: 'center',
      customComponent: function rates(row) {
        let serviceName = '';
        let shipDate = '';
        let shippingCost;
        const label = labels.find((lab) => lab.orderId === row.id);
        if (shipRates?.[row.id]) {
          serviceName = shipRates?.[row.id]?.selectedServiceName;
          const serviceOption = shipRates?.[row.id]?.serviceOptions?.find(service => service?.serviceName == serviceName);
          if (serviceOption) {
            shippingCost = (serviceOption?.shipmentCost + serviceOption?.otherCost).toFixed(2);
            shipDate = `(${new Date(serviceOption?.deliveryDate).toLocaleString('default', { month: 'long' }).slice(0, 3)} ${new Date(serviceOption?.deliveryDate).getDate()})`
          };
        };
        if (row?.label) {
          serviceName = row?.orderItems?.[0]?.supplierTrackingCarrier;
          shippingCost = (row?.orderItems?.[0]?.supplierShippingCost / 100).toFixed(2);
        };
        return (
          <Grid
            container
            xs={10}
            direction="column"
            style={{
              minWidth: '180px',
              maxWidth: '650px',
              justifyContent: 'flex-end',
            }}
          >
            <Grid item xs={12}>
              <div style={{ textAlign: 'left' }}>
                Service:{' '}
                {serviceName && !shipRates?.[row.id]?.loading && <span style={{ fontWeight: 'bold' }}>
                  {serviceName} {shipDate}
                </span>}
                {(!serviceName || shipRates?.[row.id]?.loading) && <FontAwesome spin name="spinner" type="fas" form="fa" />}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ textAlign: 'left' }}>
                Shipping Cost:{' '}
                {shippingCost && !shipRates?.[row.id]?.error && !shipRates?.[row.id]?.loading && <span style={{ fontWeight: 'bold', color: 'blue' }}>
                  ${shippingCost}
                </span>}
                {(!shippingCost || shipRates?.[row.id]?.loading) && <FontAwesome spin name="spinner" type="fas" form="fa" />}
              </div>
            </Grid>
            {/* <Grid item xs={12}>
                <div style={{ textAlign: 'left' }}>
                  ETA:{' '}
                  {shipRates?.[row.id] && !shipRates?.[row.id]?.error && <span style={{ fontWeight: 'bold' }}>
                    {new Date(
                      shipDate,
                    )
                      .toLocaleString('default', {
                        month: 'long',
                      })
                      .slice(0, 3)}{' '}
                    {new Date(
                      shipDate,
                    ).getDate()}
                  </span>}
                  {!shipRates?.[row.id] && <FontAwesome spin name="spinner" type="fas" form="fa" />}
                </div>
              </Grid>
            */}
            {/* <Grid item xs={12} style={{ marginBottom: 10 }}>
                <PrintLabelButton
                  id={row.id}
                  row={row}
                  carrierData={carrierData}
                  me={me}
                  label={label}
                  mutate={labelMutate}
                  shipRates={shipRates}
                />
              </Grid>
            */}
          </Grid>
        );
      },
    },
    {
      title: 'Total',
      keyName: 'itemPrice',
      sortBy: true,
      align: 'center',
      customComponent: function orderTotal(row) {
        let serviceName = '';
        let shippingCost = 0;
        if (!row?.orderItems?.[0]) return;
        const label = labels.find((lab) => lab.orderId === row.id);
        if (shipRates?.[row.id]) {
          serviceName = shipRates?.[row.id]?.selectedServiceName;
          const serviceOption = shipRates?.[row.id]?.serviceOptions?.find(
            (service) => service?.serviceName == serviceName,
          );
          if (serviceOption) {
            shippingCost =
              serviceOption?.shipmentCost > 0
                ? convertDollarsToCents(
                    (
                      serviceOption?.shipmentCost + serviceOption?.otherCost
                    ).toFixed(2),
                  )
                : row.supplierShippingCost > 0
                ? row.supplierShippingCost
                : row.orderItems.every((item) => item.supplerShippingCost)
                ? row.orderItems.reduce(
                    (acc, curr) => acc + curr.supplierShippingCost,
                    0,
                  )
                : formatCentsToDollarsIntl(
                    Math.min(
                      ...row.serviceRates
                        .flatMap((rate) =>
                          rate.serviceRates.map((sr) => sr.shipmentCost),
                        )
                        .filter((cost) => cost > 0),
                    ) || 0,
                  );
          }
        };

        if (row?.label) {
          shippingCost = row?.orderItems?.[0]?.supplierShippingCost;
        };
  
        const totalPrice = row.orderItems?.reduce(
          (acc, item) => acc + item.itemPrice,
          0,
        );
        const totalFees =
          row.channelFee 
            ? row.channelFee
            : row.orderItems?.every((e) => e.itemFee)
            ? row.orderItems?.reduce((acc, item) => acc + item.itemFee, 0)
            : row.orderItems.reduce(
                (acc, curr) => acc + Math.floor(curr.supplierSubTotal * 0.12),
                0,
              );
        const totalItemCost = row.orderItems?.reduce(
          (acc, item) => acc + item.supplierSubTotal,
          0,
        );
        const totalCost = totalItemCost + totalFees + shippingCost;
        const totalProfit = formatCentsToDollarsIntl(
          totalPrice - totalItemCost - totalFees - shippingCost,
          { removeMoneySign: true },
        );
        const profitNumber: number = parseFloat(totalProfit);
        const color: string = profitNumber > 0 ? 'green' : 'red';
        return (
          <Grid
            container
            xs={12}
            spacing={1}
            direction="row"
            style={{
              justifyContent: 'flex-end',
              alignItems: 'space-around',
            }}
          >
            <Grid item container alignItems="center">
              <Grid item container xs={12} spacing={1} direction="column">
                <Grid item style={{ justifyContent: 'flex-end' }}>
                  <span style={{ fontWeight: 'bold' }}>
                    {formatCentsToDollarsIntl(totalPrice || 0)}
                  </span>
                </Grid>
              </Grid>
              <Grid item container xs={12} style={{ justifyContent: 'center' }}>
                <Grid item container xs={12}>
                  <Typography
                    style={{
                      fontSize: '0.75rem',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    <span style={{ fontWeight: 'bold' }}>
                      Cost: {formatCentsToDollarsIntl(totalCost || 0)}
                    </span>
                  </Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Typography
                    style={{
                      fontSize: '0.75rem',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    Profit:{' '}
                    <span
                      style={{
                        fontWeight: 'bold',
                        color: color,
                      }}
                    >
                      ${totalProfit}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      },
    },
    {
      title: 'Actions',
      keyName: 'actions',
      align: 'center',
      customComponent: function ActionButtons(row) {
        const label = labels.find((lab) => lab.orderId === row.id);
        return (
          <Grid
            container
            style={{
              flexDirection: 'column',
              lineHeight: '18px',
              minWidth: '175px',
              maxWidth: '200px',
            }}
          >
            {!shipRates?.[row.id]?.error && !shipRates?.[row.id]?.loading && (<Grid
              item
              container
              xs={12}
              md={12}
              lg={5}
              direction="row"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 10,
              }}
            >
              <Grid item xs={12} spacing={1} direction="row">
                <PrintLabelButton
                  id={row.id}
                  row={row}
                  carrierData={carrierData}
                  me={me}
                  label={label}
                  mutate={labelMutate}
                  shipRates={shipRates}
                />
              </Grid>
            </Grid>
            )}

            {shipRates?.[row.id] && shipRates?.[row.id].error && (
              <Tooltip title={shipRates?.[row.id]?.message}>
                <Grid style={{ color: 'red', cursor: 'default' }}>Error</Grid>
              </Tooltip>
            )}

            {shipRates?.[row.id]?.loading && <Grid>
              <FontAwesome spin name="spinner" type="fas" form="fa" />
            </Grid>}

            <Grid container justify="center" xs={12}>
              <Grid
                item
                xs={3}
                // spacing={1}
                direction="column"
                justify="flex-end"
              >
                <ViewDrawerButton id={row.id} row={row} orderType={'order'} />
              </Grid>
              <Grid
                item
                xs={3}
                // spacing={1}
                direction="column"
                style={{ justifyContent: 'center' }}
              >
                <DropshipButton row={row} />
              </Grid>
              <Grid item xs={3} direction="column">
                <OutOfStockButton />
              </Grid>
            </Grid>
          </Grid>
        );
      },
    },
  ];
