import { Grid, Typography } from '@material-ui/core';
import AppSelect from '@src/Components/common/Selects/AppSelect';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import recoilSearch from '@src/Components/common/containers/SearchView/hooks/useSearchOptions';
import { limitOptions } from '@src/Components/common/containers/SearchView/SearchTable/SearchBar/constants';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import type { Props } from './types';
import ChannelOptions from '@src/Components/common/containers/SearchView/ChannelOptions';

// const useStyles = makeStyles((theme) =>
//   createStyles({
//     root: {
//       marginTop: theme.spacing(2),
//       // width: '100%',
//     },
//   }),
// );

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('md')]: {
        // margin: theme.spacing(0.5, 0),
        width: '100%',
      },
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0),
        width: '100%',
      },
      justifyContent: 'space-between',
    },
    show: {
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: 20,
      },
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 5,
      },
      zIndex: 11,
    },
    pagination: {
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginRight: 20,
      },
      [theme.breakpoints.down('md')]: {},
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  }),
);

function TablePagination({
  showCount = true,
  showPerPage = true,
  currentPage,
  setCurrentPage,
  totalPages,
  showChannelOptions,
}: Props) {
  const classes = useStyles();
  const [searchState, setSearchState] = recoilSearch.useState();
  const [searchOptions, setSearchOptions] = recoilSearch.useState();
  const isMobile = useMediaQuery('(max-width:900px)');

  const update = (limit: number) => {
    setSearchState((previous) => ({
      ...previous,
      limit,
    }));
  };

  const updatePage = (page: number) => {
    setSearchOptions((previous) => ({
      ...previous,
      page,
    }));
  };

  return (
    // <Grid
    //   item
    //   container
    //   xs={6}
    //   md={12}
    //   justify="flex-end"
    //   className={classes.root}
    // >
    //   <Pagination
    //     count={totalPages}
    //     color="primary"
    //     page={currentPage}
    //     onChange={(_, page) => setCurrentPage(page)}
    //   />
    // </Grid>

    <Grid
      item
      container
      alignItems="center"
      // spacing={1}
      xs={12}
      className={classes.root}
    >
      {/* <Grid container xs={12} style={{ justifyContent: 'space-between' }}> */}
      <Grid
        item
        container
        xs={12}
        md={4}
        lg={12}
        className={classes.pagination}
      >
        {showCount ? (
          <Grid
            item
            container
            xs={12}
            style={{ justifyContent: 'flex-end', marginRight: 10 }}
          >
            <Typography
              variant="body2"
              style={{ fontWeight: 'bold', color: '#686868' }}
            >
              {/* Count: {Number(data?.count || 0).toLocaleString('en')} */}
              COUNT:
            </Typography>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <Grid
        container
        item
        xs={11}
        md={12}
        style={{ justifyContent: 'space-between' }}
      >
        {showPerPage ? (
          <Grid item container xs={6} className={classes.show}>
            <Grid item xs={6} sm={4} md={1}>
              Show:
            </Grid>
            <Grid item xs={6} sm={4} md={2} xl={1}>
              <AppSelect
                options={limitOptions}
                value={
                  limitOptions.find(
                    (option) => option.value === searchState.limit,
                  ) || {}
                }
                onChange={(option) => update(option.value)}
              />
            </Grid>
            <Grid item xs={3}>
              {showChannelOptions && <ChannelOptions />}
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        <Grid
          item
          container
          xs={showPerPage ? 5 : 11}
          style={{ justifyContent: 'flex-end' }}
        >
          <Pagination
            size="small"
            count={totalPages}
            color="primary"
            page={currentPage}
            onChange={(_, page) => setCurrentPage(page)}
            style={{ justifyContent: 'flex-end' }}
            siblingCount={1}
            boundaryCount={1}
          />
        </Grid>
      </Grid>
      {/* </Grid> */}
    </Grid>
  );
}

export default TablePagination;
