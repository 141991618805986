import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Collapse } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { RecoilRoot } from 'recoil';
import Theme from './Components/Theme';
import Routes from './routes';

import DayjsUtils from '@date-io/dayjs';

function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Theme>
          <MuiPickersUtilsProvider utils={DayjsUtils}>
            <SnackbarProvider maxSnack={3} TransitionComponent={Collapse}>
              <Routes />
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </Theme>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
