import TextField from '@src/Components/common/TextField';
import { Grid } from '@material-ui/core';

export type columnType = {
  title: string;
  key: string;
  type: string;
  customComponent?: any;
};
export const columns = (onRowChange: any): columnType[] => [
  {
    title: 'Purchase Date',
    key: 'Purchase Date',
    type: 'value',
  },
  {
    title: 'Last Price Change',
    key: 'priceChangedAt',
    type: 'value',
  },
  {
    title: 'Select',
    key: 'selected',
    type: 'checkbox',
  },
  {
    title: 'Channel Order ID',
    key: 'Channel Order ID',
    type: 'value',
  },
  {
    title: 'Supplier Order #',
    key: 'Supplier Order #',
    type: 'value',
  },
  {
    title: 'Channel Name',
    key: 'Channel Name',
    type: 'value',
  },
  {
    title: 'Price',
    key: 'Price',
    type: 'value',
    customComponent: function price(row) {
      const prices = (row['Price'] as string).split(',');
      return prices.map((price, index) => (
        <p key={`magik-time-price-${row.id}-${index}`}>
          ${parseFloat(price).toFixed(2)}
        </p>
      ));
    },
  },
  {
    title: 'Price After 12%',
    key: 'Price After 12%',
    type: 'value',
    customComponent: function priceRev(row) {
      return <p>${row['Price After 12%'] /* .toFixed(2) */}</p>;
    },
  },
  {
    title: 'Item Costs',
    key: 'itemCost',
    type: 'value',
    customComponent: function itemCost(row) {
      const itemCosts = (row.itemCost as string).split(',');
      return itemCosts.map((itemCost, index) => (
        <p key={`magik-time-item-cost-${row.id}-${index}`}>
          ${parseFloat(itemCost).toFixed(2)}
        </p>
      ));
    },
  },
  {
    title: 'Profit/Loss',
    key: 'Profit',
    type: 'value',
    customComponent: function profLoss(row) {
      return <p>$ {row.Profit.toFixed(2)}</p>;
    },
  },
  {
    title: 'Profit Margin',
    key: 'Profit Margin',
    type: 'value',
    customComponent: function profMarg(row) {
      function formatNumber(number) {
        if (typeof number === 'number') {
          return number.toFixed(2);
        } else {
          return number.toString();
        }
      }
      return <p>{formatNumber(row?.['Profit Margin'])}%</p>;
    },
  },
  {
    title: 'Supplier Name',
    key: 'Supplier Name',
    type: 'value',
  },
  {
    title: 'QTY',
    key: 'Quantity',
    type: 'value',
  },
  {
    title: 'Skus',
    key: 'sku',
    type: 'value',
    customComponent: function skus(row) {
      const vSku = row.sku?.split(',') || ['Not found Check DB'];
      return vSku.map((varId, index) => (
        <p key={`magik-time-sku=${row.id}-${index}`}>{vSku[index]}</p>
      ));
    },
  },
  {
    title: 'Min Price',
    key: 'minPrice',
    type: 'value',
    customComponent: function prices(row) {
      const minPrices = (row.minPrice as string).split(',');
      return minPrices.map((varId, index) => {
        return (
          <TextField
            key={`magik-time-min-price-${row.id}-${index}`}
            fullWidth
            label=""
            value={minPrices[index]}
            style={{ width: 75 }}
            onChange={(e) => {
              const minArray = [].concat(row.minPrice.split(','));
              minArray[index] = e.currentTarget.value;
              const value = minArray.join(',');
              onRowChange(row.id, 'minPrice', value);
            }}
            disabled={!row.selected}
          />
        );
      });
    },
  },
  {
    title: 'Max Price',
    key: 'maxPrice',
    type: 'input',
    customComponent: function maxPrice(row) {
      const maxPrices = (row.maxPrice as string).split(',');
      return maxPrices.map((varId, index) => {
        return (
          <TextField
            key={`magik-time-max-price-${row.id}-${index}`}
            fullWidth
            label=""
            value={maxPrices[index]}
            style={{ width: 75 }}
            onChange={(e) => {
              const maxArray = [].concat(row.maxPrice.split(','));
              maxArray[index] = e.currentTarget.value;
              const value = maxArray.join(',');
              onRowChange(row.id, 'maxPrice', value);
            }}
            disabled={!row.selected}
          />
        );
      });
    },
  },
  {
    title: 'New Price',
    key: 'newPrice',
    type: 'input',
    customComponent: function newPrice(row) {
      const newPrices = (row.newPrice as string).split(',');
      return newPrices.map((varId, index) => {
        return (
          <TextField
            key={`magik-time-new-price-${row.id}-${index}`}
            fullWidth
            label=""
            value={newPrices[index]}
            style={{ width: 75 }}
            onChange={(e) => {
              const newArray = [].concat(row.newPrice.split(','));
              newArray[index] = e.currentTarget.value;
              const value = newArray.join(',');
              onRowChange(row.id, 'newPrice', value);
            }}
            disabled={!row.selected}
          />
        );
      });
    },
  },
];
