import { useState } from 'react';
import { startCase, lowerCase } from 'lodash';
import FontAwesome from '@src/Components/common/FontAwesome';
import { IconButton, Tooltip, Grid } from '@material-ui/core';
import OrderDrawer from '../OrderDrawer';
import IssueDrawer from '../IssueDrawer';
import type { Props } from './types';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';

function ViewDrawer({ row, orderType = 'order' }: Props) {
  const [open, setOpen] = useState(false);

  const close = () => {
    setOpen(false);
  };

  const orderCheck =
    orderType === 'issue' ? orderType : 'order' ? orderType : null;

  return (
    <>
      <Grid item style={{ justifyContent: 'flex-end', float: 'right' }}>
        <Tooltip title={`View ${startCase(lowerCase(orderType))}`}>
          <span>
            <IconButton onClick={() => setOpen(true)}>
              <VisibilityTwoToneIcon color="primary" fontSize="default" />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      {open && orderCheck && (
        <OrderDrawer isDrawerOpen={open} setDrawerOpen={close} row={row} />
      )}
    </>
  );
}

export default ViewDrawer;
