import colors from '@oneAppCore/constants/colors';

import { createMuiTheme } from '@material-ui/core/styles';

import { borderRadius, primary, greys, boxShadowConfig } from './constants';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary,
    secondary: {
      main: '#272C2F',
      light: '#909ba2',
      dark: '#000000',
      contrastText: '#FFF',
    },
    orange: colors.ORANGES,
    green: colors.GREENS,
    red: colors.REDS,
    purple: colors.PURPLES,
    blue: colors.BLUES,
    greys: colors.GREYS,
    black: colors.BLACKS,
  },
  typography: {
    fontFamily: ['Montserrat', 'Helvetica', 'Arial', 'serif'].join(','),
  },
  overrides: {
    MuiListItem: {
      root: {
        marginTop: 5,
        '&&:hover': {
          paddingLeft: 20,
          transition: '.25s ease-in-out',
        },
        '&$selected': {
          borderRadius,
          backgroundColor: primary.light,
          boxShadow: `${boxShadowConfig} ${primary.light}`,
          '&:hover': {
            backgroundColor: primary.light,
          },
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius,
      },
      elevation1: {
        boxShadow: `${boxShadowConfig} rgba(34, 41, 47, 10%)`,
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: 'none',
        boxShadow: `${boxShadowConfig} rgba(34, 41, 47, 10%)`,
      },
    },
    MuiInputLabel: {
      outlined: {
        '&:not($focused)': {
          top: -6,
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        height: 3,
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: greys.light,
        borderTop: `.5px solid ${greys.border}`,
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 600,
        color: greys.main,
        paddingTop: '8px',
        paddingBottom: '8px',
        textTransform: 'uppercase',
        fontSize: '.75rem',
        letterSpacing: 0.75,
      },
      body: {
        color: greys.main,
      },
    },
  },
});

export default theme;
