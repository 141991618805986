import { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  SwipeableDrawer,
  Grid,
  Button,
  TextField,
  Typography,
  Divider,
} from '@material-ui/core';

import type { DrawerProps } from './types';
import { columns, issueColumns } from './constants';
import { WPS, AD, DZONE } from '@oneAppCore/constants/suppliers';
import OrderDrawerItemsTable from '@src/Components/Orders/OrderDrawerItemsTable';
import OrdersApi from '@oneAppCore/services/OrdersApi';
import suppliersApi from '@oneAppCore/services/SupplierApi';
import DefaultWarehouseSelector from '../DefaultWarehouseSelector';

import { useSnackbar } from 'notistack';

import { convertDollarsToCents } from '@src/utils/currency';

const drawerWidth = 400;
const mobileWidth = 260;
interface StyleProps {
  mobile: boolean;
}
type SupplierTotals = {
  total: number;
  supplier: string;
  available: boolean;
  calculatedResponse?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
      width: '475px',
    },
    [theme.breakpoints.down('md')]: {
      width: '350px',
    },
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
  },
  title: { paddingTop: theme.spacing(1) },
  root: {
    display: 'flex',
    flexBasis: 0,
    margin: theme.spacing(3, 0),
  },

  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
}));
const getWpsItemInventory = async (sku, enqueueSnackbar) => {
  try {
    const response: any = await suppliersApi.getWpsItemInventory(sku);
    return response;
  } catch (error) {
    enqueueSnackbar(`${error.message}`, {
      variant: 'error',
    });
  }
};

const calculateAutoDistOrder: any = async (row) => {
  const response = await suppliersApi.calculateAutoDistOrder(row);
  return response;
};

const postSupplierOrder = async (
  row,
  supplier,
  signatureChecked,
  setIsLoading,
  setDrawerOpen,
  enqueueSnackbar,
  defaultWarehouse?,
  calculatedResponse?,
) => {
  const supplierConfig = {
    [AD]: {
      field: 'signatureRequired',
      apiMethod: OrdersApi.postToAutoDist.bind(OrdersApi),
      calculatedResponse,
    },
    [WPS]: {
      field: 'proof_of_delivery',
      apiMethod: OrdersApi.postToWps.bind(OrdersApi),
      defaultWarehouse,
    },
  };
  const config = supplierConfig[supplier];
  if (!config) {
    return;
  }
  const payload = {
    id: row.id,
    [config.field]: signatureChecked,
    defaultWarehouse: [config.defaultWarehouse] ? defaultWarehouse : '',
    calculatedResponse: [config.calculatedResponse]
      ? calculatedResponse
      : false,
  };
  try {
    const response: any = await config.apiMethod(payload);
    if (response === `${row.orderNumber} successfully placed on ${supplier}`) {
      enqueueSnackbar(`${response}`, {
        variant: 'success',
      });
      return response;
    } else {
      enqueueSnackbar(`${response}`, {
        variant: 'error',
      });
    }
  } catch (error) {
    enqueueSnackbar(`${error.message}`, {
      variant: 'error',
    });
  } finally {
    setIsLoading(false);
    setDrawerOpen(false);
  }
};

export default function OrderDrawer({
  isDrawerOpen,
  setDrawerOpen,
  row,
  mobile,
}: DrawerProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [item, setItem] = useState(0);
  const orderItems = row.orderItems ? row.orderItems[item] : null;
  const [isLoading, setIsLoading] = useState(false);
  const [signatureChecked, setSignatureChecked] = useState(false);
  const [wpsInventoryLoaded, setWpsInventoryLoaded] = useState(false);
  const [wpsInventory, setWpsInventory] = useState([]);

  const handleRadioChange = (value) => {
    setSupplierRadioSelection(value);
  };
  const handleSignatureRequiredCheckboxChange = (event) => {
    setSignatureChecked(event.target.checked);
  };
  const [defaultWarehouse, setDefaultWarehouse] = useState('');
  const handleDefaultWarehouseChange = (event) => {
    setDefaultWarehouse(event.target.value);
  };

  const classes = useStyles({ mobile });
  const [calculatedResponse, setCalculatedResponse] = useState(false);

  const defaultTotals = () => {
    const separateRowsTotals = row?.orderItems?.flatMap((item) =>
      item?.allSuppliersArray[0]?.cost.map((cost, index) => {
        return {
          totalItemCost: cost * item.quantity,
          shippingCost: item.allSuppliersArray[0].estimatedShipping[index],
          supplier: item.allSuppliersArray[0].name[index],
          available:
            item.supplierName === item.allSuppliersArray[0].name[index]
              ? true
              : item.quantity > item.allSuppliersArray[0].available[index]
              ? false
              : true,
          calculatedResponse: false,
        };
      }),
    );
    const combinedRowsTotals = separateRowsTotals?.reduce((acc, curr) => {
      if (curr) {
        const existingSupplier = acc.find(
          (item) => item.supplier === curr.supplier,
        );
        if (existingSupplier) {
          if (existingSupplier.shippingCost < curr.shippingCost) {
            existingSupplier.shippingCost = curr.shippingCost;
          }
          existingSupplier.totalItemCost += curr.totalItemCost;
          existingSupplier.available =
            existingSupplier.available && curr.available;
        } else {
          acc.push({ ...curr });
        }
      }
      return acc;
    }, []);
    return combinedRowsTotals?.map((totalRow) => {
      const total = totalRow.shippingCost + totalRow.totalItemCost;
      return { ...totalRow, total };
    });
  };
  const [totals, setTotals] = useState<SupplierTotals[]>(defaultTotals());
  const wpsInventoryPromises = (row, enqueueSnackbar) =>
    row?.orderItems.flatMap((item) => {
      return item?.allSuppliersArray[0]?.name.map(async (name, i) => {
        if (name === WPS) {
          const wpsItemInventory = await getWpsItemInventory(
            item?.allSuppliersArray[0]?.supplierItemNo[i],
            enqueueSnackbar,
          );

          const filteredWpsItemInventoryArray =
            wpsItemInventory && wpsItemInventory[0]
              ? Object.entries(wpsItemInventory[0]).filter(
                  ([key, qty]) =>
                    key.includes('_warehouse') && qty >= item.quantity,
                )
              : null;
          setWpsInventory(filteredWpsItemInventoryArray);
        }
      });
    });
  useEffect(() => {
    const promises = [];
    let hasWpsInventory = false;

    if (
      row.orderItems.some((e) => e.allSuppliersArray[0]?.name.includes(WPS))
    ) {
      promises.push(wpsInventoryPromises(row, enqueueSnackbar));
      hasWpsInventory = true;
    }
    if (row.orderItems.some((e) => e.allSuppliersArray[0]?.name.includes(AD))) {
      promises.push(calculateAutoDistOrder(row));
    }
    Promise.all(promises)
      .then((data) => {
        hasWpsInventory ? setWpsInventoryLoaded(true) : null;
        const calculateData = data.find(
          (item) => typeof item === 'object' && !Array.isArray(item),
        );
        setCalculatedResponse(true);
        if (
          calculateData &&
          calculateData.totalInvoiceAmount !== '.00' &&
          calculateData.totalOrderValue === calculateData.totalInvoiceAmount
        ) {
          const newTotal =
            convertDollarsToCents(calculateData.totalInvoiceAmount) +
            convertDollarsToCents(calculateData.totalShippingCost);
          setTotals((prevTotals) =>
            prevTotals.map((e) =>
              e.supplier === AD
                ? {
                    ...e,
                    total: newTotal,
                    available: true,
                    calculatedResponse: true,
                  }
                : e,
            ),
          );
        } else {
          setTotals((prevTotals) =>
            prevTotals.map((e) =>
              e.supplier === AD ? { ...e, available: false } : e,
            ),
          );
        }
        return row;
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  }, [row]);

  const defaultRadioSelection = () => {
    if (orderItems.supplierName === DZONE) {
      return DZONE;
    }
    const filteredTotals = totals?.filter((item) => item.available);
    if (filteredTotals && filteredTotals.length > 0) {
      filteredTotals.sort((a, b) => a.total - b.total);

      return filteredTotals[0].supplier;
    }
    return '';
  };
  const [supplierRadioSelection, setSupplierRadioSelection] = useState(
    defaultRadioSelection(),
  );

  return (
    <>
      {orderItems === null ? (
        <></>
      ) : (
        <SwipeableDrawer
          open={isDrawerOpen}
          onClose={() => setDrawerOpen()}
          onOpen={() => null}
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="right"
        >
          <Grid container>
            <Grid alignItems="center" justifyContent="center" container>
              <Typography
                variant="h4"
                color="primary"
                style={{ marginBottom: 15, fontWeight: 600 }}
              >
                Order Details
              </Typography>
            </Grid>

            <OrderDrawerItemsTable
              row={row}
              signatureChecked={signatureChecked}
              handleSignatureRequiredCheckboxChange={
                handleSignatureRequiredCheckboxChange
              }
              isLoading={isLoading}
              onRadioChange={handleRadioChange}
              supplierRadioSelection={supplierRadioSelection}
              totals={totals}
              calculatedResponse={calculatedResponse}
            />
            <Grid
              item
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
            >
              {(row.store === 'dzone' || row.store === 'ads') &&
              (row.orderStatus === 'UNASSIGNED' ||
                row.orderStatus === 'ISSUE') &&
              orderItems &&
              orderItems?.allSuppliersArray.length >= 1 ? (
                <>
                  <Button
                    size="small"
                    style={{ margin: 15 }}
                    disabled={
                      (supplierRadioSelection !== WPS &&
                        supplierRadioSelection !== AD) ||
                      isLoading
                    }
                    key={`place-${row.id}`}
                    onClick={() => [
                      postSupplierOrder(
                        row,
                        supplierRadioSelection,
                        signatureChecked,
                        setIsLoading,
                        setDrawerOpen,
                        enqueueSnackbar,
                        defaultWarehouse,
                        calculatedResponse,
                      ),
                      setIsLoading(true),
                    ]}
                    variant="contained"
                    color="primary"
                  >
                    {supplierRadioSelection === AD ||
                    supplierRadioSelection === WPS
                      ? `Place ${supplierRadioSelection} Order`
                      : orderItems.supplierName === DZONE
                      ? `Internal Order`
                      : `${orderItems.supplierName} Order`}
                  </Button>
                  {supplierRadioSelection === WPS &&
                  wpsInventory?.length >= 1 &&
                  wpsInventoryLoaded ? (
                    <DefaultWarehouseSelector
                      wpsInventory={wpsInventory}
                      defaultWarehouse={defaultWarehouse}
                      handleDefaultWarehouseChange={
                        handleDefaultWarehouseChange
                      }
                    />
                  ) : null}
                </>
              ) : null}
            </Grid>
            <Grid item container xs={12} justifyContent="center">
              <Typography variant="h6" align="center">
                {orderItems ? orderItems.name : null}
              </Typography>
            </Grid>
            {columns
              .filter((col) => !issueColumns.includes(col.key))
              .map((name) => {
                if (name.customComponent) {
                  return (
                    <>
                    {name.key === 'price' ? (
                        <Grid item xs={12}>
                          <Divider className={classes.divider} />
                        </Grid>
                      ) : null}
                      <name.customComponent
                        order={row}
                        itemIndex={item}
                        supplierRadioSelection={supplierRadioSelection}
                        totals={totals}
                      />
                      {name.key === 'addressLine1' ? (
                        <Grid item xs={12}>
                          <Divider className={classes.divider} />
                        </Grid>
                      ) : null}
                      {name.key === 'cost' ? (
                        <Grid item xs={12}>
                          <Divider className={classes.divider} />
                        </Grid>
                      ) : null}
                      {name.key === 'purchaseDate' ? (
                        <Grid item xs={12}>
                          <Divider className={classes.divider} />
                        </Grid>
                      ) : null}
                      {name.key === 'price' ? (
                        <Grid item xs={12}>
                          <Divider className={classes.divider} />
                        </Grid>
                      ) : null}
                    </>
                  );
                }
                return (
                  <Grid key={name.key} container style={{ marginTop: 5 }}>
                    <Grid item xs={6} md={6} lg={6}>
                      <Typography
                        style={{ fontWeight: 600, fontSize: '1.15rem' }}
                        key={name.id}
                      >
                        {name.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <Typography style={{ fontSize: '1.15rem' }}>
                        {row[name.key]}
                      </Typography>
                      <Typography style={{ fontSize: '1.15rem' }}>
                        {orderItems[name.key]}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {name.key === 'supplierOrderNo' ? (
                        <Divider className={classes.divider} />
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      {name.key === 'listingSku' ? (
                        <Divider className={classes.divider} />
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      {name.key === 'price' ? (
                        <Divider className={classes.divider} />
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      {name.key === 'orderNumber' ? (
                        <Divider className={classes.divider} />
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      {name.key === 'supplierTrackingNo' ? (
                        <Divider className={classes.divider} />
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      {name.key === 'shippingName' ? (
                        <Divider className={classes.divider} />
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      {name.key === 'channel' ? (
                        <Divider className={classes.divider} />
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      {name.key === 'orderStatus' ? (
                        <Divider className={classes.divider} />
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      {name.key === 'placedByUsername' ? (
                        <Divider className={classes.divider} />
                      ) : null}
                    </Grid>
                  </Grid>
                );
              })}
            <Grid item container xs={12} justifyContent="center">
              <Typography variant="h6" style={{ marginTop: 10 }}>
                <strong>Notes</strong>
              </Typography>
            </Grid>
            <Grid item container xs={12} justifyContent="center">
              <TextField
                disabled
                style={{ marginBottom: 10, width: '80%' }}
                multiline
                variant="outlined"
              />
              <Button disabled variant="contained" color="primary">
                Save Notes
              </Button>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      )}
    </>
  );
}
