import recoilAnalyticsOptions from '../../AnalyticsHooks/AnalyticsOptions';

import { options } from './constants';

import AppSelect from '@src/Components/common/Selects/AppSelect';
import Grid from '@material-ui/core/Grid';

function NewerThanSelect() {
  const [
    reportOptions,
    setAnalyticsOptions,
  ] = recoilAnalyticsOptions.useAnalyticsOptionsState();

  const updateAnalytics = (input: number) => {
    setAnalyticsOptions((previous) => ({
      ...previous,
      newerThan: input,
    }));
  };

  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <AppSelect
        options={options}
        value={options.find(
          (option) => option.value === reportOptions.newerThan,
        )}
        onChange={(option) => updateAnalytics(option?.value || 0)}
        label="Newer Than"
        isClearable
        style={{ zIndex: 999 }}
      />
    </Grid>
  );
}

export default NewerThanSelect;
