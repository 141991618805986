import { channelIds, AMAZON, WALMART, EBAY, WOO_COMMERCE } from "./channels";
import { BLUES, DARKBLUES, DARKGREENS, DARKREDS, GOLDS, ORANGES, PURPLES, REDS } from "./colors";

// Active, Inactive, Pending Internal, Pending External, OOS Active
export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';
export const DRAFT = 'DRAFT';
export const PENDING = 'PENDING';
export const ERROR = 'ERROR';
export const ARCHIVED = 'ARCHIVED';
export const REJECTED = 'REJECTED';
export const COMPLETE = 'COMPLETE';
export const APPROVED = 'APPROVED';
export const REVIEW = 'REVIEW';
export const OOS = 'OOS';
export const EDIT = 'EDIT';
export const ISSUE = 'ISSUE';
export const REMOVED = 'REMOVED';
export const CREATED = 'CREATED';
export const CHANGED = 'CHANGED';
export const SUBMITTED = 'SUBMITTED';
export const APPROVED_CREATE = 'APPROVED_CREATE';
export const APPROVED_EDIT = 'APPROVED_EDIT';
export const UNPUBLISHED = 'UNPUBLISHED';
export const SUBMITTED_TO_AMAZON = 'Listing submitted to Amazon';

export const ANALYTIC_STATUS = 'listing status';
export const ANALYTIC_STOCK = 'listing stock';
export const ANALYTIC_QTY = 'listing quantity';
export const ANALYTIC_LST = 'listings';
export const ANALYTIC_SUP_TOT = 'supplier-total';
export const ANALYTIC_SUP_OOS = 'supplier-oos';
export const ANALYTIC_BRD_TOT = 'brand-total';
export const ANALYTIC_BRD_OOS = 'brand-oos';
export const ANALYTIC_OOS = 'out-of-stock';
export const ANALYTIC_INS = 'in-stock';
export const ANALYTIC_OOSPREV = 'out-previous';
export const ANALYTIC_INSPREV = 'in-previous';
export const ANALYTIC_OOS_LABEL = 'Out of Stock';
export const ANALYTIC_INS_LABEL = 'In Stock';
export const ANALYTIC_OOSPREV_LABEL = 'Out Previous';
export const ANALYTIC_INSPREV_LABEL = 'In Previous';

export const ANALYTICS_TYPE_LABELS = {
  [ANALYTIC_OOS]: ANALYTIC_OOS_LABEL,
  [ANALYTIC_INS]: ANALYTIC_INS_LABEL,
  [ANALYTIC_OOSPREV]: ANALYTIC_OOSPREV_LABEL,
  [ANALYTIC_INSPREV]: ANALYTIC_INSPREV_LABEL,
};

export const statusFilter = [
  // { label: SUBMITTED, value: SUBMITTED },
  // { label: APPROVED_CREATE, value: APPROVED_CREATE },
  // { label: APPROVED_EDIT, value: APPROVED_EDIT },
  { label: DRAFT, value: DRAFT },
  { label: PENDING, value: PENDING },
  { label: SUBMITTED, value: SUBMITTED },
  // { label: CREATED, value: CREATED },
  // { label: INACTIVE, value: INACTIVE },
  { label: REJECTED, value: REJECTED },
  { label: ERROR, value: ERROR },
]

export const AMAZON_STATUSES = {
  'Active': ACTIVE,
  'Inactive': INACTIVE,
  'OOS': ACTIVE,
  'Incomplete': PENDING,
  'Listing Removed': INACTIVE,
  'Search Suppressed': INACTIVE,
}

export const EBAY_STATUSES = {
  'Active': ACTIVE,
  'Inactive': INACTIVE,
}

export const LISTING_STATUSES = {
  [channelIds[AMAZON]]: AMAZON_STATUSES,
  [channelIds[EBAY]]: EBAY_STATUSES,
  // [channelIds[WALMART]]: WALMART,
  // [channelIds[WOO_COMMERCE]]: WOO_COMMERCE,
}

export const colorTheme = {
  palette: {
    ['WOO_COMMERCE dzone']: { main: '#F3AC74', dark: '#990000', light: '#D54040' },
    ['EBAY dzone']: { main: '#9F82D9', dark: '#B09225', light: '#E8CF76' },
    ['WALMART dzone']: { main: '#91C5F2', dark: '#294694', light: '#829BE0' },
    ['AMAZON dzone']: { main: '#B09225', dark: '#7D681A', light: '#FCE281' },

    ['AMAZON ads']: { main: '#6BDBA7', dark: '#A61F16', light: '#DF6860' },
    // [ARCLITE]: { main: '#1E73BE', dark: '#16558C', light: '#5D97CA' },
    // [DZONE]: { main: '#4444DA', dark: '#377FA3', light: '#8DC2DD' },
    indexColors: [
      ORANGES.main,
      GOLDS.main,
      DARKBLUES.main,
      DARKGREENS.main,
      PURPLES.main,
      REDS.dark,
      BLUES.main,
      DARKREDS.light,
    ],
    contrastThreshold: 3,
    tonalOffset: 0.2,
    green: {
      main: '#0f0',
      light: '#0f0',
      dark: '#0f0',
      lightest: '#0f0',
      darkest: '#0f0',
      contrastText: '#fff',
    },
    orange: {
      main: '#FFA500',
      light: '#FFA500',
      dark: '#FFA500',
      lightest: '#FFA500',
      darkest: '#FFA500',
      contrastText: '#fff',
    },
    red: {
      main: '#f00',
      light: '#f00',
      dark: '#f00',
      lightest: '#f00',
      darkest: '#f00',
      contrastText: '#fff',
    },
    purple: {
      main: '#800080',
      light: '#800080',
      dark: '#800080',
      lightest: '#800080',
      darkest: '#800080',
      contrastText: '#fff',
    },
    blue: {
      main: '#00f',
      light: '#00f',
      dark: '#00f',
      lightest: '#00f',
      darkest: '#00f',
      contrastText: '#fff',
    },
    greys: {
      main: '#999',
      light: '#999',
      dark: '#999',
      lightest: '#999',
      darkest: '#999',
      contrastText: '#fff',
    },
    black: {
      main: '#000',
      light: '#000',
      dark: '#000',
      lightest: '#000',
      darkest: '#000',
      contrastText: '#fff',
    },
    light: '',
  },
}

export const FEEDS_DIR = '/feeds'
