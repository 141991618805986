import { useHistory } from 'react-router-dom';

import queryUtils from '@src/utils/queries';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import type { Column } from '@src/Components/common/containers/SearchView/types';
import dayjs from 'dayjs';
import { Divider, Grid, Tooltip, IconButton } from '@material-ui/core';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';

export const columns: Column[] = [
  {
    title: 'Category Name',
    keyName: 'categoryName',
    sortBy: true,
    align: 'center',
  },
  {
    title: 'Parent Category Name',
    keyName: 'parentCategoryName',
    sortBy: true,
    align: 'center',
  },
  {
    title: 'Amazon ID',
    keyName: 'amazonCategoryId',
    sortBy: true,
    align: 'center',
  },
  {
    title: 'Ebay ID',
    keyName: 'ebayCategoryId',
    sortBy: true,
    align: 'center',
  },
  {
    title: 'WooCommerce ID',
    keyName: 'wooCommerceCategoryId',
    sortBy: true,
    align: 'center',
  },
  {
    title: 'Walmart ID',
    keyName: 'walmartCategoryId',
    sortBy: true,
    align: 'center',
  },
  {
    title: 'Created At',
    keyName: 'createdAt',
    sortBy: true,
    align: 'center',
    // type: 'date',
    customComponent: function CreatedAt(row) {
      return (
        <Typography>
          {dayjs(row.createdAt)
            .tz(dayjs.tz.guess())
            .format('MM/DD/YYYY hh:mma z')}
        </Typography>
      );
    },
  },
  {
    title: 'View',
    keyName: 'view',
    sortBy: false,
    align: 'center',
    customComponent: function CategoryRow(row) {
      const history = useHistory();

      const viewCategory = () => {
        const query = queryUtils.add('id', row.id);
        history.replace({
          search: query,
        });
      };
      return (
        <Grid key={row.id}>
          <Tooltip title={`View Category`}>
            <span>
              <IconButton onClick={viewCategory}>
                <VisibilityTwoToneIcon color="primary" fontSize="default" />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      );
    },
  },
];
