import SearchViewMiniReport from '@src/Components/common/containers/SearchViewMiniReport';
import { Grid, Typography, Tooltip, CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { NumberBlockProps } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      height: '100%',
    },
    number: {
      color: theme.palette.purple.main,
      fontWeight: 700,
    },
  }),
);

function NumberBlock(props: NumberBlockProps) {
  const classes = useStyles();
  const { value, title, description } = props;
  return (
    <SearchViewMiniReport>
      <Tooltip placement="top" title={description}>
        <Grid
          item
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="body2">{title}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h2" className={classes.number}>
              {value ? value : <CircularProgress />}
            </Typography>
          </Grid>
        </Grid>
      </Tooltip>
    </SearchViewMiniReport>
  );
}

export default NumberBlock;
