import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '50%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function DefaultWarehouseSelector({
  wpsInventory,
  defaultWarehouse,
  handleDefaultWarehouseChange,
}) {
  const classes = useStyles();
  let allowedWarehouses = [];
  wpsInventory.forEach((item) => {
    allowedWarehouses.push(item[0]);
  });
  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel id="default-warehouse-select-label">
          Select Default Warehouse
        </InputLabel>
        <Select
          labelId="default-warehouse-select-label"
          id="default-warehouse-select"
          value={defaultWarehouse}
          onChange={handleDefaultWarehouseChange}
        >
          {wpsInventory.map((item) => {
            const warehouseName = item[0].split('_')[0].toUpperCase();
            return (
              <MenuItem key={item[0]} value={warehouseName}>
                {`${warehouseName} Warehouse`}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
}
