import { useState, useEffect, FormEvent } from 'react';
import { useParams } from 'react-router-dom';
import SupplierSelect from '@src/Components/common/Selects/SupplierSelect';
import FontAwesome from '@src/Components/common/FontAwesome';

import {
  Table,
  TablePagination,
  Card,
  CircularProgress,
  Button,
  Grid,
  TextField,
  Chip,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  withStyles,
  IconButton,
  Divider,
} from '@material-ui/core';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';

import { columns } from './constants';
import TableHeader from './TableHeader';
import TableRows from './TableRows';
import AddVariation from './addVariation';

import type {
  Form,
  Params,
  Supplier,
  VariationsForm,
  FitmentForm,
  ManufacturerSupplierForm,
} from '../types';
import { makeStyles, Theme } from '@material-ui/core/styles';
import type { Makes, Models } from '../../NewProductForm/types';
import useVariationData from '@src/Components/Inventory/Management/hooks/useVariationsData';
import VariationBox from '../../NewProductForm/VariationsStep/variationBox';
import FitmentApi from '@oneAppCore/services/FitmentApi';
import type { SaveFunction } from '../../NewProductForm/types';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    [theme.breakpoints.down('md')]: {
      overflow: 'scroll',
    },
    [theme.breakpoints.up('md')]: {
      overflow: 'visible',
    },
  },
}));

function VariationsTable({
  product,
  supps,
  brand: brandParam,
  props,
  save,
  updateProductForm,
  row,
  cellIndex,
  isViewMode,
  skuError,
  mpnError,
}: {
  save?: SaveFunction;
  row?: any;
  mutate?: any;
  product?: Form;
  props?: any;
  brand?: string;
  supps?: Supplier[];
  updateProductForm?: any;
  cellIndex?: number;
  isViewMode?: boolean;
  skuError: boolean;
  mpnError: boolean;
}) {
  const { id: paramId } = useParams<Params>();

  const classes = useStyles();
  const [openedCell, setOpenedCell] = useState<number>();
  const [openedType, setOpenedType] = useState<string>('fitment');
  const [form, setForm] = useState<VariationsForm[]>([]);
  const [suppliers, setSuppliers] = useState<ManufacturerSupplierForm[][]>([
    [],
  ]);
  const [imageCell, setImageCell] = useState<number>();
  const [brand, setBrand] = useState<string>(brandParam);
  const [fitment, setFitment] = useState<FitmentForm[][]>([[]]);
  const [page, setPage] = useState(0);
  const [makes, setMakes] = useState<Makes[]>([]);
  const [models, setModels] = useState<Models[]>([]);
  const [isShown, setIsShown] = useState(false);
  const [varOptions, setVarOptions] = useState('');
  const [variantType, setVariantType] = useState<string>('');
  const { data: variations, mutate: mutateVariations } = useVariationData(
    Number(paramId),
  );

  console.log(variations, 'variations');

  const CustomColorIconButton = withStyles({
    root: {
      color: 'crimson',
    },
  })(IconButton);

  // let productCosts = suppliers.map(function (cost) {
  //   return cost.cost + cost.shippingCost;
  // });

  // let totalCost = productCosts.reduce(function (acc, total) {
  //   return acc + total;
  // }, 0);

  const handleClick = () => {
    setIsShown(true);
  };

  const rowsPerPage = 5;

  const setOpen = (cell: number, type: string = 'fitment') => {
    setOpenedType(type);
    setOpenedCell(cell);
  };

  useEffect(() => {
    const getMakesAndModels = async () => {
      const { rows: make } = await FitmentApi.getMakes();
      const { rows: model } = await FitmentApi.getModels();
      setMakes(make);
      setModels(model);
    };
    getMakesAndModels();
    const updatedVariations = variations?.slice() || [];
    if (updatedVariations.length < 1) {
      updatedVariations.push({});
    }
    if (updatedVariations[0].brand) {
      setBrand(updatedVariations[0].brand);
    }
    setForm(updatedVariations);
  }, [variations]);

  useEffect(() => {
    setBrand(brandParam);
  }, [brandParam]);

  const addSuppliers = (variationIndex: number) => {
    const updatedVariations = form?.slice() || [];
    const updatedSuppliers = suppliers.slice() || [];
    updatedSuppliers[variationIndex].push({ supplierId: 0 });
    updatedVariations[variationIndex].suppliers =
      updatedSuppliers[variationIndex];
    setSuppliers(updatedSuppliers);
    updateProductForm('variations', updatedVariations);
    setOpenedCell(variationIndex);
  };

  const removeSuppliers = (index: number, variationIndex: number) => {
    const updatedVariations = form?.slice() || [];
    const updatedSuppliers = suppliers.slice();
    updatedSuppliers[variationIndex].splice(index, 1);
    updatedVariations[variationIndex].suppliers =
      updatedSuppliers[variationIndex];
    setSuppliers(updatedSuppliers);
    updateProductForm('variations', updatedVariations);
  };

  const updateSuppliers = <K extends keyof ManufacturerSupplierForm>(
    index: number,
    key: K,
    value: ManufacturerSupplierForm[K],
    variationIndex: number,
  ) => {
    const updatedVariations = form?.slice() || [];
    const updatedSuppliers = suppliers.slice();
    updatedSuppliers[variationIndex][index] = {
      ...updatedSuppliers[variationIndex][index],
      [key]: value,
    };
    updatedVariations[variationIndex].suppliers =
      updatedSuppliers[variationIndex];
    setSuppliers(updatedSuppliers);
    updateProductForm('variations', updatedVariations);
  };

  const updateAllVariationTypes = (variantType: string) => {
    const updatedVariations =
      form?.map((variation) => ({
        ...variation,
        variantType: variantType,
      })) || [];
    setForm(updatedVariations);
    updateProductForm('variations', updatedVariations);
  };

  const onChange = <K extends keyof VariationsForm>(
    index: number,
    key: K,
    value: VariationsForm[K],
  ) => {
    const updatedVariations = form?.slice() || [];

    const existingVariationType = updatedVariations[index]?.variantType;

    updatedVariations[index] = {
      ...updatedVariations[index],
      [key]: value,
      variantType: existingVariationType,
    };
    setForm(updatedVariations);
    updateProductForm('variations', updatedVariations);
  };

  const updateFitment = (
    index: number,
    second: number,
    key: any,
    value: FitmentForm,
  ) => {
    const updatedVariations = form?.slice() || [];
    const newFitment = fitment?.slice() || [];
    newFitment[index][second][key] = value;
    updatedVariations[index].fitment = newFitment[index];
    setFitment(newFitment);
  };

  const addFitment = (index: number) => {
    const updatedVariations = form?.slice() || [];
    const newFitment = fitment?.slice() || [];
    newFitment[index].push({ years: [], make: '', model: '' });
    updatedVariations[index].fitment = newFitment[index];
    setFitment(newFitment);
  };

  const createNewFitment = (variationIndex: number) => {
    const updatedVariations = form?.slice() || [];
    const newFitment = fitment?.slice() || [];
    newFitment.push([]);
    updatedVariations[variationIndex].fitment =
      newFitment[variationIndex] || [];
    setFitment(newFitment);
  };

  const deleteFitment = (variationIndex: number, rowIndex: number) => {
    const updatedVariations = form?.slice() || [];
    const newFitment = fitment?.slice() || [];
    newFitment[variationIndex].splice(rowIndex, 1);
    updatedVariations[variationIndex].fitment = newFitment[variationIndex];
    setFitment(newFitment);
  };

  const removeFitment = (index: number) => {
    const updatedVariations = form?.slice() || [];
    const newFitment = fitment?.slice() || [];
    newFitment.splice(index, 1);
    updatedVariations[index].fitment = newFitment[index];
    setFitment(newFitment);
  };

  const addVariation = () => {
    const updatedVariations = form?.slice() || [];
    updatedVariations.push({
      brand: brand ?? '',
    });
    const newSuppliers = suppliers.slice() || [];
    const newFitment = fitment?.slice() || [];
    if (!newFitment[updatedVariations.length - 1])
      newFitment[updatedVariations.length - 1] = [];
    if (!newSuppliers[updatedVariations.length - 1])
      newSuppliers[updatedVariations.length - 1] = [];
    updatedVariations[updatedVariations.length - 1].fitment =
      newFitment[updatedVariations.length - 1] || [];
    updatedVariations[updatedVariations.length - 1].suppliers =
      newSuppliers[updatedVariations.length - 1] || [];
    setFitment(newFitment);
    setForm(updatedVariations);
    setSuppliers(newSuppliers);

    // createNewFitment(updatedVariations.length);
  };

  const handleTest = (variationIndex: number) => {
    setOpenedCell((prevOpenedCell) =>
      prevOpenedCell === variationIndex ? null : variationIndex,
    );
  };

  useEffect(() => {
    console.log('After render:', openedCell);
  }, [openedCell]);

  const removeVariation = (index: number) => {
    const updatedVariations = form?.slice() || [];
    updatedVariations.splice(index, 1);
    removeFitment(index);
    setForm(updatedVariations);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    save({ variations: form, suppliers, fitment });
  };

  const handleVarOptionsChange = (value: string) => {
    updateAllVariationTypes(value);
    setVarOptions(value);
  };

  return (
    <>
      <Grid container xs={12} alignItems="flex-end">
        {/* <Grid item xs={2}> */}

        {!isViewMode && form.length > 1 ? (
          <Grid item xs={2} style={{ margin: 20, marginRight: 5 }}>
            <InputLabel id="inputLabel">Variant Options</InputLabel>
            <Select
              variant="outlined"
              labelId="inputLabel"
              style={{
                minWidth: 300,
                maxHeight: '36px',
                backgroundColor: '#ffffff',
              }}
              onChange={(e) => handleVarOptionsChange(e.target.value as string)}
            >
              <MenuItem value={'size'}>Size</MenuItem>
              <MenuItem value={'color'}>Color</MenuItem>
              <MenuItem value={'style'}>Style</MenuItem>
              <MenuItem value={'material'}>Material</MenuItem>
              <MenuItem value={'make'}>Vehicle Make</MenuItem>
            </Select>
          </Grid>
        ) : null}
        {/* </Grid> */}
      </Grid>
      <form onSubmit={handleSubmit}>
        <Card className={classes.card}>
          {variations ? (
            <Table aria-label="collapsible table">
              <TableHeader columns={columns} row={variations} />
              <TableRows
                columns={columns}
                rows={variations}
                mutate={mutateVariations}
                page={page}
                rowsPerPage={rowsPerPage}
                openedCell={openedCell}
                openedType={openedType}
                setOpenedCell={setOpen}
                imageCell={imageCell}
                setImageCell={setImageCell}
                suppliers={supps}
              />
              {Boolean(form?.length) &&
                form
                  .filter(
                    (item) => !(item.sku && item.manufacturerNo && item.brand),
                  )
                  .map((variation, index) => (
                    <>
                      <AddVariation
                        key={index}
                        variationIndex={form.length - 1}
                        variation={variation}
                        columns={columns}
                        product={{ ...product }}
                        mutate={mutateVariations}
                        deleteRow={deleteFitment}
                        removeVariation={removeVariation}
                        varOptions={variations?.[0]?.variantType}
                      />
                    </>
                  ))}
              {paramId && (
                <Grid
                  item
                  container
                  justify="flex-start"
                  style={{ marginBottom: 20, marginTop: 10 }}
                >
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={addVariation}
                    // onClick={handleClick}
                    style={{ marginLeft: 10, minWidth: 'max-content' }}
                    disabled={
                      form.filter(
                        (item) =>
                          !(item.sku && item.manufacturerNo && item.brand),
                      ).length > 0
                    }
                  >
                    Add Variation
                  </Button>
                </Grid>
              )}
              <TablePagination
                count={variations.length}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[rowsPerPage]}
                page={page}
                onPageChange={(e, page) => {
                  setPage(page);
                  setOpenedCell(null);
                }}
              />
            </Table>
          ) : (
            <>
              {Boolean(form?.length) &&
                form.map((variation, vIndex) => {
                  const cellNeedsOpen = paramId ? vIndex === openedCell : true;
                  if (vIndex === openedCell)
                    console.log('variation', variation);
                  return (
                    <>
                      <VariationBox
                        index={vIndex}
                        columns={columns}
                        variation={variation}
                        onChange={onChange}
                        fitment={fitment}
                        updateFitment={updateFitment}
                        makes={makes}
                        models={models}
                        deleteRow={deleteFitment}
                        removeVariation={removeVariation}
                        imageCell={imageCell}
                        setImageCell={setImageCell}
                        row={row}
                        mutate={mutateVariations}
                        cellIndex={cellIndex}
                        formLength={form.length}
                        varOptions={varOptions}
                        skuError={skuError}
                        mpnError={mpnError}
                      />
                      <Grid
                        container
                        xs={11}
                        style={{ justifyContent: 'flex-end' }}
                      >
                        <Grid
                          container
                          xs={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Grid
                            item
                            // container
                            xs={2}
                            justify="flex-start"
                            style={{
                              paddingBottom: 20,
                              paddingLeft: 35,
                              alignItems: 'center',
                            }}
                          >
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={() => addFitment(vIndex)}
                              style={{
                                whiteSpace: 'nowrap',
                                minWidth: '120px',
                              }}
                            >
                              Add Fitment
                            </Button>
                          </Grid>
                          {suppliers.length === 0 ? (
                            <Grid
                              item
                              // container
                              xs={1}
                              justify="flex-start"
                              style={{ paddingLeft: 35 }}
                            >
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                  handleTest(vIndex);
                                  addSuppliers(vIndex);
                                  console.log(vIndex, 'vIndex');
                                  console.log(variation, 'Opened');
                                }}
                                style={{
                                  whiteSpace: 'nowrap',
                                  minWidth: '120px',
                                }}
                              >
                                Add Supplier
                              </Button>
                            </Grid>
                          ) : (
                            <>
                              <Grid
                                item
                                container
                                xs={1}
                                justify="flex-start"
                                style={{
                                  paddingBottom: 20,
                                  paddingRight: 55,
                                  alignItems: 'center',
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() => {
                                    addSuppliers(vIndex);
                                  }}
                                  style={{
                                    whiteSpace: 'nowrap',
                                    minWidth: '120px',
                                  }}
                                >
                                  Add Supplier
                                </Button>
                              </Grid>
                              <Grid
                                item
                                container
                                xs={12}
                                justify="center"
                                alignItems="flex-end"
                                style={{
                                  maxWidth: '1733px',
                                  marginLeft: '10px',
                                  padding: 20,
                                  alignItems: 'flex-end',
                                }}
                              >
                                {cellNeedsOpen &&
                                  suppliers[vIndex].map(
                                    (
                                      supplier: ManufacturerSupplierForm,
                                      sIndex: number,
                                    ) => (
                                      <Grid
                                        item
                                        container
                                        xs={12}
                                        spacing={2}
                                        justify="center"
                                        alignItems="flex-end"
                                        key={`supplier-${sIndex + 1}`}
                                        style={{ alignItems: 'flex-end' }}
                                      >
                                        <Grid
                                          item
                                          xs={11}
                                          md={4}
                                          lg={4}
                                          style={{ maxWidth: '560px' }}
                                        >
                                          <SupplierSelect
                                            value={supplier.supplierId ?? ''}
                                            onChange={(e) =>
                                              updateSuppliers(
                                                sIndex,
                                                'supplierId',
                                                e.value,
                                                vIndex,
                                              )
                                            }
                                            required
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={11}
                                          md={3}
                                          lg={3}
                                          style={{ width: '440px' }}
                                        >
                                          <TextField
                                            style={{ width: '100%' }}
                                            variant="outlined"
                                            label="Cost"
                                            value={supplier?.cost ?? ''}
                                            required
                                            type="number"
                                            onChange={(e) =>
                                              updateSuppliers(
                                                sIndex,
                                                'cost',
                                                parseFloat(
                                                  e.currentTarget.value,
                                                ),
                                                vIndex,
                                              )
                                            }
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={9}
                                          md={3}
                                          lg={3}
                                          style={{ width: '440px' }}
                                        >
                                          <TextField
                                            style={{ width: '100%' }}
                                            variant="outlined"
                                            label="Shipping Cost"
                                            value={supplier?.shippingCost ?? ''}
                                            type="number"
                                            onChange={(e) =>
                                              updateSuppliers(
                                                sIndex,
                                                'shippingCost',
                                                parseFloat(
                                                  e.currentTarget.value,
                                                ),
                                                vIndex,
                                              )
                                            }
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={1}
                                          justify="flex-start"
                                          alignItems="flex-end"
                                        >
                                          <CancelTwoToneIcon
                                            onClick={() =>
                                              removeSuppliers(sIndex, vIndex)
                                            }
                                            fontSize="large"
                                            style={{ color: '#FC0301' }}
                                          />
                                        </Grid>
                                      </Grid>
                                    ),
                                  )}
                              </Grid>
                              <Grid container xs={12}>
                                <Divider />
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
            </>
          )}
          {!paramId && (
            <Grid
              item
              container
              xs={12}
              justify="space-between"
              style={{
                // marginBottom: 20,
                // marginTop: 20,
                alignItems: 'center',
              }}
            ></Grid>
          )}
        </Card>
      </form>
      <Grid item xs={1}>
        {!paramId && (
          <Grid item container xs={12}>
            <Button
              color="secondary"
              variant="contained"
              onClick={addVariation}
              style={{
                margin: 15,
                marginLeft: 5,
                marginBottom: 20,
                minWidth: 'max-content',
              }}
            >
              Add Variation
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default VariationsTable;
