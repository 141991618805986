import { useState, useEffect, useMemo, FormEvent } from 'react';
import { omit, isEmpty } from 'lodash';
import { Grid, Paper, Typography, Button, Divider } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import useCompanies from '@src/hooks/swr/useCompanies';
import Api from '@oneAppCore/services/Api';
import { TextField, Select } from '@src/Components/common';
import type { CreateUser } from '@oneAppCore/types/userTypes';

import { formFields } from './constants';
import { validate } from '../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
  },
  botmarg: {
    marginBottom: '10px',
  },
  containerClass: {
    width: '100%',
    marginBottom: '-5px',
    position: 'relative',
  },
}));

const omitFields = [
  'id',
  'createdBy',
  'updatedBy',
  'deletedBy',
  'createdAt',
  'updatedAt',
  'deletedAt',
  'confirmPassword',
  'roles',
];

function CoreInformation({ user, edit }: { user: any; edit: Boolean }) {
  const classes = useStyles();

  const [form, setForm] = useState<CreateUser>(user);
  const [errors, setErrors] = useState<Record<string, boolean>>({});

  const { data: companies = [] } = useCompanies();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setForm(user);
  }, [user]);

  const update = (key: string, value: string | number) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const error = validate(form);
    setErrors(error);
    if (!isEmpty(error)) {
      return;
    }
    try {
      const payload = omit(form, omitFields);
      if (form.id) {
        await Api.put(`/api/v1/users/${form.id}`, payload);
      } else {
        await Api.post(`/api/v1/users`, payload);
      }
      enqueueSnackbar('Saved', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const companyOptions = useMemo(
    () =>
      companies.map((company) => ({
        value: company.id,
        label: company.name,
      })),
    [companies],
  );

  return (
    <Paper elevation={1} className={classes.root}>
      <form onSubmit={submit}>
        <Grid item container alignItems="center" spacing={1}>
          <Grid item container justify="center" style={{ marginBottom: 10 }}>
            <Typography variant="h5">{`${user.firstName}'s Information`}</Typography>
          </Grid>
          <Grid xs={12} style={{ marginBottom: 15 }}>
            <Divider />
          </Grid>
          {formFields.map((field, fieldIndex) => {
            let formElement;
            switch (field.type) {
              case 'select':
                formElement = (
                  <Select
                    containerClass={!edit && classes.containerClass}
                    disabled={!edit}
                    options={companyOptions}
                    value={companyOptions.find(
                      (option) => option.value === form[field.key],
                    )}
                    onChange={(e) => update(field.key, e.value)}
                    placeholder={`Select ${field.label}...`}
                    label={field.label}
                    required={field.required}
                    styles={{ position: 'absolute', top: '0' }}
                  />
                );
                break;
              default:
              case 'password':
                formElement = (
                  <TextField
                    disabled={!edit}
                    fullWidth
                    label={field.label}
                    variant="outlined"
                    value={form[field.key] ?? ''}
                    onChange={(e) => update(field.key, e.currentTarget.value)}
                    error={Boolean(errors[field.key])}
                    type={field.type}
                    name={`${field.type}-${field.key}`}
                    required={field.required}
                  />
                );
                break;
            }
            return (
              <Grid
                key={field.key}
                item
                xs={12}
                md={6}
                lg={3}
                xl={2}
                className={
                  !edit && fieldIndex === formFields.length - 1
                    ? classes.botmarg
                    : ''
                }
              >
                {formElement}
              </Grid>
            );
          })}
          {edit && (
            <Grid item container justify="flex-end">
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </Paper>
  );
}

export default CoreInformation;
