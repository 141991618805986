import React from 'react';
import useLocalStorage from '@src/hooks/util/useLocalStorage';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import themes from './themes';

interface Props {
  children: React.ReactElement;
}

const Theme: React.FC<Props> = (props) => {
  const { children } = props;

  const [theme] = useLocalStorage('themeMode', 'lightTheme');

  return (
    <ThemeProvider theme={themes[theme] || themes.lightTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default Theme;
