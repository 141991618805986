import useOrderOperations from './hooks/useOrderOperations';

import dayjs from 'dayjs';
import numberUtils from '@oneAppCore/utils/numbers';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FontAwesome from '@src/Components/common/FontAwesome';
import { CircularProgress, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  'bg-synced': {
    backgroundColor: 'rgba(97,185,137, .1) !important',
  },
  'bg-nah': {
    backgroundColor: 'rgba(207,131,132, .1) !important',
  },
}));

function OrderOperations() {
  const classes = useStyles();
  const { data } = useOrderOperations();

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Channels</TableCell>
            <TableCell>Synced</TableCell>
            <TableCell>Yesterday</TableCell>
            <TableCell>Today</TableCell>
            <TableCell>Unassigned</TableCell>
            <TableCell>Placed</TableCell>
            <TableCell>Tracked</TableCell>
            <TableCell>Issues</TableCell>
            <TableCell>Scanned At</TableCell>
          </TableRow>
        </TableHead>
        {data ? (
          <TableBody>
            {(data || []).map((row) => {
              const synced = dayjs(row.scannedAt).isAfter(
                dayjs().subtract(21, 'minutes'),
              );
              return (
                <TableRow
                  key={row.name}
                  className={synced ? classes[`bg-synced`] : classes[`bg-nah`]}
                >
                  <TableCell>
                    <Grid
                      container
                      xs={12}
                      alignItems="center"
                      justify="space-between"
                    >
                      <Grid item xs={6}>
                        {row.name}
                      </Grid>
                      <Grid item xs={6}>
                        <img
                          src={row?.icon}
                          height="30px"
                          width="30px "
                          style={{ borderRadius: 5 }}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    {synced ? (
                      <FontAwesome
                        name="check-circle"
                        type="fa"
                        form="fa"
                        color="green"
                      />
                    ) : (
                      <FontAwesome
                        name="times-circle"
                        type="fa"
                        form="far"
                        color="red"
                      />
                    )}
                  </TableCell>
                  <TableCell>{row.yesterdayCount}</TableCell>
                  <TableCell>{row.todayCount}</TableCell>
                  <TableCell>
                    {numberUtils.formatNumber(row.unassigned, '0,0')}
                  </TableCell>
                  <TableCell>
                    {numberUtils.formatNumber(row.placed, '0,0')}
                  </TableCell>
                  <TableCell>
                    {numberUtils.formatNumber(row.tracked, '0,0')}
                  </TableCell>
                  <TableCell style={{ color: row.issues ? 'red' : 'black' }}>
                    {row.issues || 0}
                  </TableCell>
                  <TableCell>
                    {dayjs(row.scannedAt).format('MM/DD/YYYY hh:mma')}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        ) : (
          <Grid
            container
            xs={12}
            style={{
              marginTop: 20,
              marginBottom: 20,
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="primary" />
          </Grid>
        )}
      </Table>
    </TableContainer>
  );
}

export default OrderOperations;
