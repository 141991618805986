import { Grid, Typography, TextField } from '@material-ui/core';

export const formFields = ['brand', 'brandAliases', 'discounts', 'isMap'];

export const complexFormFields = [
  {
    name: 'brand',
    label: 'Brand',
    type: 'text',
  },
  {
    name: 'brandAliases',
    label: 'Aliases',
    type: 'list',
  },
];
