import { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import AddInventory from './AddInventory';
import InventoryHistory from './InventoryHistory';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function InventoryManagement() {
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(30);
  
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AddInventory page={page} limit={limit} />
      <InventoryHistory page={page} setPage={setPage} limit={limit} setLimit={setLimit} />
    </div>
  );
}

export default InventoryManagement;
