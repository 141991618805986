import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  TableBody,
  TableCell,
  TableRow,
  Collapse,
  Box,
  TextField,
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import type { Props } from './types';
import { Params } from '@src/Components/Users/UserView/types';
import ItemGroupsApi from '@oneAppCore/services/ItemGroups';
import useProduct from '../../hooks/useProduct';
import { GroupForm, Form, CreateGroup } from '../../types';

const useStyles = makeStyles(() => ({
  boxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  cellPadding: {
    paddingBottom: 0,
    paddingTop: 0,
    width: '100%',
    justifyContent: 'center',
    align: 'center',
  },
}));

function TableRows({
  rows,
  columns,
  mutate,
  page,
  rowsPerPage,
  openedType,
  openedCell,
  setOpenedCell,
  imageCell,
  setImageCell,
}: Props) {
  const classes = useStyles();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editCell, setEditCell] = useState<number>();
  const [groupForm, setGroupForm] = useState<GroupForm[]>([]);
  const [checked, setChecked] = useState(false);
  const { data: product, mutate: mutateProduct } = useProduct();
  const [modifiedCell, setModifiedCell] = useState({
    name: '',
    variation: '',
  });

  const { id: paramId } = useParams<Params>();

  useEffect(() => {
    if (paramId) {
      (async () => {
        const { group } = await ItemGroupsApi.getGroupId(Number(paramId));
        setGroupForm(group);
      })();
    } else {
      return;
    }
  }, []);

  const paginate = (array: any[], page_size: number, page_number: number) => {
    const pageRows = Array.isArray(array) && array.length ? array : groupForm;
    const x = pageRows.slice(0, 10);
    return x;
  };

  useEffect(() => {
    if (editCell != undefined) {
      const { product: name, variation } = paginationData[editCell];
      setModifiedCell({ name, variation });
    }
  }, [editCell]);

  useEffect(() => {
    setEditMode(false); //? Pagination change disables the edit so it doesnt stay in line.
  }, [page]);

  const paginationData = paginate(rows, 5, page + 1);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <TableBody>
      {paginationData.map((row, index) => (
        <Fragment key={row.id + index}>
          <TableRow>
            {columns.map((column) => {
              if (editMode && column.editable && editCell === index) {
                return (
                  <TableCell key={`editable-${index}`}>
                    <TextField
                      variant="outlined"
                      onChange={(e) => {
                        setModifiedCell((cell) => ({
                          ...cell,
                          [column.keyName]: e.target.value,
                        }));
                      }}
                      label={column.title}
                      value={modifiedCell[column.keyName]}
                    />
                  </TableCell>
                );
              } else if (column.keyName === 'edit') {
                return (
                  <TableCell
                    key={column.keyName}
                    align={column.align === 'center' ? 'center' : 'left'}
                  >
                    <Grid item container justify="center">
                      {column.editHandler(
                        paginationData[editCell],
                        modifiedCell,
                        editMode,
                        setEditMode,
                        index,
                        editCell,
                        setEditCell,
                        mutate,
                      )}
                    </Grid>
                  </TableCell>
                );
              } else if (column.keyName === 'createdAt') {
                return (
                  <TableCell key={column.keyName}>
                    {new Date(row.createdAt).toISOString().split('T')[0]}
                  </TableCell>
                );
              } else if (column.keyName === 'image') {
                return (
                  <TableCell key={column.keyName}>
                    <img src={row.imageUrl} alt="" width={50} height={50} />
                  </TableCell>
                );
              } else {
                return (
                  <>
                    <TableCell
                      key={column.keyName}
                      align={column.align === 'center' ? 'center' : 'left'}
                    >
                      {column.customComponent
                        ? column.customComponent(
                            row,
                            setOpenedCell,
                            openedCell,
                            setImageCell,
                            openedType,
                            imageCell,
                            index,
                            mutate,
                          )
                        : row[column.keyName]}
                    </TableCell>
                  </>
                );
              }
            })}
          </TableRow>
        </Fragment>
      ))}
    </TableBody>
  );
}

export default TableRows;
