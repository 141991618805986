import { useState, useEffect } from 'react';
import useMagikTime from './hooks/useMagikTime';

import { magikTimeStateDefault } from './hooks/useMagikTime/constants';
import { dateOptions } from './TimePeriodSelect/constants';
import ToolsApi from '@oneAppCore/services/ToolsApi';

import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';

import ChannelOptions from './ChannelOptions';
import TimePeriodSelect from './TimePeriodSelect';
import StoreSelect from './StoreSelect';
import TableView from './TableView';
import DownloadButton from './DownloadButton';

function MagikTime() {
  const [newerThan, setNewerThan] = useState(dateOptions[0].value);
  const [
    { loading, channelId, storeId, csv },
    setState,
  ] = useMagikTime.useState();

  useEffect(() => {
    setState(magikTimeStateDefault);
  }, []);

  useEffect(() => {
    if (!storeId || !channelId) return;
    (async () => {
      setState((previous) => ({
        ...previous,
        loading: true,
      }));

      const lostMoneyOrders = await ToolsApi.getLostMoney(
        newerThan,
        channelId,
        storeId,
      );

      setState((previous) => ({
        ...previous,
        lostMoneyOrders,
        loading: false,
      }));
    })();
  }, [newerThan, channelId, storeId, csv]);

  return (
    <Grid item container direction="column" spacing={1}>
      <ChannelOptions />
      <TimePeriodSelect newerThan={newerThan} updateNewerThan={setNewerThan} />
      <StoreSelect />
      <DownloadButton />
      <Grid item>
        {loading ? <CircularProgress color="primary" /> : <TableView />}
      </Grid>
    </Grid>
  );
}

export default MagikTime;
